import {Liquidity} from "../../../assets/domain/models/Liquidity";
import {EnvVars} from "../../../common/config/EnvVars";
import {NFTRepository} from "../../../eth/data/NFTRepository";

export class GetLiquidityUseCase {
  async execute(clientAddress: string): Promise<Array<Liquidity>> {
    const contract = new NFTRepository(EnvVars.liquidityPositionAddress)
    const countNFT = await contract.balanceOf(clientAddress)

    let promises = [];
    for (let i = 0; i <= countNFT - 1; i++) {
      let promise = await contract.tokenOfOwnerByIndex(clientAddress, i)
      promises.push(promise);
    }
    const tokenIds = await Promise.all(promises)

    const data = await Promise.all(tokenIds.map(async (tokenId) => await contract.tokenData(tokenId)))
    return data
  }
}
