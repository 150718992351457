import { FormControl, FormHelperText, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import React from 'react'
import useStyles from './FileUploader.style'
import { FileError, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import ClearIcon from '@material-ui/icons/Clear'
import { formatBytes } from '../../../utils/functions'

export type FileUploaderAcceptTypes = '.pdf' | 'image/*' | '.xlsx' | '.xls' | '.doc' | '.docx' | '.ppt' | '.pptx' | '.jpg' | '.png'

const DEFAULT_TYPES: Array<FileUploaderAcceptTypes> = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.jpg' ,'.png']

const FILE_ERROR_MESSAGES: Record<FileError['code'], string> = {
  'file-too-large': 'formErrors.fileTooLarge',
  'file-too-small': 'formErrors.fileTooSmall',
  'too-many-files': 'formErrors.tooManyFiles',
  'file-invalid-type': 'formErrors.fileInvalidType'
}

interface Props {
  label?: string
  placeholder?: string
  accept?: Array<FileUploaderAcceptTypes>
  onChange: (files: Array<File>) => void
  onRemoved?: (file: File) => void
  multiple?: boolean
  minSize?: number
  maxSize?: number
  maxFiles?: number
  disabled?: boolean
  onFileDialogCancel?: () => void
  error?: boolean
  helperText?: React.ReactNode
  value: Array<File>
}

const FileUploader = ({
  label,
  placeholder,
  accept = DEFAULT_TYPES,
  onChange,
  error,
  helperText,
  value,
  multiple,
  onRemoved,
  ...restProps
}: Props) => {
  const onDrop = (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length) {
      onChange(acceptedFiles)
    }
  }

  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({ accept, onDrop, ...restProps, multiple })
  const classes = useStyles({ isDragActive })
  const { t } = useTranslation()

  return (
    <FormControl className={classes.fileUploaderRoot}>
      <InputLabel shrink htmlFor="fileUploader">
        {label ?? t('formLabels.default.fileUploader.label')}
      </InputLabel>
      <div {...getRootProps({ className: classes.fileUploaderDropzone })}>
        <input id="fileUploader" {...getInputProps()} />
        <Typography variant="body2">{placeholder ?? t('formLabels.default.fileUploader.placeholder')}</Typography>
      </div>
      {helperText && (
        <FormHelperText error={error || Boolean(fileRejections.length)}>
          {Boolean(fileRejections.length) ? t((`${FILE_ERROR_MESSAGES[fileRejections[0].errors[0].code]}` as any) ?? '') : helperText}
        </FormHelperText>
      )}
      <List className={classes.fileUploaderPreview}>
        {value?.map((file) => {
          return (
            <ListItem key={file.name} dense>
              <ListItemIcon>
                <AttachFileIcon style={{ fontSize: 20 }} />
              </ListItemIcon>
              <ListItemText secondary={formatBytes(file.size)}>
                <Typography variant="body2" noWrap>
                  {file.name}
                </Typography>
              </ListItemText>
              {onRemoved && (
                <IconButton onClick={() => onRemoved(file)}>
                  <ClearIcon style={{ fontSize: 20 }} />
                </IconButton>
              )}
            </ListItem>
          )
        })}
      </List>
    </FormControl>
  )
}

export default FileUploader
