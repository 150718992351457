import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return {
    content: {
      width: '100%',
    },
  }
})

export default useStyles
