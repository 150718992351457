import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return {
    buyBtnTooltip: { fontSize: 13 },
    whiteBtn: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2),
    },
    liquidityBtn: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1),
    },
    liquidityBtnMenuItem: {
      width: 250,
      justifyContent: 'center'
    },
    adminBtn: {
      marginLeft: theme.spacing(2),
    }
  }
})

export default useStyles
