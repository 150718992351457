import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GridCellProps, Index } from 'react-virtualized'
import { createTupleFromList } from '../../../../common/utils/array'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Theme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined'
import EmptyState from '../../../../common/view/components/empty-state/EmptyState'
import GridInfiniteLoader from '../../../../common/view/components/grid-inifite-loader/GridInfiniteLoader'
import { useAssetListWithAmount } from '../../../../assets/view/hooks/AssetListWithAmount.hook'
import useStyles from './OwnerPage.styles'
import { Asset } from '../../../../assets/domain/models/Asset'
import AssetCard from '../../../../common/view/components/asset-card/AssetCard'
import { getUserClientId, getUserClientIsOwner } from '../../store/user.selectors'

const flexContentCentering = ['flex-start', 'center', 'flex-end']

export default function AssetsPage() {
  // Deps
  const classes = useStyles()
  const router = useHistory()
  const { t } = useTranslation()
  const userClientId = useSelector(getUserClientId)
  const isOwner = useSelector(getUserClientIsOwner)
  const { assetsPage, loading, getMoreAssets, assetsAmountMap } = useAssetListWithAmount(userClientId)
  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const assetsTuple = useMemo(() => createTupleFromList<Asset>(assetsPage.items, matchesSm ? 3 : 1), [assetsPage.items, matchesSm])
  const scrollElement = document.querySelector('main') as Element

  const handleOnViewDetail = (assetId: string) => {
    router.push(`/u/assets/${assetId}`)
  }

  const isRowLoaded = ({ index }: Index) => {
    return !!assetsPage.items[index]
  }

  const cellRenderer = ({ key, rowIndex, columnIndex, style }: GridCellProps) => {
    if (!assetsTuple[rowIndex][columnIndex]) {
      return null
    }

    const asset: Asset = assetsTuple[rowIndex][columnIndex]
    const amountData = assetsAmountMap.current?.get(asset.id)
    if (amountData) {
      const { totalSupply, clientBalance, prices } = amountData
      asset.amountData = { totalSupply, clientBalance, prices }
    }
    return (
      <div
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center',
          justifyContent: flexContentCentering[columnIndex],
          paddingTop: '40px'
        }}
        key={key}>
        <AssetCard
          asset={asset}
          width={matchesSm ? '90%' : '100%'}
          loadingAssetAmount={amountData?.loading}
          onViewDetail={handleOnViewDetail}
          isOwner={isOwner}
        />
      </div>
    )
  }

  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
      <Grid item>
        <Grid container alignItems="center" justify="space-between">
          <Typography variant="h4">
            <b>{t('assets.owner.title')}</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        {scrollElement && Boolean(assetsPage.items.length) && (
          <GridInfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={getMoreAssets}
            cellRenderer={cellRenderer}
            rowCountNormalList={assetsPage.totalCount}
            rowCountTupleList={assetsTuple.length}
            columnCount={assetsTuple[0].length}
            columnWidthCallback={(width) => (matchesSm ? width / 3 : width)}
            scrollElement={scrollElement}
          />
        )}
        {loading && (
          <div className={classes.listLoader}>
            <CircularProgress size={30} color="inherit" />
          </div>
        )}
        {!assetsPage.items.length && !loading && <EmptyState icon={CloudOutlinedIcon} height="85vh" message={`${t('assets.owner.empty')}`} />}
      </Grid>
    </Grid>
  )
}
