import EthereumClient from '../../../data/EthereumClient'
import { UnpauseTokenUseCase } from './UnpauseTokenUseCase'
import { Asset } from '../../../../assets/domain/models/Asset'
import { AssetsRepository } from '../../../../assets/data/AssetsRepository'
import { providers } from 'ethers'

export class UnpauseTokenInteractor {
  static async execute(asset: Asset): Promise<providers.TransactionResponse | string | undefined> {
    if (!EthereumClient.web3ClientAddressChanged) {
      return AssetsRepository.unpause({ id: asset.id})
    } else {
      return UnpauseTokenUseCase.execute(asset)
    }
  }
}
