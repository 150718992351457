import { useDispatch } from 'react-redux'
import { IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ReactComponent as MetaMaskLogo } from '../../../../common/assets/logos/metamask.svg'

import { AddTokenButtonProps, CallbackAddToken } from '../../../data/models/AddTokenButtonModel';

import { MetamaskService } from '../../../data/MetamaskService';

import { openSnackbar } from '../../../../common/view/store/ui/ui.slice'

import { ContractRepository } from '../../../data/ContractRepository';

const AddTokenButton = ({ tokenAddress, tokenSymbol, tokenImage } : AddTokenButtonProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const onPressAddToken = async () => {
    const contractRepository = new ContractRepository(tokenAddress)
    const decimals = await contractRepository.decimals()
    const callbackAddToken: CallbackAddToken = (message, severity = undefined) => {
      dispatch(openSnackbar({
        message: t(`${message}`as any),
        ...(!severity ? {} : { severity: 'error' })
      }))
    }
    MetamaskService.addToken({ tokenAddress, tokenSymbol, tokenDecimals: decimals, tokenImage, callbackAddToken })
  }
  return (
    <IconButton onClick={onPressAddToken}>
      <MetaMaskLogo height={20} width={20} />
    </IconButton>
  )
}

export default AddTokenButton;
