import { useTranslation } from 'react-i18next'
import { Avatar, Button, Grid, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import useStyles from './AssetCard.styles'

import { Asset } from '../../../../assets/domain/models/Asset'

import { CalculateBalances } from '../../../../common/utils/CalculateBalances';

export interface Props {
  asset: Asset
  loadingAssetAmount?: boolean
  onViewDetail: (assetId: string) => void
  width?: string
  isOwner?: boolean
}

export default function AssetCard({ asset, loadingAssetAmount, onViewDetail, isOwner = false, ...rest }: Props) {
  const classes = useStyles({ asset, loadingAssetAmount, onViewDetail, ...rest })
  const { t } = useTranslation()

  const { totalSupply, totalBalance, USDCValue } = CalculateBalances(asset);

  const renderData = () => {
    if (loadingAssetAmount) {
      return (
        <div className={classes.loader}>
          <CircularProgress size={30} color="inherit" />
        </div>
      )
    }

    return (
      <Grid className={classes.assetAmountData}>
        <Typography>{t('assets.card.totalSupply')}</Typography>
        <Typography className={classes.fontSmall}>
          <b>{totalSupply}</b>
        </Typography>
        <Typography>{t('assets.card.clientBalance')}</Typography>
        <Typography className={classes.fontSmall}>
          <b>{totalBalance}</b>
        </Typography>
        <Typography>{`${t('assets.card.price')}:`}</Typography>
        <Typography className={classes.fontSmall}>
          <b>{USDCValue? USDCValue : '---'} USDC</b>
        </Typography>
      </Grid>
    )
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.top}>
        <Avatar className={classes.avatar} src={asset?.logo} />
        <div className={classes.nameWrapper}>
          <Typography variant="h5" noWrap classes={{ root: classes.tokenName }}>
            <b>{asset.tokenName}</b>
          </Typography>
          <Typography variant="body1" noWrap>
            <b>{asset.tokenSymbol.toUpperCase()}</b>
          </Typography>
        </div>
      </div>
      <div>
        {renderData()}
        <div className={classes.buttonWrapper}>
          <Button variant="contained" classes={{ root: classes.button, label: classes.buttonText }} onClick={() => onViewDetail(asset.id)}>
            {t('assets.card.btn')}
          </Button>
        </div>
      </div>
    </div>
  )
}
