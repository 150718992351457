import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return {
    cardWrapper: {
      paddingTop: 40,
      width: 300,
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        minWidth: 240,
        paddingRight: 0
      }
    },
    listLoader: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2)
    },
    assetButton: {
      whiteSpace: 'nowrap',
      marginTop: 20,
      color: theme.palette.common.white,
    },
    cardContainer: {
      paddingTop: '40px',
      display: 'flex',
      alignItems: 'center',
    },
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        minWidth: 240,
        paddingRight: 0,
      }
    },
    titleWrapper: {
      marginRight: 40,
    },
    gridWrapper: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }
    }
  }
})

export default useStyles
