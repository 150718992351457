import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import useStyles from './AppLoader.styles'
import { useSelector } from 'react-redux'
import { getLoaderState } from '../../store/ui/ui.selectors'

export default function AppLoader() {
  const classes = useStyles()
  const loaderOpen = useSelector(getLoaderState)

  return (
    <Backdrop className={classes.backdrop} open={loaderOpen}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
