import { createStyles, makeStyles } from '@material-ui/core/styles'
import { DialogContent, DialogContentProps, useMediaQuery, useTheme, withStyles } from '@material-ui/core'

export const AglaiaDialogContent = withStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',

        '& > *': {
          width: '100%',
          height: '100%'
        }
      }
    }
  })
)(({ dividers, ...restProps }: DialogContentProps) => {
  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'))

  return <DialogContent dividers={dividers ?? matchesXs} {...restProps} />
})

const useStyles = makeStyles((theme) => {
  return {
    root: {}
  }
})

export default useStyles
