import { DialogTitle } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { AssetForm } from '../asset-form/AssetForm';

interface Props {
  onSuccess: () => void
}

export const CreateAssetDialog = ({ onSuccess }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <DialogTitle id="form-dialog-title">{t('assets.creation.title')}</DialogTitle>
      <AssetForm onSuccess={onSuccess} />
    </>
  )
}
