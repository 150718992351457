import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog, setLoader } from '../../../../common/view/store/ui/ui.slice'
import { Form, Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../common/i18n/config'
import yup from '../../../../common/utils/yup.extended'
import React, { useRef } from 'react'
import { Asset } from '../../../domain/models/Asset'
import DialogContentText from '@material-ui/core/DialogContentText'
import BigNumber from 'bignumber.js'
import { addTransaction, changeTransactionStatus } from '../../../../eth/view/store/eth.slice'
import { useEthErrorsWrapper } from '../../../../eth/view/hooks/EthErrorsWrapper.hook'
import { AglaiaDialogContent } from '../../../../common/view/components/app-dialog/AppDialog.styled'
import { MintTokenInteractor } from '../../../../eth/domain/use-cases/MintTokenInteractor/MintTokenInteractor'
import { checkIfTxResponse } from '../../../../eth/utils/ethers'
import { providers } from 'ethers'
import EthereumClient from '../../../../eth/data/EthereumClient'

interface FormValues {
  mintAmount: number | string
}

const formInitialValues: FormValues = {
  mintAmount: ''
}

const validationSchema = yup.object().shape({
  mintAmount: yup
    .number()
    .typeError(i18n.t('formErrors.required'))
    .required(i18n.t('formErrors.required'))
    .moreThan(0, i18n.t('formErrors.moreThan', { min: 0 }))
})

interface Props {
  ownedAsset: Asset
  onSuccess?: () => void
}

export const MintAssetDialog = ({ ownedAsset, onSuccess }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef = useRef<FormikProps<FormValues>>(null)
  const mintUseCase = useEthErrorsWrapper(MintTokenInteractor.execute)

  const handleCloseDialog = () => {
    dispatch(closeDialog())
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      dispatch(setLoader(true))
      const txRes = await mintUseCase(ownedAsset, new BigNumber(values.mintAmount!))
      dispatch(setLoader(false))
      if (checkIfTxResponse(txRes)) {
        const castedTxRes = txRes as providers.TransactionResponse
        dispatch(addTransaction({ hash: castedTxRes.hash, type: 'mint' }))
        handleCloseDialog()
        await castedTxRes?.wait()
        dispatch(changeTransactionStatus({ hash: castedTxRes.hash, status: 'completed' }))
      } else {
        const castedTxResHash = txRes as string
        const provider = await EthereumClient.currentProvider()
        dispatch(addTransaction({ hash: castedTxResHash, type: 'mint' }))
        handleCloseDialog()
        await provider.waitForTransaction(castedTxResHash)
        dispatch(changeTransactionStatus({ hash: castedTxResHash, status: 'completed' }))
      }
      if (onSuccess) {
        await onSuccess()
      }
      dispatch(setLoader(false))
    } catch (e) {
      console.error(e)
      dispatch(setLoader(false))
    }
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      enableReinitialize>
      {({ values, handleChange, errors, touched }: FormikProps<FormValues>) => {
        return (
          <>
            <DialogTitle id="form-dialog-title">{t('assets.mint.title', { assetName: ownedAsset.name })}</DialogTitle>
            <AglaiaDialogContent>
              <DialogContentText>{t('assets.mint.description')}</DialogContentText>
              <Form noValidate>
                <TextField
                  variant="outlined"
                  type="number"
                  fullWidth
                  placeholder="0"
                  margin="normal"
                  name="mintAmount"
                  onChange={handleChange}
                  value={values.mintAmount}
                  error={Boolean(touched.mintAmount) && Boolean(errors.mintAmount)}
                  helperText={Boolean(touched.mintAmount) && Boolean(errors.mintAmount) && errors.mintAmount}
                />
              </Form>
            </AglaiaDialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleCloseDialog}>
                {t('assets.mint.cancelBtn')}
              </Button>
              <Button color="primary" onClick={() => formRef.current?.submitForm()}>
                {t('assets.mint.submitBtn')}
              </Button>
            </DialogActions>
          </>
        )
      }}
    </Formik>
  )
}
