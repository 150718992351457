import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(4)
  },
  warningBanner: {
    backgroundColor: theme.palette.error.dark,
    top: theme.spacing(8),
    position: 'absolute',
    zIndex: 100,
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingRight: theme.spacing(2),
  },
  warningBannerText: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem'
    }
  },
  validateBtn: {
    position: 'relative',
    height: theme.spacing(4.5),
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    border: 'none',
  },
  toolbar: {
    display: 'flex',
    flex: 1,
    backgroundColor: theme.palette.secondary.main,
    justifyContent: 'center',
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: theme.breakpoints.values.lg
  },
  logo: {
    marginRight: theme.spacing(2),
    fontSize: 38,
    width: '50px'
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.white,
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem'
    }
  },
  link: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '80px'
  },
  selectedLink: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '80px'
  }
}))

export default useStyles
