import * as Sentry from '@sentry/react'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getUserProfile } from '../../../user/view/store/user.selectors'

export const useSentryException = () => {
  const userProfile = useSelector(getUserProfile)
  const censoredEmail = useMemo(() => {
    const splitEmail = userProfile?.email.split('@') ?? 'foo@bar.com'
    const usernameEmail = splitEmail[0]
    const censoredChars = new Array(usernameEmail.length - 1).join('*')
    const censoredEMail = usernameEmail[0] + censoredChars + usernameEmail[usernameEmail.length - 1]
    return censoredEMail + '@' + splitEmail[1]
  }, [userProfile?.email])

  const captureException = useCallback(
    (err: any) => {
      Sentry.captureException(err, {
        user: { email: censoredEmail },
        extra: {
          url: err?.response?.config?.url,
          errorCode: err?.response?.data
        }
      })
    },
    [censoredEmail]
  )

  return { captureException }
}
