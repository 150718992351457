import { fade, makeStyles, Theme } from '@material-ui/core/styles'
import { Props } from './EmptyState'

const useStyles = makeStyles<Theme, Omit<Props, 'icon' | 'message' | 'fontVariant'>>((theme) => {
  return {
    emptyStateRoot: {
      height: ({ height }) => height
    },
    emptyStateIcon: {
      fontSize: ({ iconSize }) => iconSize ?? 60,
      '& > *': {
        color: fade(theme.palette.text.primary, 0.3)
      }
    },
    emptyStateText: {
      color: fade(theme.palette.text.primary, 0.3)
    }
  }
})

export default useStyles
