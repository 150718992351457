import { HttpClient } from '../../common/data/HttpClient'
import { SignUpOutputModel } from './models/SignUpOutputModel'
import { LoginOutputModel } from './models/LoginOutputModel'

export class AuthRepository {
  static async signUp(body: SignUpOutputModel): Promise<void> {
    await HttpClient.instance.post('/clients/', body)
  }

  static async login(params: LoginOutputModel): Promise<void> {
    await HttpClient.instance.post('users/login/', params)
  }

  static async logout(): Promise<void> {
    await HttpClient.instance.post('users/logout/')
  }
}
