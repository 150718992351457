import { Expose, Type } from 'class-transformer'
import { AssetAmountData } from './AssetAmountData'
import { ApiToken } from '../../../eth/domain/models/ApiToken'
import { FileModel } from '../../../common/domain/models/FileModel'
import { TokenType } from './TokenType'

export class Asset {
  @Expose()
  id!: string
  @Expose()
  name!: string
  @Expose()
  description!: string
  @Expose({ name: 'token_name' })
  tokenName!: string
  @Expose({ name: 'token_symbol' })
  tokenSymbol!: string
  @Expose({ name: 'token_address' })
  tokenAddress!: string
  @Expose({ name: 'is_active' })
  isActive!: boolean
  @Expose()
  amountData?: AssetAmountData
  @Expose({ name: 'swappable_tokens' })
  @Type(() => ApiToken)
  swappableTokens!: Array<ApiToken>
  @Expose()
  @Type(() => FileModel)
  files!: Array<FileModel>
  @Expose()
  owner?: any
  @Expose({ name: 'token_type' })
  tokenType?: TokenType
  @Expose()
  paused?: boolean
  @Expose()
  profitability!: string
  @Expose()
  issuer!: string
  @Expose()
  summary!: string
  @Expose()
  logo!: string
  @Expose()
  description_presale!: string
  @Expose()
  issuer_presale!: string
  @Expose()
  profitability_presale!: string
  @Expose()
  uniswap_fee!: number
  @Expose()
  regulator_service_address!: string
  @Expose()
  is_presale!: boolean
  @Expose()
  presale_token!: string
  @Expose()
  bank_account!: string
  @Expose()
  contract!: string
  @Expose()
  signed!: boolean
}
