import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: theme.spacing(4),
  },
  container: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing(10),
    backgroundColor: theme.palette.common.white,
    justifyContent: 'center',
  },
  content: {
    paddingTop: 20,
    width: theme.breakpoints.values.lg,
  },
  mainText: {
    marginTop: theme.spacing(6),
    "& p": {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.00938em'
    },
    "& li": {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.00938em'
    }
  },
  mainHeading: {
    marginBottom: theme.spacing(4)
  },
}))

export default useStyles
