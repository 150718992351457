import { SwapOutputModel } from './models/SwapOutputModel'
import { HttpClient } from '../../common/data/HttpClient'
import { ApiToken } from '../domain/models/ApiToken'
import { plainToClass } from 'class-transformer'
import { TokenApi } from './models/TokenApi'
import { ApproveOutputModel } from './models/ApproveOutputModel'

export class EthApiRepository {
  static async swap(body: SwapOutputModel): Promise<string> {
    const { data } = await HttpClient.instance.post<{ transaction: string }>('/swap/', body)
    return data.transaction
  }

  static async approve(body: ApproveOutputModel): Promise<string> {
    const { data } = await HttpClient.instance.post<{ transaction: string }>('/approves/', body)
    return data.transaction
  }

  static async swappableTokens(): Promise<Array<ApiToken>> {
    const { data } = await HttpClient.instance.get<Array<TokenApi>>('/swappable-tokens/')
    return data.map((token) => plainToClass(ApiToken, token, { excludeExtraneousValues: true }))
  }
}
