import EthereumClient from '../../../data/EthereumClient'
import { MintTokensUseCase } from './MintTokensUseCase'
import { Asset } from '../../../../assets/domain/models/Asset'
import BigNumber from 'bignumber.js'
import { AssetsRepository } from '../../../../assets/data/AssetsRepository'
import { UniswapFetcherService } from '../../../data/UniswapFetcherService'
import { providers, utils } from 'ethers'

export class MintTokenInteractor {
  static async execute(asset: Asset, amount: BigNumber): Promise<providers.TransactionResponse | string | undefined> {
    const token = await UniswapFetcherService.fetchTokenData(asset.tokenAddress)
    const mintAmount = utils.parseUnits(amount.toString(), token.decimals)

    if (!EthereumClient.web3ClientAddressChanged) {
      return AssetsRepository.mint({ id: asset.id, amount: mintAmount.toString() })
    } else {
      return MintTokensUseCase.execute(asset, mintAmount.toString())
    }
  }
}
