import { Token } from '@uniswap/sdk-core'
import { ContractRepository } from './ContractRepository'
import EthereumClient from './EthereumClient'
import { FeeAmount, Pool } from '@uniswap/v3-sdk'
import UniswapV3Pool from '@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json'
import { BigNumber, Contract, ContractInterface } from 'ethers'

export class UniswapFetcherService {
  static async fetchTokenData(tokenAddress: string, symbol?: string) {
    const contractRepository = new ContractRepository(tokenAddress)
    const decimals = await contractRepository.decimals()
    const provider = EthereumClient.defaultProvider
    const chainId = provider.network.chainId
    return new Token(chainId, tokenAddress, decimals, symbol)
  }

  static async fetchPoolData(token1: Token, token2: Token, fee = FeeAmount.LOW): Promise<Pool | null> {
    const provider = await EthereumClient.currentProvider()
    const poolAddress = Pool.getAddress(token1, token2, fee)

    const poolContract = new Contract(poolAddress, UniswapV3Pool.abi as ContractInterface, provider)

    const slot0 = await poolContract.slot0()

    const sqrtPriceX96 = slot0.sqrtPriceX96
    const liquidity = await poolContract.liquidity()
    const tickCurrent = slot0.tick
    if ((liquidity as BigNumber).isZero()) {
      return null
    }
    return new Pool(token1, token2, fee, sqrtPriceX96, liquidity, tickCurrent)
  }
}
