import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { OpenDialogInput, OpenSnackbarPayload, ThemeType, UIState } from './ui.types'
import { userPrefersDark } from '../../../utils/matchMedia'
import { SnackbarKey } from 'notistack'

export const INITIAL_STATE: UIState = {
  snackbars: [],
  theme: userPrefersDark ? 'DARK' : 'LIGHT',
  showLoader: false,
  dialog: {
    open: false,
    blocking: false,
    content: null,
    fullWidth: false,
    maxWidth: false
  }
}

export const uiStore = createSlice({
  name: 'uiStore',
  initialState: INITIAL_STATE,
  reducers: {
    openSnackbar: (state, action: PayloadAction<OpenSnackbarPayload>) => {
      const { snackbarKey, autoHide, severity, ...restSnackbar } = action.payload
      state.snackbars = [
        ...state.snackbars,
        {
          snackbarKey: snackbarKey || String(new Date().getTime() + Math.random()),
          autoHide: autoHide === undefined ? 6000 : autoHide,
          severity: severity ?? 'success',
          ...restSnackbar
        }
      ]
    },
    removeSnackbar: (state, action: PayloadAction<SnackbarKey>) => {
      state.snackbars = state.snackbars.filter((snackbar) => snackbar.snackbarKey !== action.payload)
    },
    closeSnackbar: (state, action: PayloadAction<SnackbarKey | undefined>) => {
      state.snackbars = state.snackbars.map((snackbar) => {
        return (!action.payload && !snackbar.autoHide) || snackbar.snackbarKey === action.payload ? { ...snackbar, dismissed: true } : { ...snackbar }
      })
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload
    },
    openDialog: (state, action: PayloadAction<OpenDialogInput>) => {
      state.dialog.content = action.payload.content
      state.dialog.open = true
      state.dialog.blocking = action.payload.blocking ?? false
      state.dialog.fullWidth = action.payload.fullWidth ?? state.dialog.fullWidth
      state.dialog.maxWidth = action.payload.maxWidth ?? state.dialog.maxWidth

    },
    closeDialog: (state) => {
      state.dialog.open = false
      state.dialog.blocking = false
      state.dialog.content = null
      state.dialog.fullWidth = false
      state.dialog.maxWidth = false
    },
    setTheme: (state, action: PayloadAction<ThemeType>) => {
      state.theme = action.payload
    }
  }
})

export const { openSnackbar, closeSnackbar, removeSnackbar, setLoader, openDialog, closeDialog, setTheme } = uiStore.actions

export default uiStore.reducer
