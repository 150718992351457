import { Asset } from '../domain/models/Asset'
import { plainToClass } from 'class-transformer'
import { Page } from '../../common/domain/models/Page'
import { HttpClient } from '../../common/data/HttpClient'
import { PageAPI } from '../../common/data/models/PageAPI'
import { AssetCreateInputModel } from './models/AssetCreateInputModel'
import { createFilesFormData } from '../../common/utils/functions'
import { GetAssetsOutputModel } from './models/GetAssetsOutputModel'
import { AssetUpdateInputModel } from './models/AssetUpdateInputModel'
import { AssetMintInputModel } from './models/AssetMintInputModel'
import { AssetBurnInputModel } from './models/AssetBurnInputModel'
import {AssetPauseInputModel} from "./models/AssetPauseInputModel";
import {AssetUnpauseInputModel} from "./models/AssetUnpauseInputModel";
import {TransferTokenModel} from "./models/TransferTokenModel";
import {TransferLiquidityTokenModel} from "./models/TransferLiquidityTokenModel";

export class AssetsRepository {
  static async get(params?: GetAssetsOutputModel): Promise<Page<Asset>> {
    const { data } = await HttpClient.instance.get<PageAPI>('/actives/', { params })
    return {
      totalCount: data.count,
      hasNextPage: Boolean(data.next),
      items: data.results?.map((item: any) => plainToClass(Asset, item, { excludeExtraneousValues: true }))
    }
  }

  static async detail(assetId: string | number | undefined): Promise<Asset> {
    const { data } = await HttpClient.instance.get(`/actives/${assetId}/`)
    return plainToClass(Asset, data, { excludeExtraneousValues: true })
  }

  static async create(body: AssetCreateInputModel): Promise<Asset> {
    const { data } = await HttpClient.instance.post('/actives/', createFilesFormData(body), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return plainToClass(Asset, data, { excludeExtraneousValues: true })
  }

  static async update({ id, ...restBody }: AssetUpdateInputModel): Promise<Asset> {
    const { data } = await HttpClient.instance.put(`/actives/${id}/`, createFilesFormData(restBody), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return plainToClass(Asset, data, { excludeExtraneousValues: true })
  }

  static async mint({ id, amount }: AssetMintInputModel): Promise<any> {
    const { data } = await HttpClient.instance.post(`/actives/${id}/mint/`, { amount })
    return data.transaction
  }

  static async pause({ id }: AssetPauseInputModel): Promise<any> {
    const { data } = await HttpClient.instance.post(`/actives/${id}/pause/` )
    return data.transaction
  }

  static async unpause({ id }: AssetUnpauseInputModel): Promise<any> {
    const { data } = await HttpClient.instance.post(`/actives/${id}/unpause/` )
    return data.transaction
  }

  static async burn({ id, amount, address }: AssetBurnInputModel): Promise<any> {
    const { data } = await HttpClient.instance.post(`/actives/${id}/burn/`, { amount, address })
    return data.transaction
  }

  static async sign(assetId: string): Promise<any> {
    const { data } = await HttpClient.instance.post(`/actives/${assetId}/docu_sign_active/` )
    return data.url.redirect_url
  }

  static async transferToken(body: TransferTokenModel): Promise<any> {
    const { data } = await HttpClient.instance.post('/transference/token/', body )
    return data.transaction
  }

  static async transferETH(body: TransferTokenModel): Promise<any> {
    const { data } = await HttpClient.instance.post(`/transference/eth/`, body )
    return data.transaction
  }

  static async transferNFT(body: TransferLiquidityTokenModel): Promise<any> {
    const { data } = await HttpClient.instance.post(`/transference/nft/`, body )
    return data.transaction
  }


}
