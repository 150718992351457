import { AppRoute } from '../../../common/view/router/config'
import AssetsPage from '../components/assets-page/AssetsPage'
import AssetDetailPage from '../components/asset-detail-page/AssetDetailPage'

export const assetRoutes: Array<AppRoute> = [
  {
    path: '/u/assets/:id',
    component: AssetDetailPage
  },
  {
    path: '/u/assets',
    component: AssetsPage
  }
]
