import { EthApiRepository } from '../../../data/EthApiRepository'
import {FeeAmount} from '@uniswap/v3-sdk'
import {Token} from "@uniswap/sdk-core";
import SwappableTokens from "../../models/SwappableTokens";
import {UniswapFetcherService} from "../../../data/UniswapFetcherService";
import {utils} from "ethers";

export interface SwapWithoutWalletUseCaseInput {
  tokenIn: string
  tokenOut: string
  amount_in: string,
  amount_out_min: string,
  fee: FeeAmount
}

export class SwapWithoutWalletUseCase {
  inToken: Token | null = null
  outToken: Token | null = null

  async execute({ tokenIn, tokenOut, amount_in, amount_out_min, fee }: SwapWithoutWalletUseCaseInput): Promise<string> {

    this.inToken = await this.getTokenData(tokenIn)
    this.outToken = await this.getTokenData(tokenOut)

    const outputModel = {
      amount_in: utils.parseUnits(amount_in.toString(), this.inToken.decimals).toString(),
      amount_out_min: utils.parseUnits(amount_out_min.toString(), this.outToken.decimals).toString(),
      token_1: tokenIn,
      token_2: tokenOut,
      fee
    }

    return EthApiRepository.swap(outputModel)
  }

  private async getTokenData(token: string | Token): Promise<Token> {
    if (typeof token === 'string') {
      const tokenFromSwappableObj = SwappableTokens.getTokenByAddress(token)
      if (tokenFromSwappableObj) return tokenFromSwappableObj

      return await UniswapFetcherService.fetchTokenData(token)
    }
    return token
  }
}
