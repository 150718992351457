import { useCallback, useEffect, useRef, useState } from 'react'
import { Asset } from '../../domain/models/Asset'
import { CancelablePromise, makeCancelable } from '../../../common/utils/promise'
import { Page } from '../../../common/domain/models/Page'
import { AssetsRepository } from '../../data/AssetsRepository'

const ROWS_PER_PAGE = 50

export const useAssetsList = (ownerClientId?: string) => {
  const [loading, setLoading] = useState(true)
  const page = useRef(0)
  const [assetsPage, setAssetsPage] = useState<Page<Asset>>({ items: [], totalCount: 0, hasNextPage: false })

  let loadingPromise = useRef<CancelablePromise<Page<Asset>> | null>(null)

  const getAssets = useCallback(() => {
    setLoading(true)
    loadingPromise.current = makeCancelable<Page<Asset>>(AssetsRepository.get({ owner: ownerClientId }))
    loadingPromise.current?.promise
      .then((page) => {
        setAssetsPage(page)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [ownerClientId])

  const getMoreAssets = async () => {
    if (assetsPage.hasNextPage && !loading) {
      setLoading(true)
      page.current = page.current + 1
      loadingPromise.current = makeCancelable<Page<Asset>>(AssetsRepository.get({ offset: ROWS_PER_PAGE * page.current, limit: ROWS_PER_PAGE }))
      try {
        const page = await loadingPromise.current?.promise
        setAssetsPage({ items: [...assetsPage.items, ...page.items], hasNextPage: page.hasNextPage, totalCount: page.totalCount })
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    getAssets()
    return () => {
      loadingPromise?.current?.cancel()
    }
  }, [getAssets])

  return {
    assetsPage,
    loading,
    getAssets,
    getMoreAssets
  }
}
