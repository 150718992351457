import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    padding: '1vw',
    marginTop: '15vw',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  logo: {
    width: '40px',
    fontSize: 38,
    marginRight: '20px',
  },
  copyRight: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem'
    }
  }
}))

export default useStyles
