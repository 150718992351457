import { Button, ButtonProps } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { MetamaskService } from '../../../data/MetamaskService'
import { useAppDispatch } from '../../../../common/view/store/store'
import { useSelector } from 'react-redux'
import { getMetamaskConnectionStatus } from '../../store/eth.selectors'
import { MetamaskConnectionStatus } from '../../../domain/models/MetamaskConnectionStatus'
import { metamaskConnectThunk } from '../../store/eth.slice'
import { getUserClientAddressChanged, getUserSuperUserStatus } from '../../../../user/view/store/user.selectors'

const ConnectMetamaskBtn = ({ variant = 'contained', size = 'small', ...rest }: ButtonProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const metamaskConnectionStatus = useSelector(getMetamaskConnectionStatus)
  const clientEthAddressChanged = useSelector(getUserClientAddressChanged)
  const isSuperAdmin = useSelector(getUserSuperUserStatus)

  const disableBtn =
    (metamaskConnectionStatus === MetamaskConnectionStatus.CONNECTED && (clientEthAddressChanged || isSuperAdmin)) ||
    (!MetamaskService.hasMetamaskInBrowser && metamaskConnectionStatus !== MetamaskConnectionStatus.DISCONNECTED)

  return (
    <Button variant={variant} size={size} {...rest} onClick={() => dispatch(metamaskConnectThunk())} disabled={disableBtn}>
      {t('metamask.connectBtn')}
    </Button>
  )
}

export default ConnectMetamaskBtn
