import axios, { AxiosInstance } from 'axios'
import { EnvVars } from '../config/EnvVars'
import * as Sentry from '@sentry/react'
import {getCookie} from "../utils/functions";

class HttpClientFactory {
  instance: AxiosInstance

  constructor() {
    this.instance = axios.create({
      baseURL: `${EnvVars.apiUrl}s/api/1.0`,
      withCredentials: true
    })
    this.addInterceptor()
  }

  private addInterceptor() {
    this.instance.interceptors.request.use(
        (config) => {
          const token = getCookie('csrftoken')
          if (token) config.headers['X-CSRFToken'] = token;
          return config;
       },
    )
    this.instance.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        Sentry.addBreadcrumb({
          level: Sentry.Severity.Error,
          message: `Error in request response: ${error.response.config.url}`,
          data: {
            status: error.response.status,
            statusText: error.response.statusText,
            response: error.response.data
          }
        })
        return Promise.reject(error)
      }
    )
  }
}

export const HttpClient = new HttpClientFactory()
