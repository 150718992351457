import { makeStyles } from '@material-ui/core/styles'
import { SnackbarState } from '../../store/ui/ui.types'

const useStyles = makeStyles((theme) => ({
  "& .MuiAlert-icon": {
    alignSelf: 'center'
  },
  root: {
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important'
    },
    alignItems: 'center'
  },
  message: {
    alignSelf: 'flex-start'
  },
  circularProgress: {
    marginLeft: theme.spacing(1)
  },
  circularProgressColor: {
    color: (props: SnackbarState) => theme.palette.getContrastText(theme.palette[props.severity ?? 'info'].main)
  }
}))

export default useStyles
