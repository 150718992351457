import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  header: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: 'white',
    zIndex: 2,
    position: 'relative',
    minHeight: '100vh',
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  background: {
    zIndex: -1,
    width: '100%',
    height: 'auto',
    position: 'absolute',
    bottom: 0,
  },
  container: {
    display: 'flex',
    flex: 1,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    width: '100%',
    overflowX: 'auto',
    zIndex: 2,
  }
}))

export default useStyles
