import { makeStyles, Theme } from '@material-ui/core/styles'
import { Props } from './QrCode'

const useStyles = makeStyles<Theme, Props>((theme) => {
  return {
    btn: {
      display: 'flex',
      position: 'relative',
      padding: ({ disabled }) => theme.spacing(!disabled ? 0 : 5),
    },
    closeBtn: {
      position: 'absolute',
      right: theme.spacing(0.5),
      top: theme.spacing(0.5),
      zIndex: 10000,
      border: '2px solid red',
      width: theme.spacing(4),
      height: theme.spacing(4),
    }
  }
})

export default useStyles
