import { useTranslation } from 'react-i18next'
import { DialogTitle } from '@material-ui/core'

import { AssetForm } from '../asset-form/AssetForm';

import { Asset } from '../../../domain/models/Asset'

interface Props {
  asset: Asset
  onSuccess: () => void
}

export const EditAssetDialog = ({ onSuccess, asset }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <DialogTitle id="form-dialog-title">{t('assets.edition.title', { assetName: asset.name })}</DialogTitle>
      <AssetForm asset={asset} onSuccess={onSuccess} />
    </>
  )
}
