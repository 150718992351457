import SecurityToken from '../../common/assets/abis/SecurityToken.json'
import EthereumClient from './EthereumClient'
import { BigNumber, Contract, ContractInterface, providers } from 'ethers'
import { UniswapFetcherService } from './UniswapFetcherService'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'

export class ContractRepository {
  contract: Contract

  constructor(assetTokenAddress: string) {
    this.contract = new Contract(assetTokenAddress, SecurityToken.abi as ContractInterface)
  }

  async totalSupply(): Promise<CurrencyAmount<Token>> {
    const provider = EthereumClient.defaultProvider
    const contract = this.contract.connect(provider)
    const res: BigNumber = await contract.callStatic.totalSupply()
    const assetToken: Token = await UniswapFetcherService.fetchTokenData(this.contract.address)
    return CurrencyAmount.fromRawAmount(assetToken, res.toString())
  }

  async paused(): Promise<boolean> {
    const provider = EthereumClient.defaultProvider
    const contract = this.contract.connect(provider)
    const res: boolean = await contract.callStatic.paused()
    return res
  }

  async balance(walletPublicAddress: string): Promise<CurrencyAmount<Token>> {
    const provider = await EthereumClient.currentProvider()
    const contract = this.contract.connect(provider)
    const res: BigNumber = await contract.callStatic.balanceOf(walletPublicAddress)
    const assetToken: Token = await UniswapFetcherService.fetchTokenData(this.contract.address)
    return CurrencyAmount.fromRawAmount(assetToken, res.toString())
  }

  async decimals(): Promise<number> {
    const provider = EthereumClient.defaultProvider
    const contract = this.contract.connect(provider)
    return contract.callStatic.decimals()
  }

  async mint(amount: string, accountAddress?: string): Promise<providers.TransactionResponse> {
    let _address = accountAddress
    const provider = await EthereumClient.currentProvider()
    const contract = this.contract.connect(provider.getSigner())
    if (!accountAddress) {
      _address = await provider.getSigner().getAddress()
    }
    return contract.mint(_address, amount)
  }

  async pause(accountAddress?: string): Promise<providers.TransactionResponse> {
    let _address = accountAddress
    const provider = await EthereumClient.currentProvider()
    const contract = this.contract.connect(provider.getSigner())
    if (!accountAddress) {
      _address = await provider.getSigner().getAddress()
    }
    return contract.pause(_address)
  }

  async unpause(accountAddress?: string): Promise<providers.TransactionResponse> {
    let _address = accountAddress
    const provider = await EthereumClient.currentProvider()
    const contract = this.contract.connect(provider.getSigner())
    if (!accountAddress) {
      _address = await provider.getSigner().getAddress()
    }
    return contract.unpause(_address)
  }

  async burn(amount: string, address: string, accountAddress?: string): Promise<providers.TransactionResponse> {
    let _address = accountAddress
    const provider = await EthereumClient.currentProvider()
    const contract = this.contract.connect(provider.getSigner())
    if (!accountAddress) {
      _address = await provider.getSigner().getAddress()
    }
    return contract.burn(_address, amount, address)
  }
}
