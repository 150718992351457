import { Asset } from '../../domain/models/Asset'
import { GetAssetAmountDataUseCase } from '../../domain/use-cases/GetAssetAmountDataUseCase'
import { useEffect, useRef } from 'react'
import { usePrevious } from '../../../common/view/hooks/Previous.hook'
import { useForceUpdate } from '../../../common/view/hooks/ForceUpdate.hook'
import { useSelector } from 'react-redux'
import { getMetamaskConnectionStatus } from '../../../eth/view/store/eth.selectors'
import { AssetAmountData } from '../../domain/models/AssetAmountData'
import { useAssetsList } from './AssetsList.hook'
import {getUserClientWalletAddress} from "../../../user/view/store/user.selectors";

export const useAssetListWithAmount = (ownerClientId?: string) => {
  const { assetsPage, getMoreAssets, getAssets, loading } = useAssetsList(ownerClientId)
  const assetsAmountMap = useRef<Map<string, AssetAmountData & { loading?: boolean }>>(new Map())
  const forceUpdate = useForceUpdate()
  const userWalletAddress = useSelector(getUserClientWalletAddress) || ''
  const metamaskConnectionStatus = useSelector(getMetamaskConnectionStatus)
  const previousMetamaskConnectionStatus = usePrevious(metamaskConnectionStatus)

  const getAssetAmount = async (asset: Asset, force = false) => {
    if (!assetsAmountMap.current?.has(asset.id) || force) {
      assetsAmountMap.current?.set(asset.id, { loading: true })
      try {
        const getAssetAmountDataUseCase = new GetAssetAmountDataUseCase()
        const { clientBalance, totalSupply, prices } = await getAssetAmountDataUseCase.execute(asset, userWalletAddress)
        // It will update ref and update layout thanks to react virtualized.
        // Force updated will be used when batch finishes in case user does not scroll.
        // This is to avoid many setState to avoid hundreds of re renders
        assetsAmountMap.current?.set(asset.id, { loading: false, clientBalance, totalSupply, prices })
      } catch (e) {
        assetsAmountMap.current?.set(asset.id, { loading: false })
        console.error(e)
      }
    }
  }

  useEffect(() => {
    const metamaskStatusChanged = previousMetamaskConnectionStatus !== metamaskConnectionStatus
    const lastIdx = assetsPage.items.length - 1
    assetsPage.items.forEach((asset, idx) => {
      getAssetAmount(asset, metamaskStatusChanged).finally(() => {
        if (idx === lastIdx || idx === Math.round(lastIdx / 2)) {
          forceUpdate() // force update used when certain amount of asset amount data loaded
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsPage.items, metamaskConnectionStatus])

  return {
    assetsPage,
    loading,
    getAssets,
    getMoreAssets,
    assetsAmountMap
  }
}
