import { makeStyles, Theme } from '@material-ui/core/styles'
import { Props } from './AssetCard'

const useStyles = makeStyles<Theme, Props>((theme) => {
  return {
    wrapper: {
      width: 300,
      border: `1px solid ${theme.palette.primary.main}`,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 30,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(4),
      paddingTop: 0,
      [theme.breakpoints.down('xs')]: {
        width: 280
      }
    },
    top: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    fontSmall: {
      fontSize: '0.8rem',
      marginBottom: theme.spacing(0.5),
    },
    bar: {
      position: 'absolute',
      top: 0,
      left: -5,
      height: 0,
      width: 0,
      borderLeft: `5px solid transparent`,
      borderRight: `5px solid transparent`,
      borderBottom: `5px solid ${theme.palette.primary.main}`
    },
    barLeft: {
      position: 'absolute',
      top: 0,
      right: -5,
      height: 0,
      width: 0,
      borderLeft: `5px solid transparent`,
      borderRight: `5px solid transparent`,
      borderBottom: `5px solid ${theme.palette.primary.main}`
    },
    avatar: {
      width: 95,
      height: 95,
      marginTop: 10,
      color: theme.palette.text.primary,
      borderRadius: theme.spacing(8),
      '& > h4': {
        color: theme.palette.getContrastText(theme.palette.primary.light)
      },
      [theme.breakpoints.down('xs')]: {
        width: 70,
        height: 70,
      }
    },
    avatarWrapper: {
      position: 'relative',
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(1)
    },
    tokenSymbol: {
      color: theme.palette.common.white,
      fontSize: '1em',
      [theme.breakpoints.down('xs')]: {
        fontSize: '.8em'
      }
    },
    nameWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: 120,
      width: '100%',
      marginLeft: 10,
      [theme.breakpoints.down('xs')]: {
        height: 90
      }
    },
    tokenName: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5em'
      }
    },
    loader: {
      height: 80,
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2)
    },
    assetAmountData: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(1)
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center'
    },
    button: {
      backgroundColor: theme.palette.secondary.main,
      alignSelf: 'center',
      height: 50,
      width: 150
    },
    buttonText: {
      color: theme.palette.common.white
    }
  }
})

export default useStyles
