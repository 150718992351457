import { TradeExecutionPrice } from '../models/TradeExecutionPrice'
import { OperationType } from '../models/OperationType'
import {CurrencyAmount, Token} from '@uniswap/sdk-core'
import {FeeAmount} from "@uniswap/v3-sdk";
import { abi as QuoterABI } from "@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json";
import EthereumClient, {UNISWAP_QUOTER_ADDRESS} from "../../data/EthereumClient";
import {Contract, ContractInterface, utils} from "ethers";
import SwappableTokens from "../models/SwappableTokens";
import {UniswapFetcherService} from "../../data/UniswapFetcherService";

export interface TradeExecutionPriceUseCaseInput {
  assetToken: string | Token
  token: string | Token
  amount: string
  fee: FeeAmount
  operationType?: OperationType
}

export class GetTradeExecutionPriceUseCase {
  inToken: Token | null = null
  outToken: Token | null = null

  async execute({ assetToken, token, amount, fee, operationType = 'buy' }: TradeExecutionPriceUseCaseInput): Promise<TradeExecutionPrice> {
    const provider = await EthereumClient.currentProvider()
    const quoterContract = new Contract(UNISWAP_QUOTER_ADDRESS, QuoterABI as ContractInterface, provider)

    this.inToken = operationType === 'buy' ? await this.getTokenData(token) : await this.getTokenData(assetToken)
    this.outToken = operationType === 'buy' ? await this.getTokenData(assetToken) : await this.getTokenData(token)

    const amountOut = await quoterContract.callStatic.quoteExactInputSingle(
      this.inToken.address,
      this.outToken.address,
      fee,
      utils.parseUnits(amount.toString(), this.inToken.decimals),
      0
    );

    return {
      outputAmount: CurrencyAmount.fromRawAmount(this.outToken, amountOut.toString())
    }
  }

  private async getTokenData(token: string | Token): Promise<Token> {
    if (typeof token === 'string') {
      const tokenFromSwappableObj = SwappableTokens.getTokenByAddress(token)
      if (tokenFromSwappableObj) return tokenFromSwappableObj

      return await UniswapFetcherService.fetchTokenData(token)
    }
    return token
  }
}
