import { Asset } from '../../../assets/domain/models/Asset'
import { AssetsRepository } from '../../../assets/data/AssetsRepository'
import { GetAssetAmountDataUseCase } from '../../../assets/domain/use-cases/GetAssetAmountDataUseCase'

const ROWS_PER_PAGE = 100

export class GetTokenPositionsUseCase {
  getAssetAmountDataUseCase: GetAssetAmountDataUseCase

  constructor() {
    this.getAssetAmountDataUseCase = new GetAssetAmountDataUseCase()
  }

  async execute(clientAddress: string): Promise<Array<Asset>> {
    let assets = await this.getAssetsRecursively()
    assets = await this.getAmountDataPorAsset(assets, clientAddress)

    return assets.filter((asset) => asset.amountData?.clientBalance?.greaterThan('0'))
  }

  private async getAmountDataPorAsset(assets: Array<Asset>, clientAddress: string): Promise<Array<Asset>> {
    return await Promise.all(
      assets.map(async (asset) => {
        const { clientBalance, totalSupply, prices, presaleBalance } = await this.getAssetAmountDataUseCase.execute(asset, clientAddress)
        const result = {
          ...asset,
          amountData: { clientBalance, totalSupply, prices, presaleBalance }
        }
        return result;
      })
    )
  }

  private async getAssetsRecursively(page = 0, pageItems: Array<Asset> = []): Promise<Array<Asset>> {
    let { items: _pageItems, hasNextPage } = await AssetsRepository.get({ offset: ROWS_PER_PAGE * page, limit: ROWS_PER_PAGE })
    _pageItems = [...pageItems, ..._pageItems]
    if (hasNextPage) {
      return this.getAssetsRecursively(page + 1, _pageItems)
    } else {
      return _pageItems
    }
  }
}
