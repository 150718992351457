import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {useDispatch} from 'react-redux'
import {closeDialog, openSnackbar, setLoader} from '../../../../common/view/store/ui/ui.slice'
import { Form, Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../common/i18n/config'
import yup from '../../../../common/utils/yup.extended'
import React, { useRef} from 'react'
import {Grid} from '@material-ui/core'
import { AglaiaDialogContent } from '../../../../common/view/components/app-dialog/AppDialog.styled'
import {useEthErrorsWrapper} from "../../../../eth/view/hooks/EthErrorsWrapper.hook";
import {TransferLiquidityTokenModel} from "../../../../assets/data/models/TransferLiquidityTokenModel";
import {TransferLiquidityTokenInteractor} from "../../../../eth/domain/use-cases/TransferLiquidityTokenInteractor/TransferLiquidityTokenInteractor";
import {EnvVars} from "../../../../common/config/EnvVars";
import {checkIfTxResponse} from "../../../../eth/utils/ethers";
import { providers } from 'ethers'
import EthereumClient from "../../../../eth/data/EthereumClient";
import { addTransaction, changeTransactionStatus } from '../../../../eth/view/store/eth.slice'

interface FormValues {
  to: string
}

const formInitialValues: FormValues = {
  to: EnvVars.bridgeAddress
}

const validationSchema = yup.object().shape({
  to: yup.string().required(i18n.t('formErrors.required')),
})

interface Props {
  tokenId: number
}

export const TransferLiquidityDialog = ({tokenId} : Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const TransferLiquidityUseCase = useEthErrorsWrapper(TransferLiquidityTokenInteractor.execute)
  const formRef = useRef<FormikProps<FormValues>>(null)

  const handleCloseDialog = () => {
    dispatch(closeDialog())
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      dispatch(setLoader(true))

      const input: TransferLiquidityTokenModel = {
         destination_address: values.to,
         token_id: tokenId
      }

      const txRes = await TransferLiquidityUseCase(input)
      if (checkIfTxResponse(txRes)) {
        const castedTxRes = txRes as providers.TransactionResponse
        dispatch(addTransaction({ hash: castedTxRes.hash, type: 'nft' }))
        handleCloseDialog()
        await castedTxRes?.wait()
        dispatch(changeTransactionStatus({ hash: castedTxRes.hash, status: 'completed' }))
      } else {
        const castedTxResHash = txRes as string
        const provider = await EthereumClient.currentProvider()
        dispatch(addTransaction({ hash: castedTxResHash, type: 'nft' }))
        handleCloseDialog()
        await provider.waitForTransaction(castedTxResHash)
        dispatch(changeTransactionStatus({ hash: castedTxResHash, status: 'completed' }))
      }
      dispatch(setLoader(false))
    } catch (e) {
      console.error(e)
      dispatch(openSnackbar({ message: t('assets.swap.swapKo') }))
      dispatch(setLoader(false))
    }
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      enableReinitialize>
      {({values, isValid, errors, handleChange, touched}: FormikProps<FormValues>) => {
        return (
          <>
            <DialogTitle id="form-dialog-title">{t('balance.transfer.title')}</DialogTitle>
            <AglaiaDialogContent>
              <Form noValidate>
                <Grid container spacing={2} direction="column" justify="flex-start" alignItems="flex-start">
                  <Grid container item xs>
                      <TextField
                        name="to"
                        variant="outlined"
                        id="to"
                        label={t('balance.transfer.to')}
                        fullWidth
                        onChange={handleChange}
                        value={values.to}
                        error={Boolean(touched.to) && Boolean(errors.to)}
                        helperText={Boolean(touched.to) && Boolean(errors.to) && errors.to}
                      />
                  </Grid>
                </Grid>
              </Form>
            </AglaiaDialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleCloseDialog}>
                {t('assets.swap.cancelBtn')}
              </Button>
              <Button color="primary" onClick={() => formRef.current?.submitForm()}>
                {t('balance.transfer.submitBtn')}
              </Button>
            </DialogActions>
          </>
        )
      }}
    </Formik>
  )
}
