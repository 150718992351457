import { makeStyles, withStyles, styled } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export const CompanyName = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    textAlign: 'center',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  }
}))(Typography)

export const BackgroundImage = styled('div')({
  background: 'black',
  width: '100vw',
})

export const MainContainer = styled('div')({

})

const useStyles = makeStyles((theme) => ({
  themeSelector: {
    position: 'fixed',
    top: 20,
    right: 20
  },
  container: {
    overflow: 'hidden'
  },
  shape: {
    transform: 'skew(-20deg, 0deg)',
    position: 'fixed',
    background: 'black',
    zIndex: 0,
    top: 0,
    right: -200,
    height: '100vh',
    width: '600px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '0'
    }
  },
  paper: {
    minHeight: '100vh',
    position: 'relative',
    zIndex: 2,
    width: '100vw',
    display: 'flex',
    justifyContent: 'space-between',
    '& > h3': {
      marginBottom: theme.spacing(1)
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    overflowY: 'auto'
  },
  logoBlack: {
    width: 85,
    [theme.breakpoints.down('sm')]: {
      width: 50
    }
  },
  logo: {
    width: 120,
    [theme.breakpoints.down('sm')]: {
      width: 80
    }
  },
  paperHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -theme.spacing(3),
  },
  preregisterTabs: {
    marginBottom: 25,
    fontSize: 20,
    "& .MuiTabs-indicator": {
      backgroundColor: "white",
    }
  },
  disclaimer: {
    marginTop: '15px',
    "& a": {
      fontSize: "small",
      color: "white"
    }
  },
  preregisterLogo: {
    width: '210px',
    margin: 10,
    [theme.breakpoints.down('sm')]: {
      width: '60%',
      maxWidth: '275px'
    },
  },
  preregisterSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '30px 60px',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      padding: '30px 30px',
    },
    '& em' : {
      color: theme.palette.primary.main,
      fontStyle: 'normal',
      fontWeight: 'bold'
    }
  },
  preregisterTitle: {
    padding: 15,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  preregisterTitleName: {
    display: 'inline-block',
    '& h6': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      },
    }
  },
  section: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',
  },
  sectionRight: {
    display: 'flex',
    width: '700px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '30px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      flex: 1,
      padding: '0 30px',
    },
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  formDivider: {
    margin: theme.spacing(2, 0)
  },
  signUpPaper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '20px',
      marginBottom: '30px'
    },
    width: 446,
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorHelper: {
    marginLeft: theme.spacing(1),
  },
  checkBoxContainer: {
    marginLeft: theme.spacing(1),
    flexDirection: 'row',
    display: 'flex',
  },
  generalError: {
    marginTop: theme.spacing(1)
  },
  certify: {

  },
  subtitle: {
    marginBottom: 15
  },
  formSubtitle: {
    fontSize: 'small',
    fontWeight: 400,
    marginBottom: 20,
    display: 'inline-block'
  },
  registeredText: {
    fontWeight: 400,
    fontSize: 18
  }
}))

export default useStyles
