import React, { useCallback, useEffect, useRef, useState } from 'react'
import { metamaskConnectionCheckThunk, metamaskDisconnectThunk, setMetamaskStatus } from '../store/eth.slice'
import { MetamaskConnectionStatus } from '../../domain/models/MetamaskConnectionStatus'
import { closeDialog, openDialog, openSnackbar } from '../../../common/view/store/ui/ui.slice'
import MetamaskConnectBtn from '../components/metamask-conect-btn/MetamaskConnectBtn'
import { MetamaskService } from '../../data/MetamaskService'
import { useSelector } from 'react-redux'
import { getMetamaskConnectionStatus } from '../store/eth.selectors'
import { useAppDispatch } from '../../../common/view/store/store'
import { Trans, useTranslation } from 'react-i18next'
import { getUserClientAddressChanged, getUserSuperUserStatus } from '../../../user/view/store/user.selectors'
import AlertDialog from '../../../common/view/components/alert-dialog/AlertDialog'
import { usePrevious } from '../../../common/view/hooks/Previous.hook'
import EthereumClient, { Web3CheckErrors } from '../../data/EthereumClient'
import { ErrorType } from '../../../common/domain/models/ErrorType'
import { useHistory } from 'react-router-dom'
import { EnvVars } from '../../../common/config/EnvVars'

export const useOnceMetamask = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const metamaskConnectionStatus = useSelector(getMetamaskConnectionStatus)
  const clientEthAddressChanged = useSelector(getUserClientAddressChanged)
  const isSuperAdmin = useSelector(getUserSuperUserStatus)
  const prevConnectionStatus = usePrevious(metamaskConnectionStatus)
  const [currentMetamaskAddress, setCurrentMetamaskAddress] = useState<string>('')
  const previousMetamaskAddress = usePrevious(currentMetamaskAddress)
  const [currentMetamaskChainId, setCurrentMetamaskChainId] = useState<string>('')
  const previousMetamaskChainId = usePrevious(currentMetamaskChainId)
  const defaultChainId = useRef<string | null>(null)
  const router = useHistory()

  // --- Methods ---
  const updateMetamaskAddressState = async (newAddress?: string) => {
    const currentAddress = newAddress ? newAddress : EthereumClient.metamaskProvider?.selectedAddress
    if (currentAddress) setCurrentMetamaskAddress(currentAddress)
  }

  const updateMetamaskChainIdState = async (newChainId?: string) => {
    let chainId = null
    try {
      const provider = await EthereumClient.currentProvider()
      chainId = String(provider.network.chainId)
    } catch (e) {} // swallow error
    const currentChainId = newChainId ? newChainId : chainId
    if (currentChainId) setCurrentMetamaskChainId(currentChainId)
  }

  const showDialogWrongMetamaskAddress = (address: string) => {
    dispatch(
      openDialog({
        maxWidth: 'sm',
        blocking: true,
        fullWidth: true,
        content: () => (
          <AlertDialog
            variant="error"
            action={() => <MetamaskConnectBtn variant="outlined" size="medium" color="primary" />}
            noClose
            title={`${t('metamask.alert.wrongAddress.title')}`}
            description={<Trans i18nKey={'metamask.alert.wrongAddress.description'} values={{ address: address }} components={{ b: <strong /> }} />}
          />
        )
      })
    )
  }

  const showDialogWrongChainId = (chainId: string) => {
    dispatch(
      openDialog({
        maxWidth: 'sm',
        blocking: true,
        fullWidth: true,
        content: () => (
          <AlertDialog
            variant="error"
            noClose
            title={`${t('metamask.alert.wrongChainId.title')}`}
            description={<Trans i18nKey={'metamask.alert.wrongChainId.description'} values={{ chainId }} components={{ b: <strong /> }} />}
          />
        )
      })
    )
  }

  const showDialogLinkedAddressNotConnected = () => {
    dispatch(
      openDialog({
        maxWidth: 'sm',
        blocking: true,
        fullWidth: true,
        content: () => (
          <AlertDialog
            variant="error"
            noClose
            action={() => <MetamaskConnectBtn variant="outlined" size="medium" color="primary" />}
            title={`${t('metamask.alert.linkedAddressNotConnected.title')}`}
            description={`${t('metamask.alert.linkedAddressNotConnected.description')}`}
          />
        )
      })
    )
  }

  const showDialogConnectMetamask = () => {
    dispatch(
      openDialog({
        maxWidth: 'sm',
        blocking: true,
        fullWidth: true,
        content: () => (
          <AlertDialog
            variant="info"
            noClose
            action={() => <MetamaskConnectBtn variant="outlined" size="medium" color="primary" />}
            title={`${t('metamask.alert.connectMetamask.title')}`}
            description={`${t('metamask.alert.connectMetamask.description')}`}
          />
        )
      })
    )
  }

  const showSnackbarConnectMetamask = () => {
    dispatch(
      openSnackbar({
        message: t('metamask.snackbar.info.message'),
        autoHide: null,
        action: () => <MetamaskConnectBtn color="primary" />,
        severity: 'info'
      })
    )
  }

  const checkWeb3ProviderErrors = useCallback(
    async (refresh = false) => {
      try {
        await EthereumClient.currentProvider()
        if (refresh) {
          window.location.reload()
        } else {
          dispatch(closeDialog())
        }
      } catch (e) {
        if (e.message.includes('errorCode')) {
          const parsedError: ErrorType<Web3CheckErrors> = JSON.parse(e.message)
          if (parsedError.errorCode === Web3CheckErrors.DIFFERENT_PUBLIC_ADDRESS && clientEthAddressChanged && !isSuperAdmin) {
            showDialogWrongMetamaskAddress(parsedError.data?.address)
          } else if (parsedError.errorCode === Web3CheckErrors.WRONG_CHAIN_ID) {
            showDialogWrongChainId(parsedError.data?.chainId)
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientEthAddressChanged, dispatch]
  )

  // --- Effects ---

  // INIT EFFECT, CHECK METAMASK CONNECTION
  useEffect(() => {
    dispatch(metamaskConnectionCheckThunk())
    if (!defaultChainId.current) {
      EthereumClient.defaultChainId().then((chainId) => {
        defaultChainId.current = String(chainId)
      })
    }
  }, [dispatch])

  // NOT CONNECTED TO CONNECTED EFFECT
  useEffect(() => {
    if (prevConnectionStatus === MetamaskConnectionStatus.NOT_CONNECTED && metamaskConnectionStatus === MetamaskConnectionStatus.CONNECTED) {
      dispatch(closeDialog())
    }
  }, [dispatch, metamaskConnectionStatus, prevConnectionStatus, router])

  // NOT CONNECTED EFFECT
  useEffect(() => {
    if (!EnvVars.metamaskAllowed) return

    switch (true) {
      case metamaskConnectionStatus === MetamaskConnectionStatus.NOT_CONNECTED && isSuperAdmin:
      case metamaskConnectionStatus === MetamaskConnectionStatus.NO_WALLET_PROVIDER && isSuperAdmin:
        showDialogConnectMetamask()
        break
      case metamaskConnectionStatus === MetamaskConnectionStatus.NOT_CONNECTED && clientEthAddressChanged:
        showDialogLinkedAddressNotConnected()
        break
      case metamaskConnectionStatus === MetamaskConnectionStatus.NOT_CONNECTED && !isSuperAdmin:
      case metamaskConnectionStatus === MetamaskConnectionStatus.CONNECTED && !clientEthAddressChanged && !isSuperAdmin:
        showSnackbarConnectMetamask()
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, metamaskConnectionStatus, t])

  // CONNECTED STATUS EFFECT
  useEffect(() => {
    //REMOVE false to allow connect to metamask
    if (metamaskConnectionStatus === MetamaskConnectionStatus.CONNECTED) {
      void updateMetamaskAddressState()
      MetamaskService.on<Array<string>>('accountsChanged', (accounts) => {
        const connected = Boolean(accounts?.length)
        if (!connected) {
          dispatch(metamaskDisconnectThunk())
        }
        void updateMetamaskAddressState(accounts[0])
      })

      MetamaskService.on<string>('chainChanged', (chainId) => {
        void updateMetamaskChainIdState(String(parseInt(chainId)))
      })

      MetamaskService.on<string>('disconnect', () => {
        void dispatch(setMetamaskStatus(MetamaskConnectionStatus.DISCONNECTED))
      })
    }

    if (metamaskConnectionStatus === MetamaskConnectionStatus.DISCONNECTED) {
      showDialogConnectMetamask()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metamaskConnectionStatus, dispatch])

  // CONNECTED ERROR CHECKS EFFECT
  useEffect(() => {
    if (metamaskConnectionStatus === MetamaskConnectionStatus.CONNECTED) {
      void checkWeb3ProviderErrors()
    }
  }, [checkWeb3ProviderErrors, clientEthAddressChanged, metamaskConnectionStatus])

  // LOCAL STATE ADDRESS CHANGE EFFECT
  useEffect(() => {
    if (previousMetamaskAddress !== currentMetamaskAddress) {
      dispatch(metamaskConnectionCheckThunk())

      // To lower case because metamask provider address is returned in lower case
      if (isSuperAdmin) {
        if (Boolean(currentMetamaskAddress.length) && currentMetamaskAddress !== EnvVars.adminEthAddress.toLowerCase()) {
          showDialogWrongMetamaskAddress(EnvVars.adminEthAddress)
        } else {
          dispatch(closeDialog())
        }
      } else {
        void checkWeb3ProviderErrors()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMetamaskAddress, dispatch, previousMetamaskAddress])

  // LOCAL STATE CHAIN ID CHANGE EFFECT
  useEffect(() => {
    if (currentMetamaskChainId !== previousMetamaskChainId) {
      if (isSuperAdmin && Boolean(currentMetamaskChainId.length)) {
        if (currentMetamaskChainId !== defaultChainId.current) {
          showDialogWrongChainId(String(defaultChainId.current))
        } else {
          window.location.reload()
        }
      }
      if (!isSuperAdmin) {
        void checkWeb3ProviderErrors(currentMetamaskChainId === defaultChainId.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMetamaskChainId, dispatch, previousMetamaskChainId])

  return {
    metamaskConnected:
      (metamaskConnectionStatus === MetamaskConnectionStatus.CONNECTED && (clientEthAddressChanged || isSuperAdmin)) ||
      (!MetamaskService.hasMetamaskInBrowser && metamaskConnectionStatus !== MetamaskConnectionStatus.DISCONNECTED)
  }
}
