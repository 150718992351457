import { ContractRepository } from '../../../eth/data/ContractRepository'
import { Asset } from '../models/Asset'

export class GetAssetPausedUseCase {
  async execute(asset: Asset): Promise<boolean> {
    const contract = new ContractRepository(asset.tokenAddress)
    const paused = await contract.paused()
    return paused
  }
}
