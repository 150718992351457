import React from 'react'
import { Typography, withWidth } from '@material-ui/core'

import useStyles from './Footer.styles'

import AglaiaLogo from '../../../assets/logos/LOGO_ACTIONMONKEY.png'
import {useTranslation} from "react-i18next";

interface Props {
  width: string
}

const Footer = ({ width }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.wrapper}>
      <img className={classes.logo} src={AglaiaLogo} alt="Aglaia Capital DeFi Platform" title="Aglaia Capital DeFi Platform"/>
      <Typography classes={{ root: classes.copyRight }}>{t('copyright')}</Typography>
    </div>
  )
}

export default withWidth()(Footer)
