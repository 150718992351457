export class EnvVars {
  static get apiUrl() {
    return process.env.REACT_APP_API_URL ?? ''
  }
  static get adminEthAddress() {
    return process.env.REACT_APP_ADMIN_ETH_ADDRESS ?? ''
  }
  static get bridgeAddress() {
    return process.env.BRIDGE_ADDRESS ?? '0x2C9a782Ea7423F531bFcAE7801cbbaB802F06512'
  }
  static get etherNetworkUrl() {
    return process.env.REACT_APP_ETHER_URL ?? ''
  }
  static get metamaskAllowed() {
    return process.env.USE_METAMASK ?? false
  }
  static get etherscanUrl() {
    return process.env.ETHERSCAN_URL ?? 'https://etherscan.io/'
  }
  static get liquidityPositionAddress() {
    //Dirección del contrato de Uniswap con los NFTs de liquidez de los pools
    return process.env.LIQUIDITY_POSITION_ADDRESS ?? '0xC36442b4a4522E871399CD717aBDD847Ab11FE88'
  }
}
