import IconButton from '@material-ui/core/IconButton'
import SunIcon from '@material-ui/icons/WbSunny'
import MoonIcon from '@material-ui/icons/NightsStay'
import React from 'react'
import useStyles from './ThemeSelector.style'
import { useAppDispatch } from '../../store/store'
import { useSelector } from 'react-redux'
import { getThemeState } from '../../store/ui/ui.selectors'
import { setTheme } from '../../store/ui/ui.slice'
import clsx from 'clsx'

interface Props {
  className?: string
  moonIconColor?: string
}

export default function ThemeSelector({ className, moonIconColor }: Props) {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const currentTheme = useSelector(getThemeState)

  const handleThemeChange = () => {
    if (currentTheme === 'LIGHT') {
      dispatch(setTheme('DARK'))
    } else {
      dispatch(setTheme('LIGHT'))
    }
  }

  return (
    <div className={clsx(classes.selectorWrapper, className)}>
      <IconButton onClick={handleThemeChange}>{currentTheme === 'LIGHT' ? <MoonIcon htmlColor={moonIconColor} /> : <SunIcon />}</IconButton>
    </div>
  )
}
