import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import useStyles from './EmptyState.style'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon/SvgIcon'
import { Variant } from '@material-ui/core/styles/createTypography'

export interface Props {
  height: string | number
  iconSize?: number
  fontVariant?: Variant | 'inherit'
  message: string
  icon: OverridableComponent<SvgIconTypeMap>
}

const EmptyState = ({ height, icon: Icon, message, iconSize, fontVariant }: Props) => {
  const classes = useStyles({ height, iconSize })
  return (
    <Grid className={classes.emptyStateRoot} container direction="column" justify="center" alignItems="center">
      <Icon className={classes.emptyStateIcon} />
      <Typography className={classes.emptyStateText} variant={fontVariant ?? 'h5'}>
        {message}
      </Typography>
    </Grid>
  )
}

export default EmptyState
