import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  Button
} from '@material-ui/core'
import {
  People as PeopleIcon,
  Check as CheckIcon,
  HowToReg as HowToRegIcon,
  FileCopy as FileCopyIcon,
  Close as UnCheckIcon,
  PersonAddDisabled as PersonAddDisabledIcon
} from '@material-ui/icons'
import { Theme } from '@material-ui/core/styles'

import useStyles, { StyledTableCell } from './ClientsListPage.styles'

import { ClientsRepository } from '../../../data/ClientsRepository'
import { CancelablePromise, makeCancelable, timeoutPromise } from '../../../../common/utils/promise'
import { Page } from '../../../../common/domain/models/Page'
import { Client } from '../../../domain/Client'

import { RowsPerPage, useTable } from '../../../../common/view/hooks/Table.hook'
import { openDialog } from '../../../../common/view/store/ui/ui.slice'
import { useAppDispatch } from '../../../../common/view/store/store'
import ActivateClientAlertDialog from '../activate-client-alert-dialog/ActivateClientAlertDialog'
import { copyTextToClipboard } from '../../../../common/utils/functions'
import {useHistory} from "react-router-dom";

interface UserListRowProps {
  client: Client
  onActivate: (client: Client) => void
  matchesSm: boolean
}

function UserListRow({ client, onActivate, matchesSm }: UserListRowProps) {
  // Deps
  const { t } = useTranslation()
  const router = useHistory()
  const classes = useStyles()

  // State
  const [openCopyTooltip, setOpenCopyTooltip] = useState(false)

  // Methods
  const handleAddressCopy = async () => {
    await copyTextToClipboard(client?.ethPublicAddress ?? '')
    setOpenCopyTooltip(true)
    await timeoutPromise(3000)
    setOpenCopyTooltip(false)
  }

  if (!client?.user) {
    return null
  }

  const { is_validated, isActive, client: userClient } = client.user
  const { is_business, business_aml_is_validated, business_know_is_validated, business_name, business_registration_number } = userClient || {}
  const active = isActive || false
  const stateText = isActive ? t('clientsList.table.activated') : t('clientsList.table.inactived')

  const activeDisplay = (
    <>
      <Button
        color={active? "primary" : "secondary"}
        size="small"
        variant="outlined"
        onClick={() => onActivate(client)}
        startIcon={!active? <PersonAddDisabledIcon/> : <HowToRegIcon />}
      >
        {stateText}
      </Button>
    </>
  )

  const renderItemMissing = (text: any) => {
    return (
      <div className={classes.missing}>
        <UnCheckIcon fontSize="small" style={{ fill: 'red' }} />
        <Typography variant="body2" align="left">
          {t(text)}
        </Typography>
      </div>
    )
  }

  const renderValidation = () => {
    if (is_validated) {
      return (
        <Grid container justify="flex-start" alignItems="center">
          <CheckIcon fontSize="small" style={{ fill: 'green' }} />
          <Typography variant="body2">{t('clientsList.table.validated')}</Typography>
        </Grid>
      )
    }

    if (is_business) {
      return (
        <div>
          {!business_aml_is_validated && renderItemMissing('clientsList.missing.businessValidation')}
          {!business_know_is_validated && renderItemMissing('clientsList.missing.knowBusinesss')}
        </div>
      )
    }
    return <div>{!is_validated && renderItemMissing('clientsList.missing.validation')}</div>
  }

  const handleOnViewDetail = (clientId: string) => {
    router.push(`/u/balance/${clientId}`)
  }

  const renderAddress = () => {
    return (
      <div className={classes.address}>
        <Tooltip
          open={openCopyTooltip}
          title={`${t('clientsList.addressCopied')}`}
          placement="top"
          classes={{ tooltip: classes.copyTokenAddressTooltip }}>
          <IconButton onClick={handleAddressCopy} color="primary">
            <FileCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {client?.ethPublicAddress}
      </div>
    )
  }
  return (
    <TableRow style={{ height: 60 }}>
      <TableCell className={classes.pointer} onClick={() => handleOnViewDetail(client?.id)}>{client?.id}</TableCell>
      <TableCell className={classes.pointer} onClick={() => handleOnViewDetail(client?.id)} component="th" scope="column">
        <div className={classes.nameWrapper}>
          {client?.fullName}
          {matchesSm && activeDisplay}
        </div>
      </TableCell>
      <TableCell className={classes.pointer} onClick={() => handleOnViewDetail(client?.id)}>
        {client?.user?.email}
        {matchesSm && renderAddress()}
      </TableCell>
      {!matchesSm && (
        <>
          <TableCell className={classes.pointer} onClick={() => handleOnViewDetail(client?.id)}>{renderAddress()}</TableCell>
          <TableCell>{is_business && <CheckIcon fontSize="small" style={{ fill: 'green' }} />}</TableCell>
          <TableCell>{(business_name ?? "") + " - " + (business_registration_number ?? "")}</TableCell>
          <TableCell align="right">{activeDisplay}</TableCell>
        </>
      )}
      <TableCell align="right">{renderValidation()}</TableCell>
    </TableRow>
  )
}

function TableEmptyState() {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <TableRow className={classes.tableEmptyRow}>
      <TableCell colSpan={4}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <PeopleIcon className={classes.tableEmptyRowIcon} />
          </Grid>
          <Grid item>
            <Typography variant="h6">{t('clientsList.noClients')}</Typography>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

function TableLoader() {
  const classes = useStyles()
  return (
    <TableRow className={classes.tableEmptyRow}>
      <TableCell colSpan={4}>
        <Grid container direction="column" justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default function ClientsListPage() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [clientsPage, setClientsPage] = useState<Page<Client>>({ items: [], totalCount: 0 })
  const [loading, setLoading] = useState(true)
  const { rowsPerPage, setRowsPerPage, page, setPage, apiParams } = useTable()

  let loadingPromise = useRef<CancelablePromise<Page<Client>> | null>(null)

  const handleRowPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value))
    setPage(0)
  }

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const activateClient = (client: Client) => {
    dispatch(openDialog({ content: () => <ActivateClientAlertDialog client={client} onSuccess={fetchClients} /> }))
  }

  const fetchClients = useCallback(() => {
    setLoading(true)
    loadingPromise.current = makeCancelable<Page<Client>>(ClientsRepository.list(apiParams))
    loadingPromise.current?.promise.then((page) => {
      setClientsPage(page)
      setLoading(false)
    })
  }, [apiParams])

  useEffect(() => {
    fetchClients()

    return () => {
      loadingPromise?.current?.cancel()
    }
  }, [apiParams, fetchClients])

  const tableContent = !Boolean(clientsPage.items.length) ? (
    <TableEmptyState />
  ) : (
    clientsPage.items.map((client) => {
      return <UserListRow key={client?.id} client={client} onActivate={activateClient} matchesSm={matchesSm} />
    })
  )

  return (
    <div className={classes.container}>
      <Paper className={classes.tableWrapper}>
        <TableContainer className={classes.table}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('clientsList.table.id')}</StyledTableCell>
                <StyledTableCell>{t('clientsList.table.name')}</StyledTableCell>
                <StyledTableCell>{t('clientsList.table.email')}</StyledTableCell>
                {!matchesSm && (
                  <>
                    <StyledTableCell>{t('clientsList.table.ethPublicAddress')}</StyledTableCell>
                    <StyledTableCell>{t('clientsList.table.isBusiness')}</StyledTableCell>
                    <StyledTableCell>{t('clientsList.table.businessName')}</StyledTableCell>
                    <StyledTableCell>{t('clientsList.table.activateTitle')}</StyledTableCell>
                  </>
                )}
                <StyledTableCell>{t('clientsList.table.validated')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{loading ? <TableLoader /> : tableContent}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.tablePagination}
          component="div"
          rowsPerPageOptions={[RowsPerPage.A50, RowsPerPage.A100]}
          count={clientsPage.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true
          }}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowPerPageChange}
        />
      </Paper>
    </div>
  )
}
