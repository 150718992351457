import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../common/view/store/store'
import { TransactionType } from '../../domain/models/TransactionState'

function selectMetamaskBase(state: RootState) {
  return state.eth.metamask
}

function selectTransactionsBase(state: RootState) {
  return state.eth.transactions
}

export const getMetamaskConnectionStatus = createSelector([selectMetamaskBase], (metamask) => metamask.connectionStatus)

export const getPendingTransactionsByType = (type: TransactionType) =>
  createSelector([selectTransactionsBase], (transactions) => transactions.filter((tx) => tx.status === 'pending' && tx.type === type))

export const getLoadingTransactionsByType = (type: TransactionType) =>
  createSelector([selectTransactionsBase], (transactions) => transactions.filter((tx) => tx.status === 'loading' && tx.type === type))

export const getCompletedTransactionsByType = (type: TransactionType) =>
  createSelector([selectTransactionsBase], (transactions) => transactions.filter((tx) => tx.status === 'completed' && tx.type === type))
