import { ApiToken } from './ApiToken'
import { Token } from '@uniswap/sdk-core'
import { UniswapFetcherService } from '../../data/UniswapFetcherService'

class SwappableTokens {
  private apiTokenList: Array<ApiToken> = []
  private tokensMap: Map<string, Token> = new Map()

  setList(tokens: Array<ApiToken>) {
    this.apiTokenList = tokens
  }

  async fetchTokenObjects() {
    const tokens = await Promise.all(this.apiTokenList.map((apiToken) => UniswapFetcherService.fetchTokenData(apiToken.address, apiToken.name)))
    tokens.forEach((token) => {
      this.tokensMap.set(token.address.toLowerCase(), token)
    })
  }

  get USDC() {
    return this.apiTokenList.find((token) => token.name === 'USDC')?.address ?? null
  }

  get tokens() {
    return this.apiTokenList;
  }

  getTokenByAddress(tokenAddress: string): Token | undefined {
    return this.tokensMap.get(tokenAddress.toLowerCase())
  }
}

export default new SwappableTokens()
