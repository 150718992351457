import EthereumClient from '../../../data/EthereumClient'
import { PauseTokenUseCase } from './PauseTokenUseCase'
import { Asset } from '../../../../assets/domain/models/Asset'
import { AssetsRepository } from '../../../../assets/data/AssetsRepository'
import { providers } from 'ethers'

export class PauseTokenInteractor {
  static async execute(asset: Asset): Promise<providers.TransactionResponse | string | undefined> {

    if (!EthereumClient.web3ClientAddressChanged) {
      return AssetsRepository.pause({ id: asset.id})
    } else {
      return PauseTokenUseCase.execute(asset)
    }
  }
}
