import { makeStyles, Theme } from '@material-ui/core/styles'
import { Props } from './AlertDialog'

const useStyles = makeStyles<Theme, Props>((theme) => {
  return {
    root: {
      backgroundColor: ({ variant }) => (variant ? theme.palette[variant].main : undefined),
      color: ({ variant }) => (variant ? theme.palette.getContrastText(theme.palette[variant].main) : undefined)
    }
  }
})

export default useStyles
