import { createStyles, darken, makeStyles } from '@material-ui/core/styles'

import { Theme } from '@material-ui/core/styles'
import { Badge, withStyles, TableCell } from '@material-ui/core'

export const StyledBadge = withStyles((theme: Theme) => {
  const bgColor = darken(theme.palette.primary.dark, 0.5)
  return createStyles({
    badge: {
      backgroundColor: bgColor,
      color: theme.palette.getContrastText(bgColor)
    }
  })
})(Badge)

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    top: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    actions: {
      width: 250,
      marginRight: 30,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginRight: 0,
      }
    },
    bottom: {
      width: '100%',
      paddingRight: 20,
      paddingLeft: 280,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        paddingLeft: 0
      }
    },
    addressContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingRight: theme.spacing(2),
      marginBottom: 20,
    },
    tokenNumbers: {
      minWidth: 200,
    },
    assetWrapper: {
      display: 'flex',
      alignItem: 'center',
      height: 50,
    },
    tokenSymbol: {
      color: theme.palette.common.white
    },
    avatar: {
      width: 55,
      height: 55,
      borderRadius: theme.spacing(4),
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.main,
      marginRight: theme.spacing(1)
    },
    nameWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginLeft: theme.spacing(1)
    },
    contentHeading: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'space-between',
      marignRight: theme.spacing(1),
      marginBottom: theme.spacing(3)
    },
    headingLeft: {
      display: 'flex',
      alignItems: 'center',
      flex: 1
    },
    addressWrapper: {
      marginLeft: 50
    },
    titleDivider: {
      margin: theme.spacing(2, 0, 2, 0)
    },
    paperData: {
      padding: theme.spacing(5)
    },
    overviewData: {
      '& > div': {
        minHeight: 200
      }
    },
    extraData: {
      '& > div': {
        minHeight: 230
      }
    },
    mainDataDivider: {
      margin: theme.spacing(2, 0)
    },
    tokenAddressRoot: {
      userSelect: 'none'
    },
    descriptionSubtitle: {
      color: 'black',
      '&::hover': {
        opacity: 0
      },
    },
    copyTokenAddressTooltip: {
      fontSize: 13
    },
    assetButtonsWrapper: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(3),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }
    },
    nameAndLogoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    tokenInfo: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
    },
    tokenInfoRight: {
      width: '600px',
      marginBottom: '40px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    logo: {
      backgroundColor: theme.palette.common.white,
      height: 50,
      width: 50,
    },
    nameContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(2)
    },
    textMarginTop: {
      marginTop: theme.spacing(2),
    },
    table: {
      height: '65vh',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        height: '73vh'
      },
      flexGrow: 1
    },
    filesTitle: {
      marginLeft: '3%'
    },
    accordionItem: {
      marginTop: 20,
      backgroundColor: '#c7edef54',
      borderRadius: 10,
      padding: 20,
    },
    accordionButton: {
      cursor: 'pointer',
      width: '100%',
      textAlign: 'left',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      '&::before': {
          display: 'inline-block',
          content: '""',
          height: '10px',
          width: '10px',
          marginRight: '12px',
          borderBottom: '2px solid currentColor',
          borderRight: '2px solid currentColor',
          transform: 'rotate(-45deg)',
          transition: '0.4s'
      },
      '&[aria-expanded="true"]': {
        '&::before': {
          transform: 'rotate(45deg)'
        }
      },
      '&[aria-selected="true"]': {
        '&::before': {
          transform: 'rotate(45deg)'
        }
      },
    },
    accordionContent: {
      cursor: 'pointer',
      padding: '0px 25px',
      width: '100%',
      textAlign: 'left',
      marginTop: theme.spacing(2),
    }
  }
})

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white
    },
    body: {
      fontSize: 14,
      padding: 20
    }
  })
)(TableCell)

export default useStyles
