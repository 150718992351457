import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => {
  return {
    selectorWrapper: {
      display: 'flex'
    }
  }
})

export default useStyles
