import EthereumClient from '../../../eth/data/EthereumClient'
import { formatEther } from 'ethers/lib/utils'
import { FixedNumber } from 'ethers'
import { CoinBalance } from '../models/CoinBalance'
import { ContractRepository } from '../../../eth/data/ContractRepository'
import SwappableTokens from '../../../eth/domain/models/SwappableTokens'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'

export class GetCoinBalanceUseCase {
  static async execute(clientAddress: string): Promise<CoinBalance> {
    const provider = await EthereumClient.currentProvider()
    if (!clientAddress) {
      return {
        eth: FixedNumber.fromString('0').round(4),
        usdc: FixedNumber.fromString('0').round(4)
      }
    }

    const ethBalance = await provider.getBalance(clientAddress ?? '')

    const USDC = SwappableTokens.USDC
    const usdcContractRepo = new ContractRepository(USDC!)
    let usdcBalance: CurrencyAmount<Token> | null = null
    try {
      usdcBalance = await usdcContractRepo.balance(clientAddress ?? '')
    } catch (e) {
      console.error(e)
    }

    return {
      eth: FixedNumber.fromString(formatEther(ethBalance)).round(4),
      usdc: FixedNumber.fromString(usdcBalance?.toSignificant(4) ?? '0').round(4)
    }
  }
}
