import { AppRoute, RouteGuard } from '../../../../common/view/router/config'
import type * as H from 'history'
import { RedirectConfig } from '../../../../common/view/router/RedirectConfig'
import store from '../../../../common/view/store/store'

export interface LoginLocationState {
  noCheck: boolean
}

export class IsSuperAdminGuard implements RouteGuard {
  async execute(
    route: AppRoute,
    location: H.Location<LoginLocationState>,
    query: URLSearchParams
  ): Promise<boolean | string | RedirectConfig<LoginLocationState>> {
    const profile = store.getState().user.profile
    return profile?.isSuperUser ?? false
  }
}
