import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import useStyles from './ValidateDialog.style'

import { closeDialog } from '../../../../common/view/store/ui/ui.slice'

interface Props {
  url: string | null,
  onFinish: () => void,
  closable?: boolean
}

export const ValidateDialog = ({ url, onFinish, closable = true }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    return () => onFinish()
  }, [onFinish])

  useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      if (event?.data === 'close') {
        dispatch(closeDialog())
      }
    }
    window.addEventListener( "message", messageListener)

    return () => window.removeEventListener( "message", messageListener)
  }, [dispatch])

  if (!url) {
    dispatch(closeDialog())
    return null;
  }

  return (
    <Grid container justify="flex-start" alignItems="flex-start" className={closable ? classes.containerPadding : ''} >
      {closable &&
        <div className={classes.topBar}>
          <IconButton className={classes.closeBtn} onClick={() => dispatch(closeDialog())}>
            <Close />
          </IconButton>
        </div>
      }
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        className={classes.iframe}
        allow="camera"
        src={url}
        height="760px"
        width="100%"
        id="validationFrame"
      />
    </Grid>
  )
}
