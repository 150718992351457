import { MetamaskService } from '../../data/MetamaskService'
import { MetamaskConnectionStatus } from '../models/MetamaskConnectionStatus'

export class MetamaskInitUseCase {
  static async execute(): Promise<MetamaskConnectionStatus> {
    const metamaskConnected = MetamaskService.checkConnection()
    if (typeof metamaskConnected === 'undefined') return MetamaskConnectionStatus.NO_WALLET_PROVIDER
    if (!metamaskConnected) {
      return MetamaskConnectionStatus.NOT_CONNECTED
    } else {
      await MetamaskService.connect()
      return MetamaskConnectionStatus.CONNECTED
    }
  }
}
