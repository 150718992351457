import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { Typography, Tooltip, IconButton } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'

import { timeoutPromise } from '../../../../common/utils/promise'
import { copyTextToClipboard } from '../../../../common/utils/functions'

import useStyles from './CryptoAddress.styles'
import ViewEtherscanButton from "../../../../eth/view/components/view-etherscan-button/ViewEtherscanButton";

interface Props {
  address: string
}

const CryptoAddress = ({ address }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [openCopyTooltip, setOpenCopyTooltip] = useState(false)

  const handleCopyWalletAddress = async () => {
    await copyTextToClipboard(address)
    setOpenCopyTooltip(true)
    await timeoutPromise(3000)
    setOpenCopyTooltip(false)
  }
  const displayAddress = !address ? '' : `${address.slice(0, 10)}...${address.slice(-5)}`

  return (
    <div>
      <div className={classes.container}>
        <Typography classes={{ root: classes.address }}>
          {displayAddress}
        </Typography>
        <Tooltip
          open={openCopyTooltip}
          title={`${t('balance.walletAddressCopied')}`}
          placement="top"
          classes={{ tooltip: classes.copy }}>
          <IconButton onClick={handleCopyWalletAddress} color="primary">
            <FileCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.container}>
        <Typography classes={{ root: classes.address }}>
          {`${t('balance.transactions')}`}
        </Typography>
        <ViewEtherscanButton tokenAddress={address} />
      </div>
    </div>
  )
}

export default CryptoAddress;
