import React from 'react'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useStyles from './AlertDialog.styles'
import { Button, DialogActions } from '@material-ui/core'
import { useAppDispatch } from '../../store/store'
import { closeDialog } from '../../store/ui/ui.slice'
import { useTranslation } from 'react-i18next'
import { AglaiaDialogContent } from '../app-dialog/AppDialog.styled'

export interface Props {
  title: string | React.ReactNode
  description: string | React.ReactNode
  variant?: 'error' | 'warning' | 'info' | 'success'
  action?: React.ReactNode
  noClose?: boolean
}

const AlertDialog = (props: Props) => {
  const { title, description, action, noClose } = props
  const classes = useStyles(props)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const CustomAction = action as React.ComponentType
  return (
    <>
      <DialogTitle className={classes.root}>{title}</DialogTitle>
      <AglaiaDialogContent dividers>
        <DialogContentText className={classes.description}>{description}</DialogContentText>
      </AglaiaDialogContent>
      {(!noClose || action) && (
        <DialogActions>
          {!noClose && (
            <Button onClick={() => dispatch(closeDialog())} color="primary">
              {t('alert.closeBtn')}
            </Button>
          )}
          {action && <CustomAction />}
        </DialogActions>
      )}
    </>
  )
}

export default AlertDialog
