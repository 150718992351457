import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { UserState } from './user.types'
import { AuthRepository } from '../../../auth/data/AuthRepository'
import { LoginOutputModel } from '../../../auth/data/models/LoginOutputModel'
import { User } from '../../domain/models/User'
import { closeSnackbar, setLoader } from '../../../common/view/store/ui/ui.slice'
import { ClientUpdateOutputModel, ClientUpdateOutputModelData } from '../../../clients/data/models/ClientUpdateOutputModel'
import { ClientsRepository } from '../../../clients/data/ClientsRepository'
import { AppDispatch, RootState } from '../../../common/view/store/store'
import { classToPlain, plainToClass } from 'class-transformer'
import { ClientUpdate } from '../../../clients/domain/ClientUpdate'
import { InitAppUseCase } from '../../../common/domain/use-cases/InitAppUseCase'

export const INITIAL_STATE: UserState = {
  profile: null
}

export const loginThunk = createAsyncThunk('userStore/login', async (outputModel: LoginOutputModel, { dispatch }) => {
  dispatch(setLoader(true))
  try {
    await AuthRepository.login(outputModel)
    return await InitAppUseCase.execute()
  } finally {
    dispatch(setLoader(false))
  }
})

export const logoutThunk = createAsyncThunk('userStore/logout', async (arg, { dispatch }) => {
  dispatch(setLoader(true))
  try {
    await AuthRepository.logout()
    dispatch(setUser(null))
  } catch (e) {
    throw e
  } finally {
    dispatch(setLoader(false))
    dispatch(closeSnackbar())
  }
})

export const updateUserClientThunk = createAsyncThunk<
  ClientUpdate | undefined,
  Partial<ClientUpdateOutputModelData>,
  { dispatch: AppDispatch; state: RootState }
>('userStore/updateClient', async ({ user, ethPublicAddress }, { dispatch, getState }) => {
  const profile = getState().user.profile
  dispatch(setLoader(true))
  if (!profile?.client) {
    dispatch(setLoader(false))
    return
  }
  try {
    const _outputModel: ClientUpdateOutputModel = {
      clientId: profile?.client?.id ?? '',
      data: {
        user: {
          firstName: user?.firstName ? user?.firstName : profile?.firstName ?? '',
          lastName: user?.lastName ? user?.lastName : profile?.lastName ?? ''
        },
        ethPublicAddress: ethPublicAddress ? ethPublicAddress : profile?.client?.ethPublicAddress ?? ''
      }
    }
    return await ClientsRepository.update(_outputModel)
  } catch (e) {
    throw e
  } finally {
    dispatch(setLoader(false))
  }
})

export const userStore = createSlice({
  name: 'userStore',
  initialState: INITIAL_STATE,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.profile = action.payload
    },
    setAddressChanged: (state, action: PayloadAction<boolean>) => {
      if (state.profile) {
        const plain = classToPlain(state.profile)
        plain.addressChanged = action.payload
        state.profile = plainToClass(User, plain)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (state, { payload }) => {
      state.profile = payload
    })
    builder.addCase(logoutThunk.fulfilled, (state, { payload }) => {
      state.profile = null
    })
  }
})

export const { setUser, setAddressChanged } = userStore.actions

export default userStore.reducer
