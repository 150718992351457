import { Asset } from '../../assets/domain/models/Asset'

export const converToThousands = (str: string) => new Intl.NumberFormat('en-US').format(Number(str))

export const CalculateBalances = (asset: Asset | null) => {
  if (!asset?.amountData) {
    return {
      privateBalance: null,
      presaleBalance: null,
      totalBalance: null,
      totalSupply: null,
    }
  }

  const { clientBalance, presaleBalance: preBalance, totalSupply: supply, prices} = asset.amountData;
  const usdcPrice = !prices ? null : prices.find((token) => token.currency === 'USDC');

  const USDCValue = !usdcPrice?.amount ? '---' : converToThousands(usdcPrice.amount.toFixed(2));
  const privateBalance = clientBalance ? converToThousands(clientBalance.toSignificant(6)) : '---'
  const presaleBalance = preBalance ? converToThousands(preBalance.toSignificant(6)) : '---'
  const totalSupply = supply ? converToThousands(supply.toSignificant(6)) : '---'

  let totalBalance;

  if (clientBalance && preBalance) {
    totalBalance = converToThousands(String(Number(clientBalance.toSignificant(6)) + Number(preBalance.toSignificant(6))))
  }

  if (!privateBalance && preBalance) {
    totalBalance = presaleBalance
  }

  if (privateBalance && !preBalance) {
    totalBalance = privateBalance
  }

  return {
    privateBalance,
    presaleBalance,
    totalBalance,
    totalSupply,
    USDCValue
  }
}
