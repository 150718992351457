import React, { createRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { Grid, DialogTitle, DialogContentText, TextField, DialogActions, Button, InputLabel, MenuItem, FormControl, Select, FormHelperText } from '@material-ui/core'

import { closeDialog, openSnackbar, setLoader } from '../../../../common/view/store/ui/ui.slice'
import i18n from '../../../../common/i18n/config'
import yup from '../../../../common/utils/yup.extended'
import { AglaiaDialogContent } from '../../../../common/view/components/app-dialog/AppDialog.styled'

import { ClientsRepository } from '../../../../clients/data/ClientsRepository';
import { BusinessInfoModel } from '../../../../clients/data/models/BusinessInfoModel';

import { getUserProfile } from '../../../../user/view/store/user.selectors';
import { JurisdictionCode } from '../../../data/models/JurisdictionCodeModel';

interface FormValues {
  business_jurisdiction_code: number
  business_registration_number: string
  business_name: string
}

const DEFAULT_INITIAL_VALUES: FormValues = {
  business_jurisdiction_code: -1,
  business_registration_number: '',
  business_name: '',
}

const validationSchema = yup.object().shape({
  business_jurisdiction_code: yup.string().required(i18n.t('formErrors.required')),
  business_registration_number: yup.string().required(i18n.t('formErrors.required')),
  business_name: yup.string().required(i18n.t('formErrors.required'))
})

interface Props {
  onFinish: () => void,
}

export const BusinessInfoDialog = ({ onFinish }: Props) => {
  // Deps
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef = createRef<FormikProps<FormValues>>()

  // state
  const [codes, setCodes] = useState<JurisdictionCode[]>([])

  const savedUser = useSelector(getUserProfile);

   useEffect(() => {
    return () => onFinish()
  }, [onFinish])

  useEffect(() => {
    const fetchJurisdictionCodes = async () => {
      try {
        const codes = await ClientsRepository.getJurisdictionCodes();
        setCodes(codes)
      } catch (e) {
        dispatch(openSnackbar({ message: e.response.message ?? t('defaultErrorMsg'), severity: 'error' }))
      }
    }

    fetchJurisdictionCodes();
  }, [dispatch, t])

  const handleCancel = () => {
    dispatch(closeDialog())
  }

  const handleSubmit = async (values: FormValues) => {
    const { business_jurisdiction_code, business_registration_number, business_name } = values
    if (!savedUser || business_jurisdiction_code === -1) {
      return;
    }

    dispatch(setLoader(true))
    try {
      const businessData: BusinessInfoModel = {
        business_jurisdiction_code,
        business_registration_number,
        business_name
      }
      const { lastName, firstName, ethPublicAddress, client } = savedUser || {}
      const bodyData = {
        firstName,
        lastName,
        ethPublicAddress,
        ...businessData
      }
      if ( !client?.id) {
        dispatch(setLoader(false))
        return;
      }
      await ClientsRepository.updateBusinessInfo({ clientId: client.id, bodyData })
      dispatch(openSnackbar({ message: t('formLabels.businessInfo.successfulUpdate') }))
      handleCancel()
      dispatch(setLoader(false))
    } catch (e) {
      dispatch(setLoader(false))
      dispatch(openSnackbar({ message: e.response.message ?? t('defaultErrorMsg'), severity: 'error' }))
    }
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">{t('formLabels.businessInfo.title')}</DialogTitle>
      <AglaiaDialogContent>
        <Grid container direction="column" wrap="nowrap" justify="flex-start" alignItems="flex-start">
          <DialogContentText>{t('formLabels.businessInfo.description')}</DialogContentText>
          <Formik
            innerRef={formRef}
            initialValues={DEFAULT_INITIAL_VALUES}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}
            enableReinitialize>
            {({ handleChange, errors, touched, setFieldValue, values }: FormikProps<FormValues>) => {
              const { business_jurisdiction_code, business_registration_number, business_name } = values
              const { business_name: touchName, business_registration_number: touchNumber } = touched
              const { business_name: errorName, business_registration_number: errorNumber } = errors
              const errorSelect = business_jurisdiction_code === -1 && (touchName || touchNumber);
              return (
                <Form noValidate>
                  <FormControl variant="outlined" fullWidth error={errorSelect}>
                    <InputLabel id="business_jurisdiction_code_label">{`${t('formLabels.businessInfo.jurisdictionCode')} *`}</InputLabel>
                    <Select
                      labelId="business_jurisdiction_code_label"
                      id="business_jurisdiction_code"
                      name="business_jurisdiction_code"
                      value={business_jurisdiction_code}
                      label={`${t('formLabels.businessInfo.jurisdictionCode')} *`}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value={-1}>
                        <em>None</em>
                      </MenuItem>
                      {codes?.length && codes.map((code: JurisdictionCode) => {
                        return <MenuItem value={code.id}>{code.name}</MenuItem>
                      })}
                    </Select>
                    {errorSelect && <FormHelperText>Required</FormHelperText>}
                  </FormControl>
                  <TextField
                    margin="normal"
                    id="business_registration_number"
                    required
                    label={t('formLabels.businessInfo.businessNumber')}
                    multiline
                    rows={1}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={business_registration_number}
                    error={Boolean(touchNumber) && Boolean(errorNumber)}
                    helperText={Boolean(touchNumber) && Boolean(errorNumber) && errorNumber}
                  />
                  <TextField
                    margin="normal"
                    id="business_name"
                    required
                    label={t('formLabels.businessInfo.businessName')}
                    multiline
                    rows={1}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={business_name}
                    error={Boolean(touchName) && Boolean(errorName)}
                    helperText={Boolean(touchName) && Boolean(errorName) && errorName}
                  />
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </AglaiaDialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          {t('assets.edition.cancelBtn')}
        </Button>
        <Button color="primary" onClick={() => formRef.current?.submitForm()}>
          {t('assets.edition.submitBtn')}
        </Button>
      </DialogActions>
    </>
  )
}
