import { CssBaseline, Container} from '@material-ui/core'
import { Redirect, Switch } from 'react-router-dom'

import useStyles from './AppDashboard.styles'


import AppHeaderNav from '../app-header-nav/AppHeaderNav'
import Footer from "../footer/Footer";

import { CustomRouteChildrenProps, RouteWithSubRoutes } from '../../router/config'

import backgroundImg from '../../../../common/assets/background/aglaia_bg.png'


interface Props extends CustomRouteChildrenProps {}

export default function AppDashboard({ routes }: Props) {
  // Deps
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.header}>
        <AppHeaderNav />
      </div>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Redirect exact from="/u" to="/u/assets" />
            {routes?.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </Container>
        <img alt={''} title={'blockchain'} src={backgroundImg} className={classes.background}/>
        <Footer />
      </main>
    </div>
  )
}
