import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { getDialogState } from '../../store/ui/ui.selectors'
import React from 'react'
import { closeDialog } from '../../store/ui/ui.slice'
import { useMediaQuery, useTheme } from '@material-ui/core'

export default function AppDialog() {
  const dispatch = useDispatch()
  const { open, content, maxWidth, fullWidth, blocking = false } = useSelector(getDialogState)
  const DialogContent = content as React.ComponentType
  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'))

  const handleCancel = () => {
    dispatch(closeDialog())
  }
  return (
    <Dialog
      open={open}
      fullScreen={matchesXs}
      onClose={handleCancel}
      fullWidth={fullWidth}
      scroll={matchesXs ? 'paper' : undefined}
      maxWidth={maxWidth}
      disableBackdropClick={blocking}
      disableEscapeKeyDown={blocking}>
      {DialogContent && <DialogContent />}
    </Dialog>
  )
}
