import red from '@material-ui/core/colors/red'
import { createMuiTheme } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'

// A custom theme for this app
const createTheme = (prefersDarkMode: boolean) => {
  // const rgbPreference = prefersDarkMode ? '255,255,255' : '0,0,0'
  return createMuiTheme({
    palette: {
      type: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: prefersDarkMode ? blue[500] : '#85D0D3',
        light: '#C5EDF0',
        dark: '#B9EFF3',
        contrastText: 'fff'
      },
      secondary: {
        main: '#000'
      },
      grey: {},
      error: {
        main: red.A400
      },
      background: {
        default: prefersDarkMode ? '#222' : '#E5E5E5'
      }
    },
    typography: {
      fontFamily: ['"Montserrat"', 'Open Sans'].join(',')
    },
    overrides: {
      MuiInputBase: {
        // input: {
        //   '&:-webkit-autofill': {
        //     boxShadow: `0 0 0 100px rgba(${rgbPreference},0.1) inset !important`,
        //     transitionDelay: '9999s',
        //     transitionProperty: 'background-color, color',
        //     caretColor: prefersDarkMode ? `${grey[100]} !important` : '#222 !important',
        //     '-webkit-text-fill-color': prefersDarkMode ? `${grey[100]} !important` : '#222 !important'
        //   }
        // }
      },
      MuiCssBaseline: {
        '@global': {}
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1400,
        xl: 1920
      }
    }
  })
}

export default createTheme
