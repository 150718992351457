import { UserRepository } from '../UserRepository'
import { MetamaskService } from '../../../eth/data/MetamaskService'
import {EnvVars} from "../../../common/config/EnvVars";

export class GetUserDataUseCase {
  static async execute() {
    const user = await UserRepository.get()
    const addressChanged = user.client?.addressChanged
    const ethPublicAddress = user.isSuperUser? EnvVars.adminEthAddress : user?.client?.ethPublicAddress

    user.ethPublicAddress = ethPublicAddress || ''
    user.addressChanged = addressChanged || false
    MetamaskService.setAddressInClient(ethPublicAddress, addressChanged)
    return user
  }
}
