import { AssetsRepository } from '../../../../assets/data/AssetsRepository'
import { UniswapFetcherService } from '../../../data/UniswapFetcherService'
import { providers, utils } from 'ethers'
import {TransferTokenModel} from "../../../../assets/data/models/TransferTokenModel";
import {parseEther} from "ethers/lib/utils";

export class TransferTokenInteractor {
  static async execute(body: TransferTokenModel): Promise<providers.TransactionResponse | string | undefined> {

    if(body.token_address === 'eth') {
     body.amount_to_transfer = parseEther(body.amount_to_transfer).toString()
     return AssetsRepository.transferETH(body)
    }
    else {
      const token = await UniswapFetcherService.fetchTokenData(body.token_address)
      body.amount_to_transfer = utils.parseUnits(body.amount_to_transfer, token.decimals).toString()
      return AssetsRepository.transferToken(body)
    }
  }
}
