import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return {
    titleDivider: {
      margin: theme.spacing(2, 0, 2, 0)
    },
    listLoader: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2)
    }
  }
})

export default useStyles
