import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppBar, Toolbar, Typography, Link, Button, IconButton, Badge, Avatar, Menu, MenuItem, useMediaQuery } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

import useStyles from './AppHeaderNav.styles'

import { useAppDispatch } from '../../store/store'
import { logoutThunk } from '../../../../user/view/store/user.slice'
import {
  getUserSuperUserStatus,
  getUserFirstName,
  isBusinessInfoComplete,
  isBusinessDocsComplete,
  isBusinessInfoValidated,
  isValidated,
  getUserProfile,
  hasSignedDocs, getUserClientId
} from '../../../../user/view/store/user.selectors'
import {openDialog, setLoader} from '../../store/ui/ui.slice'
import { BusinessInfoDialog } from '../../../../clients/view/components/business-info-dialog/BusinessInfoDialog';
import { ValidateDialog } from '../../../../user/view/components/validate-dialog/ValidateDialog';
import { setUser } from '../../../../user/view/store/user.slice'
import { InitAppUseCase } from '../../../../common/domain/use-cases/InitAppUseCase'

import { useOnceMetamask } from '../../../../eth/view/hooks/Metamask.hook'
import MetamaskConnectBtn from '../../../../eth/view/components/metamask-conect-btn/MetamaskConnectBtn'

import AglaiaLogo from '../../../assets/logos/LOGO_ACTIONMONKEY.png'
import {EnvVars} from "../../../config/EnvVars";
import {ClientsRepository} from "../../../../clients/data/ClientsRepository";

const ASSET_PATH = '/u/assets'
const BALANCE_PATH = '/u/balance'
const CLIENT_PATH = '/u/clients'
const OWNER_PATH = '/u/owners'

export const AppHeaderSimpleNav = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const router = useHistory()

  const onClickHeaderName = () => {
    router.push('/login')
  }

  return (
    <AppBar>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarWrapper}>
          <div>
            <Button onClick={onClickHeaderName}>
              <img className={classes.logo} src={AglaiaLogo} alt="Aglaia Capital DeFi Platform" title="Aglaia Capital DeFi Platform"/>
              <Typography component="h2" variant="h6" color="inherit" noWrap className={classes.title}>
                {t('headerNav.title')}
              </Typography>
            </Button>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}

const AppHeaderNav = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const router = useHistory()
  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  // @TODO - Adapt for profile picture
  const user = useSelector(getUserProfile)
  const userName = useSelector(getUserFirstName)
  const clientId = useSelector(getUserClientId)
  const isSuperUser = useSelector(getUserSuperUserStatus)
  const { metamaskConnected } = useOnceMetamask()
  const validState = useSelector(isValidated)
  const docsSigned = useSelector(hasSignedDocs)
  const isProfileComplete = useSelector(isBusinessInfoComplete)
  const isBusinessInfoValidatedOk = useSelector(isBusinessInfoValidated)
  const isBusinessDocsOk = useSelector(isBusinessDocsComplete)

  const [ avatarAnchor, setAvatarAnchor ] = useState<null | HTMLElement>(null)
  const [ currentRoutePath, setCurrentRoutePath ] = useState(router.location.pathname)

  const [ kycFirstOpen, setKycFirstOpen ] = useState(true);
  const [ docsFirstOpen, setDocsFirstOpen ] = useState(true);
  const [ businessInfoFirstOpen, setBusinessInfoFirstOpen ] = useState(true);

  const [ showBanner, setShowBanner ] = useState(false);
  const isValidUser = validState && validState.is_validated;

  const showBusinessInfoDialog = useCallback(() => {
    const onFinish = async () => {
      setShowBanner(true)
      const profile = await InitAppUseCase.execute()
      dispatch(setUser(profile))
    }

    dispatch(
      openDialog({
        content: () => <BusinessInfoDialog onFinish={onFinish} />,
        maxWidth: 'sm',
        fullWidth: true
      })
    )
  }, [dispatch])

  const showBusinessDocsDialog = useCallback(() => {
    const onFinish = async () => {
      const profile = await InitAppUseCase.execute()
      dispatch(setUser(profile))
      setShowBanner(true)
    }

    dispatch(
      openDialog({
        content: () => <ValidateDialog url={isBusinessDocsOk.docs_verification_url} onFinish={onFinish} />,
        maxWidth: 'sm',
        fullWidth: true
      })
    )
  }, [dispatch, isBusinessDocsOk.docs_verification_url])

  const showRegisterDocs = useCallback(async () => {

      const onFinish = async () => {
        const profile = await InitAppUseCase.execute()
        dispatch(setUser(profile))
        setShowBanner(true)
      }
      dispatch(setLoader(true))
      const url = await ClientsRepository.getRegisterSignUrl(clientId || '')
      dispatch(setLoader(false))

      dispatch(
        openDialog({
          content: () => <ValidateDialog closable={false} url={url} onFinish={onFinish} />,
          maxWidth: 'xl',
          fullWidth: true,
          blocking: true
        })
      )
    },
    [dispatch, clientId],
  )

  const showKYCDialog = useCallback(() => {
      const onFinish = async () => {
        const profile = await InitAppUseCase.execute()
        dispatch(setUser(profile))
        setShowBanner(true)
      }
      dispatch(
        openDialog({
          content: () => <ValidateDialog url={validState.verification_url} onFinish={onFinish} />,
          maxWidth: 'sm',
          fullWidth: true
        })
      )
    },
    [dispatch, validState.verification_url],
  )

  useEffect(() => {
    const checkValidationStep = () => {
      if (!docsSigned) {
        showRegisterDocs()
        return
      }
      if(!isValidUser) {
        if (!kycFirstOpen) return
        setKycFirstOpen(false)
        setTimeout(() => {showKYCDialog()}, 1500)
        return
      }
      if (!isProfileComplete) {
        if (!businessInfoFirstOpen) return
        setBusinessInfoFirstOpen(false)
        setTimeout(() => {showBusinessInfoDialog()}, 1000)
        return
      }
      if (!isBusinessInfoValidatedOk) {
        if (!docsFirstOpen) return
        setDocsFirstOpen(false)
        setTimeout(() => {showBusinessDocsDialog()}, 1000)
        return
      }
    }
    if (user) {
      checkValidationStep();
    }
  }, [
    docsSigned,
    isProfileComplete,
    isBusinessInfoValidatedOk,
    isValidUser,
    showKYCDialog,
    showBusinessInfoDialog,
    showBusinessDocsDialog,
    showRegisterDocs,
    businessInfoFirstOpen,
    docsFirstOpen,
    kycFirstOpen,
    user
  ]);

  useEffect(() => {
    router.listen((location) => {
      setCurrentRoutePath(location.pathname)
    })
  }, [router])

  const handleLogout = async () => {
    handleAvatarMenuClose()
    const res = await dispatch(logoutThunk())
    try {
      unwrapResult(res)
      router.push('/login', { noCheck: true })
    } catch (e) {
      console.error(e)
    }
  }

  const handleAvatarMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarAnchor(event.currentTarget)
  }
  const handleAvatarMenuClose = () => {
    setAvatarAnchor(null)
  }

  const isSelectedCss = useCallback(
    (path) => {
      return currentRoutePath.includes(path) ? classes.selectedLink : classes.link
    },
    [currentRoutePath, classes.selectedLink, classes.link]
  )

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickNavButton = (path: string) => {
    router.push(path)
  }

  const onClickBurgerNav = useCallback(
    (path: string) => {
      handleAvatarMenuClose()
      onClickNavButton(path)
    },
    [onClickNavButton]
  )

  const renderSuperUserNavButton = () => {
    const navPath = !isSuperUser ? BALANCE_PATH : CLIENT_PATH
    const btnText = !isSuperUser ? 'headerNav.position' : 'headerNav.users'
    return (
      <>
        <Button component={Link} onClick={() => onClickBurgerNav(ASSET_PATH)} className={isSelectedCss(ASSET_PATH)}>
          {t('headerNav.assets')}
        </Button>
        <Button component={Link} onClick={() => onClickBurgerNav(navPath)} className={isSelectedCss(navPath)}>
          {t(btnText)}
        </Button>
      </>
    )
  }

  const renderNavigationButtons = () => {
    const showBadge = EnvVars.metamaskAllowed && metamaskConnected
    if (matchesSm) {
      return (
        <div>
          <IconButton color="inherit" onClick={handleAvatarMenuOpen}>
            <Badge color="error" overlap="circle" badgeContent=" " invisible={!showBadge}>
              <Avatar alt="Profile-Pic" src="" className={classes.avatar}>
                {userName[0]}
              </Avatar>
            </Badge>
          </IconButton>
        </div>
      )
    }

    return (
      <div>
        {renderSuperUserNavButton()}
        {isSuperUser && (
          <Button component={Link} onClick={() => onClickBurgerNav(BALANCE_PATH)} className={isSelectedCss(BALANCE_PATH)}>
            {t('headerNav.position')}
          </Button>
        )}
        <IconButton color="inherit" onClick={handleAvatarMenuOpen}>
          <Badge color="error" overlap="circle" badgeContent=" " invisible={!showBadge}>
            <Avatar alt="Profile-Pic" src="" className={classes.avatar}>
              {userName[0]}
            </Avatar>
          </Badge>
        </IconButton>
      </div>
    )
  }

  const renderSmallScreenAdditionalBurguerOptions = () => {
    if (!matchesSm) {
      return null
    }

    let menuOptions = [
      <MenuItem key={'assets-burger'} onClick={() => onClickBurgerNav(ASSET_PATH)} selected={currentRoutePath.includes(ASSET_PATH)}>
        {t('headerNav.assets')}
      </MenuItem>,
      <MenuItem key={'balance-burger'} onClick={() => onClickBurgerNav(BALANCE_PATH)} selected={currentRoutePath.includes(BALANCE_PATH)}>
        {t('headerNav.position')}
      </MenuItem>
    ]

    if (isSuperUser) {
      menuOptions.push(
        <MenuItem key={'users-burger'} onClick={() => onClickBurgerNav(OWNER_PATH)} selected={currentRoutePath.includes(OWNER_PATH)}>
          {t('headerNav.users')}
        </MenuItem>
      )
    }
    return menuOptions
  }

  const renderValidationWarningBanner = () => {
    if (isSuperUser) {
      return null;
    }
    if (!showBanner || !docsSigned || (isValidUser && isBusinessInfoValidatedOk && isBusinessDocsOk.business_docs_is_validated)) {
      return null;
    }

    const warningText = !isValidUser ? 'appDashboard.validationUser' : 'appDashboard.validationBusiness';
    const buttonText = !isValidUser ? 'appDashboard.validate' : 'appDashboard.update';

    const onClick = () => {
     if (!isValidUser) showKYCDialog()
     else if (!isBusinessInfoValidatedOk) showBusinessInfoDialog();
     else if (!isBusinessDocsOk.business_docs_is_validated) showBusinessDocsDialog();
    }

    return (
      <div className={classes.warningBanner}>
        <Typography variant="subtitle1" classes={{ root: classes.warningBannerText }}>
          <b>{t(warningText)}</b>
        </Typography>
        <Button className={classes.validateBtn} variant="outlined" color="secondary" onClick={onClick}>
          {t(buttonText)}
        </Button>
      </div>
    )
  }

  const onClickHeaderName = () => {
    if (user) {
      router.push('/u')
      return
    }
    router.push('/login')
  }

  return (
    <AppBar>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarWrapper}>
          <div>
            <Button onClick={onClickHeaderName} style={{textTransform: 'none'}}>
              <img className={classes.logo} src={AglaiaLogo} alt="Aglaia Capital DeFi Platform" title="Aglaia Capital DeFi Platform"/>
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                {t('headerNav.title')}
              </Typography>
            </Button>
          </div>
          {renderNavigationButtons()}
        </div>
      </Toolbar>
      <Menu
        id="simple-menu"
        anchorEl={avatarAnchor}
        keepMounted
        open={Boolean(avatarAnchor)}
        onClose={handleAvatarMenuClose}
        transformOrigin={{
          vertical: -48,
          horizontal: 0
        }}>
        {renderSmallScreenAdditionalBurguerOptions()}
        <MenuItem onClick={handleLogout}>{t('headerNav.userActions.logout')}</MenuItem>
        {EnvVars.metamaskAllowed &&
          <MenuItem>
            <MetamaskConnectBtn color="primary" />
          </MenuItem>
        }
      </Menu>
      {renderValidationWarningBanner()}
    </AppBar>
  )
}

export default AppHeaderNav
