import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import useStyles from './ResetPasswordPage.styles'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Formik, FormikProps } from 'formik'
import yup from '../../../../common/utils/yup.extended'
import { openSnackbar } from '../../../../common/view/store/ui/ui.slice'
import { useAppDispatch } from '../../../../common/view/store/store'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../common/i18n/config'
import { PasswordTextField } from '../../../../common/view/components/PasswordTextField'
import { PASSWORD_REGEX } from '../../../../common/utils/regexs'
import { UserRepository } from '../../../data/UserRepository'
import ThemeSelector from '../../../../common/view/components/theme-selector/ThemeSelector'

interface FormValues {
  password: string
  repeatPassword: string
}

const formInitialValues: FormValues = {
  password: '',
  repeatPassword: ''
}

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required(i18n.t('formErrors.required'))
    .test('passwordTest', i18n.t('formErrors.passwordFormat'), (value) => !PASSWORD_REGEX.test(value ?? '')),
  repeatPassword: yup
    .string()
    .required(i18n.t('formErrors.required'))
    .oneOf([yup.ref('password'), null], i18n.t('formErrors.passwordMatch'))
})

export default function ResetPassword() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const router = useHistory()
  const { userId, token } = useParams<{ userId: string; token: string }>()

  const handleSubmit = async (values: FormValues) => {
    await UserRepository.resetPassword({ password: values.password, id: userId, token })
    router.push('/login', { noCheck: true })
    dispatch(openSnackbar({ message: t('resetPassword.successfulReset') }))
  }

  return (
    <Container component="main" maxWidth="xs">
      <ThemeSelector className={classes.themeSelector} />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('resetPassword.title')}
        </Typography>
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmit}>
          {({ errors, touched, handleChange, values }: FormikProps<FormValues>) => {
            return (
              <Form className={classes.form} noValidate>
                <Grid container spacing={4} justify="flex-start" alignItems="flex-start">
                  <Grid item xs={12}>
                    <PasswordTextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label={t('formLabels.resetPassword.password')}
                      onChange={handleChange}
                      value={values.password}
                      error={Boolean(touched.password) && Boolean(errors.password)}
                      helperText={(Boolean(touched.password) && Boolean(errors.password) && errors.password) || t('formHints.resetPassword.password')}
                      autoComplete="current-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordTextField
                      variant="outlined"
                      required
                      fullWidth
                      name="repeatPassword"
                      label={t('formLabels.resetPassword.repeatPassword')}
                      onChange={handleChange}
                      value={values.repeatPassword}
                      error={Boolean(touched.repeatPassword) && Boolean(errors.repeatPassword)}
                      helperText={Boolean(touched.repeatPassword) && Boolean(errors.repeatPassword) && errors.repeatPassword}
                      autoComplete="current-password"
                    />
                  </Grid>
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                  {t('resetPassword.submitBtn')}
                </Button>
              </Form>
            )
          }}
        </Formik>
      </div>
    </Container>
  )
}
