import { AppRoute } from '../../../common/view/router/config'
import ClientsListPage from '../components/clients-list-page/ClientsListPage'
import { IsSuperAdminGuard } from '../../../user/view/router/guards/IsSuperAdminGuard'

export const clientRoutes: Array<AppRoute> = [
  {
    path: '/u/clients',
    component: ClientsListPage,
    guard: new IsSuperAdminGuard()
  }
]
