export const urlToBlob = (url: string, readAs: 'dataUrl' | 'arrayBuffer' = 'dataUrl'): Promise<ProgressEvent<FileReader>> => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()
    request.open('GET', url, true)
    request.responseType = 'blob'
    request.onload = function () {
      const reader = new FileReader()
      if (readAs === 'dataUrl') {
        reader.readAsDataURL(request.response)
      } else if (readAs === 'arrayBuffer') {
        reader.readAsArrayBuffer(request.response)
      }
      reader.onload = function (e) {
        resolve(e)
      }
      reader.onerror = function (e) {
        reject(e)
      }
    }
    request.send()
  })
}

export const extractFileNameAndExtension = (url: string): { filename: string; extension: string } => {
  const urlSections = url.split('/')
  const lastSection = urlSections[urlSections.length - 1]
  const filenameParts = lastSection.split('.')
  const extension = filenameParts[filenameParts.length - 1]
  filenameParts.splice(-1, 1)
  const filename = filenameParts.join('')
  return { filename, extension }
}

export const downloadURI = async (uri: string, name: string) => {
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
