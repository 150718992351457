import React, { createRef, useCallback, useState, useEffect } from 'react'
import {
  Button,
  DialogContentText,
  TextField,
  DialogActions,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  Typography
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import useStyles from './AssetForm.styles';

import { closeDialog, openSnackbar, setLoader } from '../../../../common/view/store/ui/ui.slice'
import i18n from '../../../../common/i18n/config'
import yup from '../../../../common/utils/yup.extended'
import { AssetsRepository } from '../../../data/AssetsRepository'
import { AssetCreateInputModel } from '../../../data/models/AssetCreateInputModel'
import { AssetUpdateInputModel } from '../../../data/models/AssetUpdateInputModel'
import FileUploader from '../../../../common/view/components/file-uploader/FileUploader'
import AsyncAutocomplete, { AsyncAutocompleteOption } from '../../../../common/view/components/async-autocomplete/AsyncAutocomplete'
import { Client } from '../../../../clients/domain/Client'
import { ClientsRepository } from '../../../../clients/data/ClientsRepository'
import { TokenType } from '../../../domain/models/TokenType'
import { tokenTypeOptions } from '../../view-models/TokenTypeOptions'
import { AglaiaDialogContent } from '../../../../common/view/components/app-dialog/AppDialog.styled'
import { Asset } from '../../../domain/models/Asset'
import { extractFileNameAndExtension, urlToBlob } from '../../../../common/utils/url'
import { getUserSuperUserStatus } from '../../../../user/view/store/user.selectors'

interface ClientSearchValue extends AsyncAutocompleteOption {
  extraData: Client
}

interface FormValues {
  name: string
  issuer: string
  profitability: string
  summary: string
  description: string
  tokenName: string
  tokenSymbol: string
  tokenAddress: string
  logo: Array<File>
  files: Array<File>
  owner: ClientSearchValue | null
  tokenType: string
  regulatorServiceAddress: string
  uniswap_fee: number
  descriptionPresale: string
  issuerPresale: string
  profitabilityPresale: string
  isPresale: boolean
  presaleToken: string
  create: boolean
  bankAccount: string
  contract: Array<File>
}

const formInitialValues: FormValues = {
  name: '',
  issuer: '',
  profitability: '',
  summary: '',
  description: '',
  tokenName: '',
  tokenSymbol: '',
  tokenAddress: '',
  logo: [],
  files: [],
  owner: null,
  tokenType: TokenType.ERC_20_TOKEN,
  regulatorServiceAddress: '',
  uniswap_fee: 500,
  descriptionPresale: '',
  issuerPresale: '',
  profitabilityPresale: '',
  isPresale: false,
  presaleToken: '',
  create: false,
  bankAccount: '',
  contract: []
}

const validationSchema = yup.object().shape({
  name: yup.string().required(i18n.t('formErrors.required')),
  description: yup.string().required(i18n.t('formErrors.required')),
  issuer: yup.string().required(i18n.t('formErrors.required')),
  profitability: yup.string().required(i18n.t('formErrors.required')),
  summary: yup.string().required(i18n.t('formErrors.required')),
  tokenName: yup.string().required(i18n.t('formErrors.required')),
  tokenType: yup.string().required(i18n.t('formErrors.required')),
  logo: yup.array().length(1, i18n.t('formErrors.required')),
  contract: yup.array().length(1, i18n.t('formErrors.required')),
  bankAccount: yup.string().required(i18n.t('formErrors.required')),
  tokenSymbol: yup
    .string()
    .required(i18n.t('formErrors.required'))
    .min(3, i18n.t('formErrors.minCharacters', { min: 3 }))
    .max(10, i18n.t('formErrors.maxCharacters', { max: 10 })),
  tokenAddress: yup
    .string()
    .required(i18n.t('formErrors.required'))
    .hex(i18n.t('formErrors.hex'))
    .max(42, i18n.t('formErrors.maxCharacters', { max: 42 })),
  create: yup.boolean(),
  regulatorServiceAddress: yup
    .string()
    .when('create', {
      is: true,
      then: yup
        .string()
        .when('tokenType', {
        is: (tokenType: TokenType) => tokenType === TokenType.S_TOKEN,
        then: yup.string().required(i18n.t('formErrors.required'))
      })
    }),
  descriptionPresale: yup.string().required(i18n.t('formErrors.required')),
  issuerPresale: yup.string().required(i18n.t('formErrors.required')),
  profitabilityPresale: yup.string().required(i18n.t('formErrors.required')),
  isPresale: yup.boolean(),
  presaleToken: yup
    .string()
    .when('isPresale',{
      is: true,
      then: yup
        .string()
        .required(i18n.t('formErrors.required'))
        .hex(i18n.t('formErrors.hex'))
        .max(42, i18n.t('formErrors.maxCharacters', { max: 42 }))
    })
})

interface feeOption {
  id: number
  value: number
}

const FeeOptions = [
  {
    id: 500,
    value: 0.05
  },
  {
    id: 3000,
    value: 0.3
  },
  {
    id: 10000,
    value: 1
  }
]

interface Props {
  asset?: Asset
  onSuccess: () => void,
}

export const AssetForm = ({ onSuccess, asset }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const formRef = createRef<FormikProps<FormValues>>()
  const isSuperUser = useSelector(getUserSuperUserStatus)

  const [initialValues, setInitialValues] = useState<FormValues>(formInitialValues)

  const initValues = useCallback(async () => {
    let files: Array<File> = []
    let logo: Array<File> = []
    let contract: Array<File> = []
    let owner: ClientSearchValue | null = null
    if (asset?.files?.length) {
      const resolvedFiles = await Promise.all(
        asset.files.map(async (file) => {
          const { target } = await urlToBlob(file.file, 'arrayBuffer')
          const { extension, filename } = extractFileNameAndExtension(file.file)
          if (!target?.result) {
            return null
          }
          return new File([target.result], `${filename}.${extension}`, { type: `text/${extension}` })
        })
      )
      files = resolvedFiles.filter((file) => file !== null) as Array<File>
    }

    if (asset?.logo) {
        const { target } = await urlToBlob(asset.logo, 'arrayBuffer')
        const { extension, filename } = extractFileNameAndExtension(asset.logo)
        if (target?.result) {
          logo = [new File([target.result], `${filename}.${extension}`, { type: `text/${extension}` })]
        }
    }

    if (asset?.contract) {
        const { target } = await urlToBlob(asset.contract, 'arrayBuffer')
        const { extension, filename } = extractFileNameAndExtension(asset.contract)
        if (target?.result) {
          contract = [new File([target.result], `${filename}.${extension}`, { type: `text/${extension}` })]
        }
    }

    if (asset?.owner && isSuperUser) {
      const client = await ClientsRepository.get(asset?.owner)
      owner = { key: client.id, subLabel: client.user?.email, label: client.fullName, extraData: client }
    }

    let data = {
      name: asset?.name ?? '',
      issuer: asset?.issuer ?? '',
      profitability: asset?.profitability ?? '',
      summary: asset?.summary ?? '',
      description: asset?.description ?? '',
      tokenName: asset?.tokenName ?? '',
      tokenSymbol: asset?.tokenSymbol ?? '',
      tokenAddress: asset?.tokenAddress ?? '',
      bankAccount: asset?.bank_account ?? '',
      logo: logo ?? [],
      files: files ?? [],
      contract: contract ?? [],
      owner: owner ?? null,
      tokenType: asset?.tokenType ?? TokenType.ERC_20_TOKEN,
      regulatorServiceAddress: asset?.regulator_service_address ?? '',
      uniswap_fee: asset?.uniswap_fee ?? 500,
      descriptionPresale: asset?.description_presale ?? '',
      issuerPresale: asset?.issuer_presale ?? '',
      profitabilityPresale: asset?.profitability_presale ?? '',
      isPresale: asset?.is_presale ?? false,
      presaleToken: asset?.presale_token ?? '',
      create: asset ? false : true
    }

    setInitialValues(data)
  }, [asset, isSuperUser])

  useEffect(() => {
    if (asset) {
      initValues()
    }
  }, [initValues, asset])

  const handleCancel = () => {
    dispatch(closeDialog())
  }

  const handleSubmit = async (values: FormValues) => {
    dispatch(setLoader(true))
    const {
      name,
      description,
      issuer,
      profitability,
      summary,
      tokenName,
      tokenSymbol,
      tokenAddress,
      files,
      logo,
      owner,
      bankAccount,
      contract,
      tokenType,
      regulatorServiceAddress,
      uniswap_fee,
      descriptionPresale,
      issuerPresale,
      profitabilityPresale,
      isPresale,
      presaleToken
    } = values
    try {
      const commonData = {
        name,
        description,
        issuer,
        profitability,
        summary,
        token_name: tokenName,
        token_symbol: tokenSymbol,
        token_address: tokenAddress,
        logo: logo[0],
        contract: contract[0],
        bank_account: bankAccount,
        files: files,
        owner: owner?.extraData.id,
        description_presale: descriptionPresale,
        issuer_presale: issuerPresale,
        profitability_presale: profitabilityPresale,
        is_presale: isPresale,
        presale_token: presaleToken === '' ? undefined : presaleToken
      }

      if (asset) {
        const updateDate: AssetUpdateInputModel = {
          id: asset.id,
          ...commonData
        }
        await AssetsRepository.update(updateDate)
        dispatch(openSnackbar({ message: t('assets.edition.successfulUpdate') }))
      } else {
        const newData: AssetCreateInputModel = {
          token_type: tokenType,
          regulator_service_address: regulatorServiceAddress.length ? regulatorServiceAddress : undefined,
          uniswap_fee: uniswap_fee,
          ...commonData
        }
        await AssetsRepository.create(newData)
        dispatch(openSnackbar({ message: t('assets.creation.successfulCreate') }))
      }
      handleCancel()
      onSuccess()
    } catch (e) {
      dispatch(openSnackbar({ message: e.response.message ?? t('defaultErrorMsg'), severity: 'error' }))
    } finally {
      dispatch(setLoader(false))
    }
  }

  const handleFileRemove = (removedFile: File) => {
    const filteredFiles = formRef.current?.values.files.filter((file) => file.name !== removedFile.name)
    formRef.current?.setFieldValue('files', filteredFiles)
  }

  const searchClients = async (searchValue: string) => {
    const { items } = await ClientsRepository.list({ search: searchValue })
    return items.map((client) => ({ key: client.id, label: client.fullName, subLabel: client.user?.email, extraData: client }))
  }

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

  return (
    <>
      <AglaiaDialogContent>
        <Grid container direction="column" wrap="nowrap" justify="flex-start" alignItems="flex-start">
          <DialogContentText>{t('assets.creation.description')}</DialogContentText>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}
            enableReinitialize
            >
            {({ handleChange, errors, touched, setFieldValue, values }: FormikProps<FormValues>) => {
              return (
                <Form noValidate className={classes.content}>
                  <TextField
                    autoFocus
                    margin="normal"
                    id="name"
                    required
                    label={t('formLabels.assetCreation.name')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.name}
                    error={Boolean(touched.name) && Boolean(errors.name)}
                    helperText={Boolean(touched.name) && Boolean(errors.name) && errors.name}
                  />
                  <TextField
                    margin="normal"
                    id="summary"
                    required
                    label={t('formLabels.assetCreation.summary')}
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.summary}
                    error={Boolean(touched.summary) && Boolean(errors.summary)}
                    helperText={Boolean(touched.summary) && Boolean(errors.summary) && errors.summary}
                  />
                  <TextField
                    margin="normal"
                    id="description"
                    required
                    label={t('formLabels.assetCreation.description')}
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.description}
                    error={Boolean(touched.description) && Boolean(errors.description)}
                    helperText={Boolean(touched.description) && Boolean(errors.description) && errors.description}
                  />
                  <TextField
                    margin="normal"
                    id="issuer"
                    required
                    label={t('formLabels.assetCreation.issuer')}
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.issuer}
                    error={Boolean(touched.issuer) && Boolean(errors.issuer)}
                    helperText={Boolean(touched.issuer) && Boolean(errors.issuer) && errors.issuer}
                  />
                  <TextField
                    margin="normal"
                    id="profitability"
                    required
                    label={t('formLabels.assetCreation.profitability')}
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.profitability}
                    error={Boolean(touched.profitability) && Boolean(errors.profitability)}
                    helperText={Boolean(touched.profitability) && Boolean(errors.profitability) && errors.profitability}
                  />
                  <TextField
                    margin="normal"
                    id="descriptionPresale"
                    required
                    label={t('formLabels.assetCreation.descriptionPresale')}
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.descriptionPresale}
                    error={Boolean(touched.descriptionPresale) && Boolean(errors.descriptionPresale)}
                    helperText={Boolean(touched.descriptionPresale) && Boolean(errors.descriptionPresale) && errors.descriptionPresale}
                  />
                  <TextField
                    margin="normal"
                    id="issuerPresale"
                    required
                    label={t('formLabels.assetCreation.issuerPresale')}
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.issuerPresale}
                    error={Boolean(touched.issuer) && Boolean(errors.issuer)}
                    helperText={Boolean(touched.issuer) && Boolean(errors.issuer) && errors.issuer}
                  />
                  <TextField
                    margin="normal"
                    id="profitabilityPresale"
                    required
                    label={t('formLabels.assetCreation.profitabilityPresale')}
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.profitabilityPresale}
                    error={Boolean(touched.profitabilityPresale) && Boolean(errors.profitabilityPresale)}
                    helperText={Boolean(touched.profitabilityPresale) && Boolean(errors.profitabilityPresale) && errors.profitabilityPresale}
                  />
                  <Grid container spacing={2} justify="flex-start" alignItems="flex-start">
                    <Grid item xs={7}>
                      <TextField
                        margin="normal"
                        id="tokenName"
                        required
                        label={t('formLabels.assetCreation.tokenName')}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        value={values.tokenName}
                        error={Boolean(touched.tokenName) && Boolean(errors.tokenName)}
                        helperText={Boolean(touched.tokenName) && Boolean(errors.tokenName) && errors.tokenName}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        margin="normal"
                        id="tokenSymbol"
                        required
                        label={t('formLabels.assetCreation.tokenSymbol')}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setFieldValue('tokenSymbol', e.target.value.toUpperCase())}
                        value={values.tokenSymbol}
                        error={Boolean(touched.tokenSymbol) && Boolean(errors.tokenSymbol)}
                        helperText={
                          (Boolean(touched.tokenSymbol) && Boolean(errors.tokenSymbol) && errors.tokenSymbol) ||
                          t('formHints.assetCreation.tokenSymbol')
                        }
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    margin="normal"
                    id="tokenAddress"
                    required
                    label={t('formLabels.assetCreation.tokenAddress')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.tokenAddress}
                    error={Boolean(touched.tokenAddress) && Boolean(errors.tokenAddress)}
                    helperText={Boolean(touched.tokenAddress) && Boolean(errors.tokenAddress) && errors.tokenAddress}
                  />
                  {!asset && (
                    <>
                      <TextField
                      name="tokenType"
                      id="tokenType"
                      margin="normal"
                      fullWidth
                      select
                      label={t('formLabels.assetCreation.tokenType')}
                      value={values.tokenType}
                      onChange={handleChange}
                      error={Boolean(touched.tokenType) && Boolean(errors.tokenType)}
                      helperText={Boolean(touched.tokenType) && Boolean(errors.tokenType) && errors.tokenType}
                      variant="outlined">
                      {tokenTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                      </TextField>
                      {values.tokenType === TokenType.S_TOKEN && (
                        <TextField
                          autoFocus
                          margin="normal"
                          id="regulatorServiceAddress"
                          required
                          label={t('formLabels.assetCreation.regulatorServiceAddress')}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                          value={values.regulatorServiceAddress}
                          error={Boolean(touched.regulatorServiceAddress) && Boolean(errors.regulatorServiceAddress)}
                          helperText={
                            Boolean(touched.regulatorServiceAddress) && Boolean(errors.regulatorServiceAddress) && errors.regulatorServiceAddress
                          }
                        />
                      )}
                      <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel id="asset_fee_label">{`${t('formLabels.assetCreation.fee')} *`}</InputLabel>
                        <Select
                          labelId="asset_fee_label"
                          id="uniswap_fee"
                          name="uniswap_fee"
                          value={values.uniswap_fee}
                          label={`${t('formLabels.assetCreation.fee')} *`}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth>
                          {FeeOptions.map((fee: feeOption) => {
                            return <MenuItem key={fee.id} value={fee.id}>{`${fee.value} %`}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </>
                  )}
                  <Grid item>
                    <Typography component="small" variant="body2" color="secondary">
                      <Checkbox {...label} onChange={handleChange} value={values.isPresale} name="isPresale" />
                      {t('formLabels.assetCreation.presaleActive')}
                    </Typography>
                  </Grid>
                  <TextField
                    margin="normal"
                    id="presaleToken"
                    required={values.isPresale}
                    label={t('formLabels.assetCreation.tokenAddressPresale')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.presaleToken}
                    error={Boolean(touched.presaleToken) && Boolean(errors.presaleToken)}
                    helperText={Boolean(touched.presaleToken) && Boolean(errors.presaleToken) && errors.presaleToken}
                  />
                  <FileUploader
                    placeholder={`${t('formLabels.assetCreation.contract')} *`}
                    onChange={(contract) => {
                      setFieldValue('contract', contract)
                    }}
                    onRemoved={() => {
                      setFieldValue('contract', null)
                    }}
                    value={values.contract}
                    error={Boolean(errors.contract)}
                    helperText={errors?.contract || null}
                  />
                  <TextField
                    margin="normal"
                    id="bankAccount"
                    required
                    label={t('formLabels.assetCreation.bankAccount')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.bankAccount}
                    error={Boolean(touched.bankAccount) && Boolean(errors.bankAccount)}
                    helperText={Boolean(touched.bankAccount) && Boolean(errors.bankAccount) && errors.bankAccount}
                  />
                  <FileUploader
                    placeholder={`${t('formLabels.assetCreation.logo')} *`}
                    onChange={(logo) => {
                      setFieldValue('logo', logo)
                    }}
                    onRemoved={() => {
                      setFieldValue('logo', null)
                    }}
                    value={values.logo}
                    error={Boolean(errors.logo)}
                    helperText={errors?.logo || null}
                  />
                  <FileUploader
                    placeholder={`${t('formLabels.assetCreation.files')}`}
                    onChange={(files) => {
                      setFieldValue('files', [...values.files, ...files])
                    }}
                    onRemoved={handleFileRemove}
                    multiple
                    value={values.files}
                  />
                  <AsyncAutocomplete<ClientSearchValue>
                    label={`${t('formLabels.assetCreation.owner')}`}
                    promise={searchClients}
                    onChange={(value) => {
                      setFieldValue('owner', value)
                    }}
                    value={values.owner}
                  />
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </AglaiaDialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          {t('assets.creation.cancelBtn')}
        </Button>
        <Button color="primary" onClick={() => formRef.current?.submitForm()}>
          {asset? t('assets.edition.submitBtn') : t('assets.creation.submitBtn')}
        </Button>
      </DialogActions>
    </>
  )
}
