import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Typography, useMediaQuery, CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GridCellProps, Index } from 'react-virtualized'
import { Theme } from '@material-ui/core/styles'
import DashboardIcon from '@material-ui/icons/Dashboard'
import AddIcon from '@material-ui/icons/Add'

import useStyles from './AssetsPage.styles'

import AssetCard from '../../../../common/view/components/asset-card/AssetCard'
import { Asset } from '../../../domain/models/Asset'
import { createTupleFromList } from '../../../../common/utils/array'
import { getUserSuperUserStatus } from '../../../../user/view/store/user.selectors'
import { openDialog } from '../../../../common/view/store/ui/ui.slice'
import { CreateAssetDialog } from '../create-asset-dialog/CreateAssetDialog'
import { useAppDispatch } from '../../../../common/view/store/store'
import EmptyState from '../../../../common/view/components/empty-state/EmptyState'
import GridInfiniteLoader from '../../../../common/view/components/grid-inifite-loader/GridInfiniteLoader'

import { useAssetListWithAmount } from '../../hooks/AssetListWithAmount.hook'

const flexContentCentering = ['flex-start', 'center', 'flex-end']

export default function AssetsPage() {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const router = useHistory()
  const { t } = useTranslation()
  const { assetsPage, loading, getAssets, getMoreAssets, assetsAmountMap } = useAssetListWithAmount()
  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const isSuperUser = useSelector(getUserSuperUserStatus)
  const assetsTuple = useMemo(() => createTupleFromList<Asset>(assetsPage.items, matchesSm ? 3 : 1), [assetsPage.items, matchesSm])
  const scrollElement = document.querySelector('main') as Element

  const openAssetCreationDialog = () => {
    dispatch(
      openDialog({
        content: () => <CreateAssetDialog onSuccess={getAssets} />,
        maxWidth: 'sm',
        fullWidth: true
      })
    )
  }

  const handleOnViewDetail = (assetId: string) => {
    router.push(`/u/assets/${assetId}`)
  }

  const isRowLoaded = ({ index }: Index) => {
    return !!assetsPage.items[index]
  }

  const calculateWith = (width: number) => {
    if (matchesXs) {
      return width;
    }

    return width > 950 ? width / 3 : 370;
  }

  const cellRenderer = ({ key, rowIndex, columnIndex, style }: GridCellProps) => {
    if (!assetsTuple[rowIndex][columnIndex]) {
      return null
    }

    const asset: Asset = assetsTuple[rowIndex][columnIndex]
    const amountData = assetsAmountMap.current?.get(asset.id)
    if (amountData) {
      const { totalSupply, clientBalance, prices } = amountData
      asset.amountData = { totalSupply, clientBalance, prices }
    }

    return (
      <div
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center',
          justifyContent: flexContentCentering[columnIndex],
          paddingTop: '40px',
          borderColor: 'blue',
          borderWidth: 1,
          marginRight: 20,
        }}
        key={key}>
        <AssetCard asset={asset} width={'100%'} loadingAssetAmount={amountData?.loading} onViewDetail={handleOnViewDetail} />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        <Typography variant="h4">
          <b>{t('assets.list.title')} </b>
        </Typography>
          {isSuperUser && (
            <Button className={classes.assetButton} startIcon={<AddIcon />} variant="contained" color="primary" onClick={openAssetCreationDialog}>
            {t('assets.list.createBtn')}
          </Button>
          )}
      </div>
      <div className={classes.gridWrapper}>
        {scrollElement && Boolean(assetsPage.items.length) && (
          <GridInfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={getMoreAssets}
            cellRenderer={cellRenderer}
            rowCountNormalList={assetsPage.totalCount}
            rowCountTupleList={assetsTuple.length}
            columnCount={assetsTuple[0].length}
            columnWidthCallback={calculateWith}
            scrollElement={scrollElement}
          />
        )}
        {loading && (
          <div className={classes.listLoader}>
            <CircularProgress size={30} color="inherit" />
          </div>
        )}
        {!assetsPage.items.length && !loading && <EmptyState icon={DashboardIcon} height="85vh" message={`${t('assets.list.empty')}`} />}
      </div>
    </div>
  )
}
