import { Page } from '../../common/domain/models/Page'
import { HttpClient } from '../../common/data/HttpClient'
import { PageAPI } from '../../common/data/models/PageAPI'
import { plainToClass } from 'class-transformer'
import { Client } from '../domain/Client'
import { ClientUpdateOutputModel } from './models/ClientUpdateOutputModel'
import { ClientUpdate, BusinessUpdate } from '../domain/ClientUpdate'
import { GetClientsOutputModel } from './models/GetClientsOutputModel'
import { ClientBusinessUpdateOutputModel } from './models/ClientBusinessUpdateOutputModel';
import { JurisdictionCode } from './models/JurisdictionCodeModel';

export class ClientsRepository {
  static async list(params?: GetClientsOutputModel): Promise<Page<Client>> {
    const { data } = await HttpClient.instance.get<PageAPI>('/clients/', { params })
    return {
      items: data.results?.map((item: any) => plainToClass(Client, item, { excludeExtraneousValues: true })),
      totalCount: data.count ?? 0
    }
  }

  static async get(id: number): Promise<Client> {
    const { data } = await HttpClient.instance.get(`/clients/${id}/`)
    return plainToClass(Client, data, { excludeExtraneousValues: true })
  }

  static async activate(clientId: string): Promise<void> {
    await HttpClient.instance.post(`/clients/${clientId}/activate/`)
  }

  static async desactivate(clientId: string): Promise<void> {
    await HttpClient.instance.post(`/clients/${clientId}/deactivate/`)
  }

  static async update({ clientId, data: bodyData }: ClientUpdateOutputModel): Promise<ClientUpdate> {
    const { data } = await HttpClient.instance.put(`/clients/${clientId}/`, {
      user: {
        first_name: bodyData.user.firstName,
        last_name: bodyData.user.lastName
      },
      eth_public_address: bodyData.ethPublicAddress
    })
    return plainToClass(ClientUpdate, data, { excludeExtraneousValues: true })
  }

  static async updateBusinessInfo({ clientId, bodyData }: ClientBusinessUpdateOutputModel): Promise<BusinessUpdate> {
    const { firstName, lastName, ethPublicAddress, business_name, business_registration_number, business_jurisdiction_code} = bodyData;
    const body = {
      user: {
        first_name: firstName,
        last_name: lastName
      },
      eth_public_address: ethPublicAddress,
      business_name,
      business_registration_number,
      business_jurisdiction_code,
    }
    const { data } = await HttpClient.instance.put(`/clients/${clientId}/`, body)
    return plainToClass(BusinessUpdate, data, { excludeExtraneousValues: true })
  }

  static async getJurisdictionCodes(): Promise<JurisdictionCode[]> {
    const { data } =  await HttpClient.instance.get('/jurisdiction-codes/');
    return data?.results || [];
  }

  static async getRegisterSignUrl(clientId: string): Promise<string> {
    const { data } = await HttpClient.instance.post(`/clients/${clientId}/docu_sign_registry/`)
    return data.url.redirect_url
  }
}
