import { AppRoute } from '../../../common/view/router/config'
import ResetPassword from '../components/reset-password-page/ResetPasswordPage'
import OwnerPage from '../components/owner-page/OwnerPage'
import { IsOwnerGuard } from './guards/IsOwnerGuard'

export const userRoutes: Array<AppRoute> = [
  {
    path: '/remember-password/:userId/:token',
    component: ResetPassword
  }
]

export const ownerRoutes: Array<AppRoute> = [
  {
    path: '/u/owner',
    component: OwnerPage,
    guard: new IsOwnerGuard()
  }
]
