import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom'
import { Form, Formik, FormikProps } from 'formik'
import { Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Checkbox from '@material-ui/core/Checkbox'

import useStyles, { CompanyName, BackgroundImage, MainContainer } from './SignUpPage.styles'

import i18n from '../../../../common/i18n/config'
import yup from '../../../../common/utils/yup.extended'
import { PASSWORD_REGEX } from '../../../../common/utils/regexs'

import { openSnackbar, setLoader } from '../../../../common/view/store/ui/ui.slice'
import { useAppDispatch } from '../../../../common/view/store/store'
import { PasswordTextField } from '../../../../common/view/components/PasswordTextField'
import { AglaiaPaper } from '../../../../common/view/components/aglaia-paper/AglaiaPaper'

import AglaiaLogo from '../../../../common/assets/logos/LOGO_ACTIONMONKEY.png'

import { AuthRepository } from '../../../data/AuthRepository'
import { AssetsRepository } from '../../../../assets/data/AssetsRepository'

interface FormValues {
  firstName: string
  lastName: string
  email: string
  password: string
  amount: string
  isBusiness: boolean
  acceptTerms: boolean,
  qualifiedInvestor: boolean
}
interface ActiveData {
  name: string
  summary: string
  description_presale: string
  issuer_presale: string
  profitability_presale: string
  logo: string
  tokenSymbol: string
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number | null
}

const formInitialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  amount: '0',
  isBusiness: false,
  acceptTerms: false,
  qualifiedInvestor: false
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required(i18n.t('formErrors.required')),
  lastName: yup.string().required(i18n.t('formErrors.required')),
  email: yup.string().required(i18n.t('formErrors.required')).email(i18n.t('formErrors.email')),
  password: yup
    .string()
    .required(i18n.t('formErrors.required'))
    .test('passwordTest', i18n.t('formErrors.passwordFormat'), (value) => !PASSWORD_REGEX.test(value ?? ''))
})
const amountValidationSchema = yup.object().shape({ amount: yup.string().required(i18n.t('formErrors.required')) })
const registeredValidationSchema = validationSchema.concat(amountValidationSchema)

export default function SignUp(props: any) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const router = useHistory()

  const [signUpError, setSignUpError] = useState<string | undefined>(undefined)
  const [activeData, setActiveData] = useState<ActiveData | undefined>(undefined)
  const [tabValue, setTabValue] = useState<number>(0)
  const [termsError, setTermsError] = useState<boolean>(false)
  const [isRegistered, setIsRegistered] = useState<boolean>(false)

  const tokenId = new URLSearchParams(useLocation().search).get('cryptoasset') || undefined

  useEffect(() => {
    getActiveData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getActiveData = async () => {
    dispatch(setLoader(true))
    if (!tokenId) {
      dispatch(setLoader(false))
      return
    }

    try {
      const active = await AssetsRepository.detail(tokenId)
      dispatch(setLoader(false))
      setActiveData(active)
    } catch (e) {
      setSignUpError('Token no encontrado')
      dispatch(setLoader(false))
      setTimeout(() => {
        router.push('/login', { noCheck: true })
      }, 1000)
      throw e
    }
  }

  const handleSubmit = async (values: FormValues) => {
    const { firstName, lastName, email, password, isBusiness, acceptTerms } = values
    if (!acceptTerms) {
      setTermsError(true)
      return
    }
    setTermsError(false)

    const params = {
      user: {
        email: email,
        first_name: firstName,
        last_name: lastName,
        password
      },
      is_business: isBusiness,
      ...(!tokenId
        ? {}
        : {
            requested_active: {
              amount: 0, /// fixed - Input hide from user
              active: tokenId
            }
          })
    }

    dispatch(setLoader(true))
    try {
      await AuthRepository.signUp(params)
      dispatch(openSnackbar({ message: t('signUp.successfulSignUp') }))
      setIsRegistered(true)
    } catch (e) {
      setSignUpError(e.response.data.user.email)
      throw e
    } finally {
      dispatch(setLoader(false))
    }
  }
  const handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }
  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props
    return (
      <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
        {value === index && (
          <div>
            <Typography>{children}</Typography>
          </div>
        )}
      </div>
    )
  }

  const renderSection = () => {
    if (tokenId)
      return (
        <>
          <div className={classes.preregisterSection}>
            <div className={classes.preregisterTitle}>
              <img className={classes.preregisterLogo} src={activeData?.logo} alt="token-logo" />
              <div className={classes.preregisterTitleName}>
                <Typography variant="h6">
                  <b>[{activeData?.tokenSymbol}]</b>
                </Typography>
              </div>
            </div>
            <Typography align="center">{t('signUp.presale.posttitle')}</Typography>
            <h3 style={{ textAlign: 'center'}}>{activeData?.summary}</h3>
            <div className={classes.preregisterTabs}>
              <Tabs centered textColor="inherit" onChange={handleTabChange} value={tabValue} aria-label="activeData-tabs">
                <Tab label={t('signUp.presale.description')}></Tab>
                <Tab label={t('signUp.presale.issuer')}></Tab>
                <Tab label={t('signUp.presale.profitability')}></Tab>
              </Tabs>
            </div>
            <TabPanel value={tabValue} index={0}>
              <div dangerouslySetInnerHTML={{__html: activeData?.description_presale || ''}}/>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <div dangerouslySetInnerHTML={{__html: activeData?.issuer_presale || ''}}/>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <div dangerouslySetInnerHTML={{__html: activeData?.profitability_presale || ''}}/>
            </TabPanel>
            <br/>
            <Typography variant="subtitle1" align="center" className={classes.disclaimer}>
              <a target={'_blank'} href="/#/disclaimer/">{t('signUp.presale.disclaimer')}</a>
            </Typography>
          </div>
        </>
      )
    return (
      <>
        <div className={classes.section}>
          <img className={classes.logo} src={AglaiaLogo} alt="Aglaia Capital DeFi Platform" title="Aglaia Capital DeFi Platform"/>
          <CompanyName variant="h4" display="block">
            <b>{t('signUp.title')}</b>
          </CompanyName>
        </div>
      </>
    )
  }

  const renderTitle = () => {
    if (tokenId) {
      return (
        <>
          <div className={classes.paperHeader}>
            <img className={classes.logoBlack} src={AglaiaLogo} alt="Aglaia Capital DeFi Platform" title="Aglaia Capital DeFi Platform"/>
            <Typography>
              <b>{t('headerNav.title')}</b>
            </Typography>
          </div>
          <Typography variant="h6" display="block" className={classes.subtitle}>
            <b>{t('signUp.presale.title')}</b>
          </Typography>
          <Typography variant="caption" className={classes.formSubtitle}>
            {t('signUp.presale.subtitle', { name: activeData?.name })}
          </Typography>
        </>
      )
    }
    return (
      <>
        <Typography variant="h6" display="block" color="primary" className={classes.subtitle}>
          <b>{t('signUp.subtitle')}</b>
        </Typography>
        {!isRegistered && (
          <Typography variant="caption" className={classes.formSubtitle}>
            {t('signUp.formSubtitle')}
          </Typography>
        )}
        {isRegistered && (
          <Typography variant="h6" className={classes.registeredText}>
            {t('signUp.registeredMessage')}
          </Typography>
        )}
      </>
    )
  }

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

  const renderContentForm = () => {
    return (
      <Formik
        initialValues={formInitialValues}
        validationSchema={tokenId ? registeredValidationSchema : validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}>
        {({ errors, touched, handleChange, values }: FormikProps<FormValues>) => {
          return (
            <Form className={classes.form} noValidate>
              <Grid container spacing={2} justify="flex-start" alignItems="flex-start">
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    label={t('formLabels.signUp.firstName')}
                    autoFocus
                    onChange={handleChange}
                    value={values.firstName}
                    error={Boolean(touched.firstName) && Boolean(errors.firstName)}
                    helperText={Boolean(touched.firstName) && Boolean(errors.firstName) && errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label={t('formLabels.signUp.lastName')}
                    name="lastName"
                    autoComplete="lname"
                    onChange={handleChange}
                    value={values.lastName}
                    error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                    helperText={Boolean(touched.lastName) && Boolean(errors.lastName) && errors.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label={t('formLabels.signUp.email')}
                    name="email"
                    autoComplete="email"
                    onChange={handleChange}
                    value={values.email}
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={Boolean(touched.email) && Boolean(errors.email) && errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordTextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label={t('formLabels.signUp.password')}
                    onChange={handleChange}
                    value={values.password}
                    error={Boolean(touched.password) && Boolean(errors.password)}
                    helperText={(Boolean(touched.password) && Boolean(errors.password) && errors.password) || t('formHints.signUp.password')}
                    autoComplete="current-password"
                  />
                  {errors.password && touched.password && (
                    <div className={classes.errorHelper}>
                      <Typography variant="caption" color="error">
                        <b>{t('formHints.signUp.password')}</b>
                      </Typography>
                    </div>
                  )}
                </Grid>
                {/* {tokenId && (
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      type="number"
                      label={t('formLabels.signUp.preSale')}
                      name="amount"
                      margin="dense"
                      onChange={handleChange}
                      value={values.amount}
                      error={Boolean(touched.amount) && Boolean(errors.amount) && Number(values.amount) <= 0}
                      helperText={Boolean(touched.amount) && Boolean(errors.amount) && errors.amount}
                    />
                  </Grid>
                )} */}
                <Grid item className={classes.checkBoxContainer}>
                  <Typography component="small" variant="caption" color="secondary">
                    <Checkbox {...label} onChange={handleChange} value={values.isBusiness} name="isBusiness" />
                    {t('formLabels.signUp.businessAccount')}
                  </Typography>
                </Grid>
                {signUpError && (
                  <Grid item className={classes.generalError}>
                    <Typography component="small" variant="caption" color="error">
                      {signUpError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item className={classes.checkBoxContainer}>
                <Checkbox {...label} onChange={handleChange} value={values.qualifiedInvestor} name="qualifiedInvestor" required />
                <Typography component="small" variant="caption" color="secondary">
                  {t('formLabels.signUp.investor')}
                  <a target={'_blank'} href="/#/qualified-investor-requirements/">{t('formLabels.signUp.investorLink')}</a>
                </Typography>
              </Grid>
              <Grid item className={classes.checkBoxContainer}>
                <Checkbox {...label} onChange={handleChange} value={values.acceptTerms} name="acceptTerms" required />
                <Typography component="small" variant="caption" color="secondary">
                  {t('formLabels.signUp.certifity')}
                  <a target={'_blank'} href="/#/privacy-policy/">{t('formLabels.signUp.privacyPolicy')}</a>
                  {t('formLabels.signUp.the')}
                  <a target={'_blank'} href="/#/cookies-policy/">{t('formLabels.signUp.cookiesPolicy')}</a>
                   {" " + t('formLabels.signUp.and') + " "}
                  <a target={'_blank'} href="/#/terms-and-conditions/">{t('formLabels.signUp.termsConditions')}</a>.
                </Typography>
              </Grid>
              {termsError && (
                <Grid item className={classes.generalError}>
                  <Typography component="small" variant="caption" color="error">
                    {t('formLabels.signUp.pleaseAcceptTerms')}
                  </Typography>
                </Grid>
              )}
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                {t('signUp.submitBtn')}
              </Button>
              <Divider className={classes.formDivider} />
              <Grid container justify="flex-start" alignItems="flex-start">
                <Grid item>
                  <Link component={RouterLink} to={{ pathname: '/login', state: { noCheck: true } }} variant="body2">
                    {t('signUp.loginClaim')}
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    )
  }
  return (
    <MainContainer>
      <BackgroundImage>
        <div className={classes.shape} />
        <div className={classes.paper}>
          {renderSection()}
          <div className={classes.sectionRight}>
            <AglaiaPaper className={classes.signUpPaper}>
              {renderTitle()}
              {!isRegistered && renderContentForm()}
            </AglaiaPaper>
          </div>
        </div>
      </BackgroundImage>
    </MainContainer>
  )
}
