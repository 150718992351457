import { AppRoute, RouteGuard } from '../../../../common/view/router/config'
import type * as H from 'history'
import { UserRepository } from '../../../../user/data/UserRepository'
import { RedirectConfig } from '../../../../common/view/router/RedirectConfig'

export interface LoginLocationState {
  noCheck: boolean
}

export class LoginGuard implements RouteGuard {
  async execute(
    route: AppRoute,
    location: H.Location<LoginLocationState>,
    query: URLSearchParams
  ): Promise<boolean | string | RedirectConfig<LoginLocationState>> {
    if (location.state?.noCheck) return true
    try {
      await UserRepository.get()
      return '/u'
    } catch (e) {
      return true
    }
  }
}
