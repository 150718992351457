import { ContractRepository } from '../../../eth/data/ContractRepository'
import { AssetAmountData } from '../models/AssetAmountData'
import { Asset } from '../models/Asset'
import { GetTradeExecutionPriceUseCase } from '../../../eth/domain/use-cases/GetTradeExecutionPriceUseCase'
import { ApiToken } from '../../../eth/domain/models/ApiToken'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { UniswapFetcherService } from '../../../eth/data/UniswapFetcherService'
import SwappableTokens from '../../../eth/domain/models/SwappableTokens'
import {FeeAmount} from "@uniswap/v3-sdk";

export class GetAssetAmountDataUseCase {
  getTradeExecutionPriceUseCase: GetTradeExecutionPriceUseCase

  constructor() {
    this.getTradeExecutionPriceUseCase = new GetTradeExecutionPriceUseCase()
  }

  async execute(asset: Asset, clientAddress: string): Promise<AssetAmountData> {
    const { tokenAddress, swappableTokens, presale_token, uniswap_fee } = asset
    const contract = new ContractRepository(tokenAddress)
    const assetToken = await UniswapFetcherService.fetchTokenData(tokenAddress)
    const pricesPromise = await Promise.all(swappableTokens.map(async (apiToken) => await this.getSwappableTokens(apiToken, assetToken, uniswap_fee)))
    const tokenPresaleContract = !presale_token ? null : new ContractRepository(presale_token)
    const [totalSupply, clientBalance, prices, presaleBalance] = await Promise.all([
      contract.totalSupply(),
      clientAddress ? contract.balance(clientAddress) : null,
      pricesPromise,
      tokenPresaleContract ? tokenPresaleContract.balance(clientAddress) : null
    ])

    return {
      totalSupply,
      clientBalance: clientBalance ?? CurrencyAmount.fromRawAmount(assetToken, '0'),
      prices,
      presaleBalance: presaleBalance ?? undefined,
    }
  }

  private async getSwappableTokens(token: ApiToken, asset: Token, fee = FeeAmount.MEDIUM) {
    const tokenFromSwappableObj = SwappableTokens.getTokenByAddress(token.address)
    try {
      const { outputAmount } = await this.getTradeExecutionPriceUseCase.execute({
        assetToken: asset,
        token: tokenFromSwappableObj ? tokenFromSwappableObj : token.address,
        amount: '1',
        fee,
        operationType: 'sell'
      })
      return { currency: token.name, amount: outputAmount }
    } catch (e) {
      return { currency: token.name }
    }
  }
}
