import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog, setLoader } from '../../../../common/view/store/ui/ui.slice'
import { Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import yup from '../../../../common/utils/yup.extended'
import React, { useRef } from 'react'
import { Asset } from '../../../domain/models/Asset'
import DialogContentText from '@material-ui/core/DialogContentText'
import { addTransaction, changeTransactionStatus } from '../../../../eth/view/store/eth.slice'
import { useEthErrorsWrapper } from '../../../../eth/view/hooks/EthErrorsWrapper.hook'
import { AglaiaDialogContent } from '../../../../common/view/components/app-dialog/AppDialog.styled'
import { UnpauseTokenInteractor } from '../../../../eth/domain/use-cases/UnpauseTokenInteractor/UnpauseTokenInteractor'
import { checkIfTxResponse } from '../../../../eth/utils/ethers'
import { providers } from 'ethers'
import EthereumClient from '../../../../eth/data/EthereumClient'

interface FormValues {}

const formInitialValues: FormValues = {}

const validationSchema = yup.object().shape({})

interface Props {
  ownedAsset: Asset
  onSuccess?: () => void
}

export const UnpauseAssetDialog = ({ ownedAsset, onSuccess }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef = useRef<FormikProps<FormValues>>(null)
  const unpauseUseCase = useEthErrorsWrapper(UnpauseTokenInteractor.execute)

  const handleCloseDialog = () => {
    dispatch(closeDialog())
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      dispatch(setLoader(true))
      const txRes = await unpauseUseCase(ownedAsset)
      if (checkIfTxResponse(txRes)) {
        const castedTxRes = txRes as providers.TransactionResponse
        dispatch(addTransaction({ hash: castedTxRes.hash, type: 'unpause' }))
        handleCloseDialog()
        await castedTxRes?.wait()
        dispatch(changeTransactionStatus({ hash: castedTxRes.hash, status: 'completed' }))
      } else {
        const castedTxResHash = txRes as string
        const provider = await EthereumClient.currentProvider()
        dispatch(addTransaction({ hash: castedTxResHash, type: 'unpause' }))
        handleCloseDialog()
        await provider.waitForTransaction(castedTxResHash)
        dispatch(changeTransactionStatus({ hash: castedTxResHash, status: 'completed' }))
      }
      if (onSuccess) {
        await onSuccess()
      }
      dispatch(setLoader(false))
    } catch (e) {
      console.error(e)
      dispatch(setLoader(false))
    }
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      enableReinitialize>
      {({ values, handleChange, errors, touched }: FormikProps<FormValues>) => {
        return (
          <>
            <DialogTitle id="form-dialog-title">{t('assets.unpause.title', { assetName: ownedAsset.name })}</DialogTitle>
            <AglaiaDialogContent>
              <DialogContentText>{t('assets.unpause.description')}</DialogContentText>
            </AglaiaDialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleCloseDialog}>
                {t('assets.unpause.cancelBtn')}
              </Button>
              <Button color="primary" onClick={() => formRef.current?.submitForm()}>
                {t('assets.unpause.submitBtn')}
              </Button>
            </DialogActions>
          </>
        )
      }}
    </Formik>
  )
}
