import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import user from '../../../user/view/store/user.slice'
import eth from '../../../eth/view/store/eth.slice'
import ui from './ui/ui.slice'

const store = configureStore({
  reducer: {
    user,
    ui,
    eth
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export default store

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
