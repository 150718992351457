import { providers } from 'ethers'
import { MetamaskProvider } from './models/MetamaskProvider'
import { EnvVars } from '../../common/config/EnvVars'

const DEFAULT_PROVIDER = `${EnvVars.etherNetworkUrl}`

export const UNISWAP_V3_ROUTER_ADDRESS = '0xE592427A0AEce92De3Edee1F18E0157C05861564'
export const UNISWAP_QUOTER_ADDRESS = '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6'

export enum Web3CheckErrors {
  WRONG_CHAIN_ID = 'WRONG_CHAIN_ID',
  DIFFERENT_PUBLIC_ADDRESS = 'DIFFERENT_PUBLIC_ADDRESS'
}

class EthereumClient {
  private readonly _jsonRpcProvider: providers.JsonRpcProvider
  private _web3Provider: providers.Web3Provider | null = null
  private web3ClientStoredAddress?: string
  private web3ClientStoredAddressChanged = false
  private _defaultChainId?: number

  constructor() {
    this._jsonRpcProvider = new providers.JsonRpcProvider(DEFAULT_PROVIDER)
    this.setMetamask()
  }

  get metamaskProvider(): MetamaskProvider | null {
    return (this._web3Provider?.provider as MetamaskProvider) ?? null
  }

  get defaultProvider(): providers.JsonRpcProvider {
    return this._jsonRpcProvider
  }

  get web3Provider(): providers.Web3Provider | null {
    return this._web3Provider
  }

  get web3ClientAddressChanged(): boolean {
    return this.web3ClientStoredAddressChanged
  }

  setMetamask(): void {
    this._web3Provider = window.ethereum ? new providers.Web3Provider(window.ethereum) : null
  }

  setStoredAddress(storedAddress?: string): void {
    this.web3ClientStoredAddress = storedAddress
  }

  setStoredAddressChanged(changed: boolean): void {
    this.web3ClientStoredAddressChanged = changed
  }

  private async extractWeb3Info() {
    if (this.metamaskProvider?.selectedAddress) {
      try {
        const [chainId, publicAddress] = await Promise.all([
          this._web3Provider?.getSigner().getChainId(),
          this._web3Provider?.getSigner().getAddress()
        ])
        return { chainId, publicAddress }
      } catch (e) {
        console.error(e)
      }
    }
  }

  resetToDefaultProvider(): void {
    this._web3Provider = null
  }

  async defaultChainId(): Promise<number> {
    if (this._defaultChainId) {
      return this._defaultChainId
    }
    const chainId = await this._jsonRpcProvider.getSigner().getChainId()
    this._defaultChainId = chainId
    return chainId
  }

  private async metamaskWalletChecks() {
    const web3Info = await this.extractWeb3Info()

    const defaultChainID = await this.defaultChainId()

    if (web3Info?.chainId !== defaultChainID) {
      throw new Error(JSON.stringify({ errorCode: Web3CheckErrors.WRONG_CHAIN_ID, data: { chainId: defaultChainID } }))
    } else if (this.web3ClientStoredAddressChanged && this.web3ClientStoredAddress !== web3Info?.publicAddress) {
      throw new Error(
        JSON.stringify({
          errorCode: Web3CheckErrors.DIFFERENT_PUBLIC_ADDRESS,
          data: { address: this.web3ClientStoredAddress }
        })
      )
    }
  }

  async currentProvider(): Promise<providers.JsonRpcProvider | providers.Web3Provider>
  {
    if (this._web3Provider && this._web3Provider.provider.isMetaMask && this.web3ClientStoredAddressChanged) {
      if (this.metamaskProvider?.selectedAddress) {
        await this.metamaskWalletChecks()
        return this._web3Provider
      }
    }
    return this._jsonRpcProvider
  }

  async providerAddress(forceProvider?: 'web3' | 'jsonRpc'): Promise<string | null> {
    try {
      let provider
      switch (forceProvider) {
        case 'jsonRpc':
          provider = this._jsonRpcProvider
          break
        case 'web3':
          provider = this._web3Provider
          break
        default:
          provider = await this.currentProvider()
          break
      }
      return (await provider?.getSigner().getAddress()) ?? null
    } catch (e) {
      // when json rpc provider is set getAddress() method returns an error with unknown account
      // that's why we catch and return null or guardian address
      return this.web3ClientStoredAddress ?? null
    }
  }
}

export default new EthereumClient()
