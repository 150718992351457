import { Expose, Type } from 'class-transformer'
import { User } from '../../user/domain/models/User'

export class Client {
  @Expose()
  id!: string
  @Expose()
  @Type(() => User)
  user?: User
  @Expose({ name: 'eth_public_address' })
  ethPublicAddress!: string
  @Expose({ name: 'has_changed_address' })
  addressChanged!: boolean
  @Expose({ name: 'is_owner' })
  isOwner!: boolean
  @Expose({ name: 'activation_task' })
  private activationTask!: string
  @Expose({ name: 'deactivation_task' })
  private deactivationTask!: boolean
  @Expose()
  is_business!: boolean
  @Expose()
  business_jurisdiction_code!: string
  @Expose()
  business_registration_number!: string
  @Expose()
  business_name!: string
  @Expose()
  business_know_is_validated!: boolean
  @Expose()
  business_aml_is_validated!: boolean
  @Expose()
  business_docs_is_validated!: boolean
  @Expose()
  aglaia_id!: string
  @Expose()
  registry_docs_signed!: boolean


  get activationPending(): boolean {
    return this.activationTask !== null
  }

  get fullName(): string {
    return `${this.user?.firstName} ${this.user?.lastName}`
  }
}
