import { useEffect } from 'react'
import { BackgroundImage, MainContainer, Content } from './ClosePage.styles'

export default function ClosePage() {
  useEffect(() => {
    window.parent.postMessage('close', '*')
  }, []);

  return (
    <MainContainer>
      <BackgroundImage>
        <Content id="close-page">
        </Content>
      </BackgroundImage>
    </MainContainer>
  )
}
