import NonfungiblePositionManager from '../../common/assets/abis/NonfungiblePositionManager.json'
import EthereumClient from './EthereumClient'
import {BigNumber, Contract, ContractInterface} from 'ethers'
import {Liquidity} from "../../assets/domain/models/Liquidity";

export class NFTRepository {
  contract: Contract

  constructor(assetTokenAddress: string) {
    this.contract = new Contract(assetTokenAddress, NonfungiblePositionManager as ContractInterface)
  }

  async balanceOf(address: string): Promise<number> {
    const provider = EthereumClient.defaultProvider
    const contract = this.contract.connect(provider)
    const res: BigNumber = await contract.callStatic.balanceOf(address)
    return res.toNumber()
  }

  async tokenOfOwnerByIndex(address: string, index: number): Promise<number> {
    const provider = EthereumClient.defaultProvider
    const contract = this.contract.connect(provider)
    const res: BigNumber = await contract.callStatic.tokenOfOwnerByIndex(address, index)
    return res.toNumber()
  }

  async tokenData(tokenId: number): Promise<Liquidity> {
    const provider = EthereumClient.defaultProvider
    const contract = this.contract.connect(provider)
    const res = await contract.callStatic.tokenURI(tokenId)

    const data:Liquidity = JSON.parse(atob(res.substring(29)))
    data.id = tokenId
    return data
  }
}
