import { GetUserDataUseCase } from '../../../user/data/use-cases/GetUserDataUseCase'
import { EthApiRepository } from '../../../eth/data/EthApiRepository'
import SwappableTokens from '../../../eth/domain/models/SwappableTokens'
import { User } from '../../../user/domain/models/User'

export class InitAppUseCase {
  static async execute(): Promise<User> {
    const [user, swappableTokens] = await Promise.all([GetUserDataUseCase.execute(), EthApiRepository.swappableTokens()])
    SwappableTokens.setList(swappableTokens)
    await SwappableTokens.fetchTokenObjects()
    return user
  }
}
