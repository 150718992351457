import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {useDispatch, useSelector} from 'react-redux'
import {closeDialog, openSnackbar, setLoader} from '../../../../common/view/store/ui/ui.slice'
import { Form, Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../common/i18n/config'
import yup from '../../../../common/utils/yup.extended'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Grid, InputAdornment, MenuItem, Typography } from '@material-ui/core'
import { Asset } from '../../../domain/models/Asset'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { GetTradeExecutionPriceUseCase, TradeExecutionPriceUseCaseInput } from '../../../../eth/domain/use-cases/GetTradeExecutionPriceUseCase'
import useStyles from './SwapDialog.styles'
import { OperationType } from '../../../../eth/domain/models/OperationType'
import debounce from 'lodash/debounce'
import CircularProgress from '@material-ui/core/CircularProgress'
import BigNumber from 'bignumber.js'
import { AglaiaDialogContent } from '../../../../common/view/components/app-dialog/AppDialog.styled'
import {
  SwapWithoutWalletUseCase,
  SwapWithoutWalletUseCaseInput
} from "../../../../eth/domain/use-cases/SwapInteractor/SwapWithoutWalletUseCase";
import {UniswapService} from "../../../../eth/domain/services/UniswapService";
import {getUserClientWalletAddress} from "../../../../user/view/store/user.selectors";
import ViewEtherscanButton from "../../../../eth/view/components/view-etherscan-button/ViewEtherscanButton";
import {FixedNumber} from "ethers";

interface FormValues {
  tokenAmount: string
  assetTokenAmount: string
  selectedToken: string
}

const formInitialValues: FormValues = {
  tokenAmount: '',
  assetTokenAmount: '',
  selectedToken: '',
}

const buyValidationSchema = yup.object().shape({
  tokenAmount: yup
    .string()
    .required(i18n.t('formErrors.required'))
    .moreThan(0, i18n.t('formErrors.moreThan', { min: 0 })),
  selectedToken: yup.string().required(i18n.t('formErrors.required'))
})

const sellValidationSchema = yup.object().shape({
  assetTokenAmount: yup
    .string()
    .required(i18n.t('formErrors.required'))
    .moreThan(0, i18n.t('formErrors.moreThan', { min: 0 })),
  selectedToken: yup.string().required(i18n.t('formErrors.required'))
})

interface Props {
  asset: Asset
  onSuccess: () => void
  operationType?: OperationType
  usdcBalance: FixedNumber | null
}

export const SwapDialog = ({ asset, onSuccess, operationType = 'sell', usdcBalance }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const formRef = useRef<FormikProps<FormValues>>(null)
  const [loadingAssetPrice, setLoadingAssetPrice] = useState(false)
  const currentuserWalletAddress = useSelector(getUserClientWalletAddress) || ''
  const getTradeExecutionPriceUseCase = useMemo(() => new GetTradeExecutionPriceUseCase(), [])
  const [priceError, setPriceError] = useState(false)

  useEffect(() => {
    formRef.current?.setFieldValue('selectedToken', asset.swappableTokens[0].address)
  }, [asset.swappableTokens, formRef])

  const _getAssetPrices = async (tokenAmount: BigNumber, outAmountKey: string): Promise<void> => {
    if (tokenAmount.isGreaterThan(new BigNumber(0))) {
      try {
        setLoadingAssetPrice(true)
        const input: TradeExecutionPriceUseCaseInput = {
          assetToken: asset.tokenAddress,
          amount: tokenAmount.toString(),
          fee: asset.uniswap_fee,
          operationType,
          token: formRef.current?.values.selectedToken ?? asset.swappableTokens[0].address
        }
        const prices = await getTradeExecutionPriceUseCase.execute(input)
        setPriceError(false)
        formRef.current?.setFieldValue(outAmountKey, Number(prices?.outputAmount.toSignificant() ?? '0'))
      } catch (e) {
        console.error(e)
        formRef.current?.setFieldValue(outAmountKey, 0)
        setPriceError(true)
      } finally {
        setLoadingAssetPrice(false)
      }
    } else {
      formRef.current?.setFieldValue(outAmountKey, 0)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAssetPrices = useCallback(debounce(_getAssetPrices, 300), [])

  const getSwappableTokenNameByAddress = (address: string) => {
    return asset.swappableTokens.find((token) => token.address === address)?.name ?? '---'
  }

  const getInputOutputToken = (selectedTokenAddress: string) => {
    const selectedToken = getSwappableTokenNameByAddress(selectedTokenAddress)
    const assetToken = asset.tokenSymbol.toUpperCase()
    if (operationType === 'buy') {
      return { inputToken: selectedToken, outputToken: assetToken }
    } else {
      return { inputToken: assetToken, outputToken: selectedToken }
    }
  }

  const handleCloseDialog = () => {
    dispatch(closeDialog())
  }

  const handleSubmit = async (values: FormValues) => {
    if (priceError) return
    try {

      dispatch(setLoader(true))
      const outAmountKey = operationType === 'buy' ? 'assetTokenAmount' : 'tokenAmount'

      const input: SwapWithoutWalletUseCaseInput = {
        tokenIn: operationType === 'buy' ? values.selectedToken : asset?.tokenAddress,
        tokenOut: operationType === 'buy' ? asset?.tokenAddress : values.selectedToken,
        amount_in: operationType === 'buy' ? values.tokenAmount : values.assetTokenAmount,
        amount_out_min: UniswapService.calculateMinOut(values[outAmountKey]),
        fee: asset.uniswap_fee
      }
      const result = await new SwapWithoutWalletUseCase().execute(input)
      if (result === 'OK') {
        dispatch(closeDialog())
        dispatch(openSnackbar({ message: () => <div>
            {t('assets.swap.swapOk')}
            <ViewEtherscanButton tokenAddress={currentuserWalletAddress} />
          </div>
        }))
      }
      else {
        dispatch(openSnackbar({ message: t('assets.swap.swapKo') }))
      }
      dispatch(setLoader(false))

    } catch (e) {
      console.error(e)
      dispatch(openSnackbar({ message: t('assets.swap.swapKo') }))
      dispatch(setLoader(false))
    }
  }

  const handleTokenAmountChange = (amount: string) => {
    const amountBig = new BigNumber(amount)
    const amountKey = operationType === 'buy' ? 'tokenAmount' : 'assetTokenAmount'
    const outAmountKey = operationType === 'buy' ? 'assetTokenAmount' : 'tokenAmount'

    formRef.current?.setFieldValue(amountKey, amount)

    getAssetPrices(amountBig, outAmountKey)
  }

  const handleSelectedTokenChange = (e: ChangeEvent<HTMLInputElement>) => {
    formRef.current?.handleChange(e)
    formRef.current?.setFieldValue('tokenAmount', 0)
    formRef.current?.setFieldValue('assetTokenAmount', 0)
  }

  const tokensFormRow = ({ errors, touched, values, handleChange }: FormikProps<FormValues>) => (
    <Grid item container spacing={1} alignItems="center" justify="flex-start">
      <Grid item xs={8}>
        <TextField
          autoFocus={operationType === 'buy'}
          id="tokenAmount"
          required
          variant="outlined"
          fullWidth
          type="number"
          onChange={(e) => handleTokenAmountChange(e.target.value)}
          value={values.tokenAmount}
          error={Boolean(touched.tokenAmount) && Boolean(errors.tokenAmount)}
          helperText={operationType === 'buy' ? "Balance: " + usdcBalance + ' (Max)' : ' '}
          InputProps={{
            readOnly: operationType === 'sell',
            endAdornment: loadingAssetPrice && operationType === 'sell' && (
              <InputAdornment position="start">
                <CircularProgress size={20} color="inherit" />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          select
          name="selectedToken"
          fullWidth
          value={values.selectedToken}
          onChange={handleSelectedTokenChange}
          variant="outlined"
          error={Boolean(touched.selectedToken) && Boolean(errors.selectedToken)}
          helperText={' '}>
          {asset.swappableTokens?.map((option) => (
            <MenuItem key={option.address} value={option.address}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )

  const assetTokensFromRow = ({ errors, touched, values }: FormikProps<FormValues>) => (
    <Grid container item xs>
      <TextField
        autoFocus={operationType === 'sell'}
        variant="outlined"
        type="number"
        fullWidth
        onChange={(e) => handleTokenAmountChange(e.target.value)}
        value={values.assetTokenAmount}
        error={Boolean(touched.assetTokenAmount) && Boolean(errors.assetTokenAmount)}
        helperText={Boolean(touched.assetTokenAmount) && Boolean(errors.assetTokenAmount) && errors.assetTokenAmount}
        InputProps={{
          readOnly: operationType === 'buy',
          endAdornment: (
            <InputAdornment position="end">
              {loadingAssetPrice && operationType === 'buy' && <CircularProgress size={20} color="inherit" style={{ marginRight: 10 }} />}
              {asset.tokenSymbol.toUpperCase()}
            </InputAdornment>
          )
        }}
      />
    </Grid>
  )

  return (
    <Formik
      innerRef={formRef}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={operationType === 'buy' ? buyValidationSchema : sellValidationSchema}
      enableReinitialize>
      {(formikProps: FormikProps<FormValues>) => {
        return (
          <>
            <DialogTitle id="form-dialog-title">{t('assets.swap.title', getInputOutputToken(formikProps.values.selectedToken))}</DialogTitle>
            <AglaiaDialogContent>
              <Form noValidate>
                <Grid container spacing={2} direction="column" justify="flex-start" alignItems="flex-start">
                  {operationType === 'buy' ? tokensFormRow(formikProps) : assetTokensFromRow(formikProps)}
                  <Grid item xs container justify="center" alignItems="flex-start">
                    <ArrowDownwardIcon />
                  </Grid>
                  {operationType === 'buy' ? assetTokensFromRow(formikProps) : tokensFormRow(formikProps)}
                </Grid>
              </Form>
              {priceError && (
                <div className={classes.priceError}>
                  <Typography component="small" variant="body2" color="error">
                    {t('assets.swap.priceError')}
                  </Typography>
                </div>
              )}
            </AglaiaDialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleCloseDialog}>
                {t('assets.swap.cancelBtn')}
              </Button>
              <Button color="primary" onClick={() => formRef.current?.submitForm()} disabled={priceError}>
                {t('assets.swap.submitBtn')}
              </Button>
            </DialogActions>
          </>
        )
      }}
    </Formik>
  )
}
