import * as yup from 'yup'
import type { AnyObject, Maybe } from 'yup/lib/types'
import BigNumber from 'bignumber.js'

yup.addMethod<yup.StringSchema>(yup.string, 'emptyAsUndefined', function () {
  return this.transform((value?: string) => (value ? value : undefined))
})

yup.addMethod<yup.StringSchema>(yup.string, 'hex', function (message: string) {
  return this.test('hex', message, (value?: string) => {
    return /^(0x|0X)?[a-fA-F0-9]+$/.test(value ?? '')
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'moreThan', function (moreThan: number, message: string) {
  return this.test('moreThan', message, (value?: string) => {
    if (!value) return false
    const valueBig = new BigNumber(value)
    const moreBig = new BigNumber(moreThan)
    return valueBig.isGreaterThan(moreBig)
  })
})

yup.addMethod<yup.NumberSchema>(yup.number, 'emptyAsUndefined', function () {
  return this.transform((value: string, originalValue?: string) => (originalValue?.trim() ? value : undefined))
})

declare module 'yup' {
  interface StringSchema<TType extends Maybe<string> = string | undefined, TContext extends AnyObject = AnyObject, TOut extends TType = TType>
    extends yup.BaseSchema<TType, TContext, TOut> {
    emptyAsUndefined(): StringSchema<TType, TContext>
    hex(message: string): StringSchema<TType, TContext>
    moreThan(moreThan: number | string, message: string): StringSchema<TType, TContext>
  }

  interface NumberSchema<TType extends Maybe<number> = number | undefined, TContext extends AnyObject = AnyObject, TOut extends TType = TType>
    extends yup.BaseSchema<TType, TContext, TOut> {
    emptyAsUndefined(): NumberSchema<TType, TContext>
  }
}

export default yup
