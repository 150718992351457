import { Expose, Type } from 'class-transformer'
import { Client } from '../../../clients/domain/Client'

export class User {
  @Expose()
  id!: string
  @Expose()
  email!: string
  @Expose()
  addressChanged!: boolean
  @Expose()
  ethPublicAddress!: string
  @Expose({ name: 'first_name' })
  firstName!: string
  @Expose({ name: 'last_name' })
  lastName!: string
  @Expose({ name: 'is_active' })
  isActive!: boolean
  @Expose({ name: 'is_superuser' })
  isSuperUser!: boolean
  @Expose()
  @Type(() => Client)
  client!: Client | null
  @Expose()
  is_validated!: boolean
  @Expose()
  verification_url!: string
  @Expose()
  docs_verification_url!: string
  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }
}
