import React, { forwardRef } from 'react'
import { SnackbarState } from '../../store/ui/ui.types'
import { useAppDispatch } from '../../store/store'
import { SnackbarContent, SnackbarKey } from 'notistack'
import { closeSnackbar } from '../../store/ui/ui.slice'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import useStyles from './AppSnackbar.styles'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function CloseAction({ snackbarKey }: { snackbarKey: SnackbarKey }) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  return (
    <Button size="small" onClick={() => dispatch(closeSnackbar(snackbarKey))}>
      {t('snackbar.closeBtn')}
    </Button>
  )
}

export const AppSnackbar = forwardRef<HTMLDivElement, SnackbarState>((props, ref) => {
  const { snackbarKey, severity, action, message, loading, noClose } = props
  const dispatch = useAppDispatch()
  const classes = useStyles(props)

  const mergeActions = () => {
    const CustomAction = action as React.ComponentType
    return (
      <div>
        {!noClose && <CloseAction snackbarKey={snackbarKey} />}
        <CustomAction />
      </div>
    )
  }

  const renderMessage = () => {
    if (typeof message === 'string') {
      return message
    } else {
      const CustomMessage = message as React.ComponentType
      return <CustomMessage/>
    }
  }

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Alert severity={severity} action={action ? mergeActions() : null} onClose={!noClose ? () => dispatch(closeSnackbar(snackbarKey)) : undefined}>
        <Grid container alignItems="center" justify="flex-start">
          {renderMessage()}
          {loading && <CircularProgress size={20} className={classes.circularProgress} classes={{ colorPrimary: classes.circularProgressColor }} />}
        </Grid>
      </Alert>
    </SnackbarContent>
  )
})
