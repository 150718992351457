import { AppRoute, RouteGuard } from '../../../../common/view/router/config'
import type * as H from 'history'
import store from '../../../../common/view/store/store'
import { setUser } from '../../../../user/view/store/user.slice'
import { RedirectConfig } from '../../../../common/view/router/RedirectConfig'
import { LoginLocationState } from './LoginGuard'
import { InitAppUseCase } from '../../../../common/domain/use-cases/InitAppUseCase'

export class AuthGuard implements RouteGuard {
  async execute(route: AppRoute, location: H.Location<any>, query: URLSearchParams): Promise<boolean | string | RedirectConfig<LoginLocationState>> {
    if (store.getState().user.profile) {
      return true
    }
    try {
      const profile = await InitAppUseCase.execute()
      store.dispatch(setUser(profile))
      return true
    } catch (e) {
      console.warn(e)
      return new RedirectConfig({ pathname: '/login', state: { noCheck: true } })
    }
  }
}
