import React, { useEffect } from 'react'
import './App.scss'
import { HashRouter as Router, Switch } from 'react-router-dom'
import { appRoutes } from './common/view/router/routes'
import { RouteWithSubRoutes } from './common/view/router/config'
import { ThemeProvider } from '@material-ui/core/styles'
import createTheme from './theme'
import CssBaseline from '@material-ui/core/CssBaseline'
import { AppSnackbarNotifier } from './common/view/components/app-snackbar/AppSnackbarNotifier'
import AppLoader from './common/view/components/app-loader/AppLoader'
import AppDialog from './common/view/components/app-dialog/AppDialog'
import { SnackbarProvider } from 'notistack'

function App() {
  const theme = React.useMemo(() => createTheme(/*currentTheme === 'DARK'*/ false), [])

  useEffect(() => {
    window.history.replaceState({}, document.title)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <div className="App">
          <Router>
            <Switch>
              {appRoutes.map((route, idx) => (
                <RouteWithSubRoutes key={idx} {...route} />
              ))}
            </Switch>
          </Router>
          <AppSnackbarNotifier />
          <AppLoader />
          <AppDialog />
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
