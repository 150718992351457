import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles'
import { TableCell, withStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  return {
    missing: {
      display: 'flex',
      alignItems: 'center',
    },
    address: {
      display: 'flex',
      alignItems: 'center'
    },
    container: {
      flex: 1,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },
    titleDivider: {
      margin: theme.spacing(2, 0, 2, 0)
    },
    tableWrapper: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '20px',
    },
    table: {
      height: '65vh',
      [theme.breakpoints.down('sm')]: {
        flex: 1,
      }
    },
    tablePagination: {
      width: '100%',
    },
    tableEmptyRow: {
      height: '68vh',
      [theme.breakpoints.up('sm')]: {
        height: '76vh'
      },
      '& h6': {
        color: fade(theme.palette.text.primary, 0.3)
      }
    },
    tableEmptyRowIcon: {
      fontSize: 60,
      color: fade(theme.palette.text.primary, 0.3)
    },
    copyTokenAddressTooltip: {
      fontSize: 13
    },
    nameWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    pointer: {
      cursor: 'pointer'
    }
  }
})

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white
    },
    body: {
      fontSize: 14,
      marginRight: 20
    }
  })
)(TableCell)

export default useStyles
