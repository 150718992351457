import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import useStyles from './PrivacyPage.style';

import { AppHeaderSimpleNav } from '../app-header-nav/AppHeaderNav';
import React from "react";

const PrivacyPage = () => {
  const classes = useStyles();
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <AppHeaderSimpleNav />
      <div className={classes.content}>
        <Typography variant="h5" className={classes.mainHeading}>
          <b>{t('privacy.title')}</b>
        </Typography>

        <div className={classes.mainText} dangerouslySetInnerHTML={{__html: t('privacy.maintext') || ''}}/>
        <br/><br/>
        <Typography variant="subtitle1" align="center">
          <b>{t('copyright')}</b>
        </Typography>
      </div>
    </div>
  )
}

export default PrivacyPage;
