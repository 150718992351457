import { fade, lighten, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return {
    loadingPositionRoot: {
      height: '100px',
    },
    assetAvatar: {
      height: 50,
      width: 50,
    },
    titleWrapper: {
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%'
    },

    positionBalance: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      '& > span': {
        color: theme.palette.text.secondary
      },
      '& > h6': {
        color: theme.palette.text.primary
      },
    },
    positionBalanceEur: {
      marginLeft: 40,
      width: '150px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      '& > span': {
        color: theme.palette.text.secondary
      },
    },
    walletSection: {
      display: 'flex',
      flexDirection: 'column',
      width: '270px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(2)
      }
    },
    wallet: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }
    },
    walletHeading: {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center'
      }
    },
    qrContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '15px'
    },
    coinsContainer: {
      display: 'flex',
    },
    liquidityAvatar: {
      width: '40px'
    },
    positionContainer: {
      marginTop: theme.spacing(6),
    },
    liquidityContainer: {
      marginTop: theme.spacing(6),
    },
    positionData: {
      display: 'flex',
      width: '100%'
    },
    positionList: {
      width: '100%',
      '& > a': {
        color: theme.palette.text.primary
      },
    },
    positionItemRoot: {
      '&:hover': {
        backgroundColor: fade(lighten(theme.palette.background.default, 0.5), 0.25)
      }
    },
    positionItemText: {
      color: theme.palette.text.primary
    },
    tokenWrapper: {
      display: 'flex',
      alignitems: 'center'
    },
    tokenName: {
      margin: '5px 0 0 10px'
    },
    liquidityActions: {
      display: 'flex',
    },
    container: {
      display: 'flex',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    content: {
      display: 'flex',
      flex: 1,
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'flex-start'
    },
    table: {
      flex: 1,
      display: 'flex',
      marginTop: '6px'
    },
    coinTitle: {
      marginLeft: 14,
      marginBottom: theme.spacing(1),
    },
    assetButton: {

    },
    coinBalance: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    tableHeader: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(2),
    }
  }
})

export default useStyles
