import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, Tooltip } from '@material-ui/core'
import { FileCopy as FileCopyIcon, AttachFile as AttachFileIcon, GetApp as DownloadIcon } from '@material-ui/icons'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import useStyles from './AssetDetailPage.style'
import { FixedNumber } from 'ethers'

import { Asset } from '../../../domain/models/Asset'
import { AssetsRepository } from '../../../data/AssetsRepository'

import { CancelablePromise, makeCancelable, timeoutPromise } from '../../../../common/utils/promise'
import { downloadURI } from '../../../../common/utils/url'
import { copyTextToClipboard, getLastPath } from '../../../../common/utils/functions'
import { CalculateBalances } from '../../../../common/utils/CalculateBalances';

import { useAppDispatch } from '../../../../common/view/store/store'
import { setLoader } from '../../../../common/view/store/ui/ui.slice'
import { GetAssetAmountDataUseCase } from '../../../domain/use-cases/GetAssetAmountDataUseCase'
import {
  getUserClientId,
  getUserClientWalletAddress,
} from '../../../../user/view/store/user.selectors'

import EmptyState from '../../../../common/view/components/empty-state/EmptyState'
import { CoinBalance } from '../../../../balance/domain/models/CoinBalance'
import { GetCoinBalanceUseCase } from '../../../../balance/domain/use-cases/GetCoinBalanceUseCase'
import { useIsMounted } from '../../../../common/view/hooks/IsMounted.hook'
import { GetAssetPausedUseCase } from '../../../domain/use-cases/GetAssetPausedUseCase'
import AssetDetailPageActions from './AssetDetailPageActions'
import AddTokenButton from '../../../../eth/view/components/add-token-button/AddTokenButton'
import ViewEtherscanButton from '../../../../eth/view/components/view-etherscan-button/ViewEtherscanButton';

export default function AssetDetailPage() {
  // Deps
  const isMounted = useIsMounted()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const userClientId = useSelector(getUserClientId)
  const clientAddress = useSelector(getUserClientWalletAddress) || ''
  const [openCopyTooltip, setOpenCopyTooltip] = useState(false)
  let getAssetDetailPromise = useRef<CancelablePromise<Asset> | null>(null)
  const balancePromise = useRef<CancelablePromise<CoinBalance>>()
  const getAssetAmountDataUseCase = useMemo(() => new GetAssetAmountDataUseCase(), [])
  const getAssetPausedUseCase = useMemo(() => new GetAssetPausedUseCase(), [])

  // State
  const [asset, setAsset] = useState<Asset | null>(null)
  const [isOwner, setIsOwner] = useState(false)
  const [usdcBalance, setUsdcBalance] = useState<FixedNumber | null>(null)
  // Methods
  const getInitialData = useCallback(async () => {
    if (!isMounted) return

    dispatch(setLoader(true))
    getAssetDetailPromise.current = makeCancelable<Asset>(AssetsRepository.detail(id))
    balancePromise.current = makeCancelable<CoinBalance>(GetCoinBalanceUseCase.execute(clientAddress))
    try {
      const [assetResponse, { usdc }] = await Promise.all([getAssetDetailPromise.current?.promise, balancePromise.current?.promise])
      setUsdcBalance(usdc)
      setAsset(assetResponse)
      const _asset = Object.assign({}, assetResponse)
      _asset.amountData = await getAssetAmountDataUseCase.execute(assetResponse, clientAddress)
      _asset.paused = await getAssetPausedUseCase.execute(assetResponse)
      setAsset(_asset)
      // receive issuer & profitability content
      setIsOwner(_asset.owner === userClientId)
      // Check if has sufficient amount to buy
    } catch (e) {
      dispatch(setLoader(false))
    } finally {
      dispatch(setLoader(false))
    }
  }, [dispatch, getAssetAmountDataUseCase, id, isMounted, userClientId, clientAddress, getAssetPausedUseCase])

  const handleAddressCopy = async () => {
    await copyTextToClipboard(asset?.tokenAddress ?? '')
    setOpenCopyTooltip(true)
    await timeoutPromise(3000)
    setOpenCopyTooltip(false)
  }

  const downloadFile = async (fileUrl: string) => {
    await downloadURI(fileUrl, getLastPath(fileUrl).split('.')[0])
  }

  useEffect(() => {
    void getInitialData()
    return () => {
      getAssetDetailPromise.current?.cancel()
    }
  }, [getInitialData])

  const { privateBalance, presaleBalance, totalBalance, totalSupply, USDCValue } = CalculateBalances(asset);

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.actions}>
          <div className={classes.assetWrapper}>
            <div className={classes.nameWrapper}>
              <Typography variant="h5">
                <b>{asset?.tokenName}</b>
              </Typography>
              <Typography variant="h6">
                <b>{asset?.tokenSymbol.toUpperCase()}</b>
              </Typography>
              <Typography variant="body2" display="block" noWrap>
                {t('balance.walletAddress')}
              </Typography>
            </div>
          </div>
          <div className={classes.assetButtonsWrapper}>
            {asset && (
              <AssetDetailPageActions asset={asset} isOwner={isOwner} usdcBalance={usdcBalance} refreshAssetData={getInitialData} />
              )}
          </div>
        </div>
        <div className={classes.tokenInfo}>
          <div className={classes.tokenInfoRight}>
            <div className={classes.nameAndLogoContainer}>
              <Avatar className={classes.logo} src={asset?.logo} alt={'logo'}/>
              <div className={classes.nameContainer}>
                <Typography variant="body1" color="textSecondary">
                  {t('assets.detail.tokenName')}
                </Typography>
                <Typography variant="h6">
                  <b>{asset?.tokenName}</b>
                </Typography>
              </div>
            </div>
            <Typography variant="body1" color="textSecondary" className={classes.textMarginTop}>
              {t('assets.detail.tokenAddress')}
              <Tooltip
                open={openCopyTooltip}
                title={`${t('assets.detail.tokenAddressCopied')}`}
                placement="top"
                classes={{ tooltip: classes.copyTokenAddressTooltip }}>
                <IconButton onClick={handleAddressCopy} color="primary">
                  <FileCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              {asset && <ViewEtherscanButton tokenAddress={asset?.tokenAddress} />}
              {asset && <AddTokenButton tokenAddress={asset?.tokenAddress} tokenSymbol={asset?.tokenSymbol} tokenImage={asset?.logo} />}
            </Typography>
            <div className={classes.addressContainer}>
              <Typography variant="body1" noWrap>
                {asset?.tokenAddress}
              </Typography>
            </div>
            <Typography variant="body2" color="textSecondary">
              {t('assets.detail.totalSupply')}
            </Typography>
            <Typography variant="body1">
              <b>{totalSupply}</b>
            </Typography>
            <Typography variant="body2" color="textSecondary" className={classes.textMarginTop}>
              {`${t('assets.detail.price')}: `}
            </Typography>
            <Typography variant="body1">
              <b>{USDCValue? USDCValue : '---'} USDC</b>
            </Typography>
          </div>
          <div className={classes.tokenNumbers}>
            <Typography variant="body2" color="textSecondary" className={classes.textMarginTop}>
              {t('assets.detail.presaleBalance')}
            </Typography>
            <Typography variant="body1">
              <b>{presaleBalance}</b>
            </Typography>

            <Typography variant="body2" color="textSecondary" className={classes.textMarginTop}>
              {t('assets.detail.privateBalance')}
            </Typography>
            <Typography variant="body1">
              <b>{privateBalance}</b>
            </Typography>

            <Typography variant="body2" color="textSecondary" className={classes.textMarginTop}>
              {t('assets.detail.totalBalance')}
            </Typography>
            <Typography variant="body1">
              <b>{totalBalance}</b>
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.bottom}>
        <Grid container direction="column" spacing={4} wrap="nowrap" justify="flex-start" alignItems="flex-start">
          <Grid item xs>
            <Typography className={classes.filesTitle} variant="h5">
              <b>{t('assets.detail.files')}</b>
            </Typography>
            {Boolean(asset?.files.length) ? (
              <List>
                {asset?.files?.map((file) => {
                  return (
                    <React.Fragment key={file.id}>
                      <Divider component="li" />
                      <ListItem dense>
                        <ListItemIcon>
                          <AttachFileIcon style={{ fontSize: 20 }} />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body2" noWrap>
                            {getLastPath(file.file)}
                          </Typography>
                        </ListItemText>
                        <IconButton onClick={() => downloadFile(file.file)}>
                          <DownloadIcon style={{ fontSize: 20 }} />
                        </IconButton>
                      </ListItem>
                    </React.Fragment>
                  )
                })}
              </List>
            ) : (
              <EmptyState height={140} icon={AttachFileIcon} iconSize={35} fontVariant="h6" message={`${t('assets.detail.filesEmpty')}`} />
            )}
          </Grid>
        </Grid>
        <Accordion allowMultipleExpanded allowZeroExpanded>
          <AccordionItem uuid="description" className={classes.accordionItem}>
            <AccordionItemHeading>
              <AccordionItemButton className={classes.accordionButton}>
                <Typography className={classes.descriptionSubtitle} variant="body1" color="textSecondary">
                  <b>{t('assets.detail.description')}</b>
                </Typography>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={classes.accordionContent}>
              <Typography variant="body1">{asset?.description}</Typography>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="issuer" className={classes.accordionItem}>
            <AccordionItemHeading>
              <AccordionItemButton className={classes.accordionButton}>
                <Typography className={classes.descriptionSubtitle} variant="body1" color="textSecondary">
                  <b>{t('assets.detail.issuer')}</b>
                </Typography>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={classes.accordionContent}>
              <Typography variant="body1">{asset?.issuer}</Typography>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="profitability" className={classes.accordionItem}>
            <AccordionItemHeading>
              <AccordionItemButton className={classes.accordionButton}>
                <Typography className={classes.descriptionSubtitle} variant="body1" color="textSecondary">
                  <b>{t('assets.detail.profitability')}</b>
                </Typography>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={classes.accordionContent}>
              <Typography variant="body1">{asset?.profitability}</Typography>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  )
}
