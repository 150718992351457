import { AppRoute } from './config'
import { authRoutes } from '../../../auth/view/router/routes'
import AppDashboard from '../components/app-dashboard/AppDashboard'
import { AuthGuard } from '../../../auth/view/router/guards/AuthGuard'
import { ownerRoutes, userRoutes } from '../../../user/view/router/routes'
import { clientRoutes } from '../../../clients/view/router/routes'
import { assetRoutes } from '../../../assets/view/router/routes'
import { positionRoutes } from '../../../balance/view/router/routes'
import ClosePage from '../components/close-page/ClosePage'
import PrivacyPage from '../components/privacy-page/PrivacyPage';
import TermsPage from "../components/terms-page/TermsPage";
import DisclaimerPage from "../components/disclaimer-page/DisclaimerPage";
import QualifiedInvestorPage from "../components/qualified-investor-page/QualifiedInvestorPage";
import CookiesPage from "../components/cookies-page/CookiesPage";

export const appRoutes: Array<AppRoute> = [
  {
    path: '/',
    exact: true,
    redirect: '/login'
  },
  ...authRoutes,
  ...userRoutes,
  {
    path: '/u',
    component: AppDashboard,
    guard: new AuthGuard(),
    routes: [...clientRoutes, ...assetRoutes, ...positionRoutes, ...ownerRoutes]
  },
  {
    path: '/close',
    component: ClosePage
  },
  {
    path: '/terms-and-conditions',
    component: TermsPage
  },
  {
    path: '/privacy-policy',
    component: PrivacyPage
  },
  {
    path: '/cookies-policy',
    component: CookiesPage
  },
  {
    path: '/disclaimer',
    component: DisclaimerPage
  },
  {
    path: '/qualified-investor-requirements',
    component: QualifiedInvestorPage
  }
]
