import { useSelector } from 'react-redux'
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  CircularProgress,
  Button
} from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'

import useStyles from './PositionPage.styles'

import {openDialog, setLoader} from '../../../../common/view/store/ui/ui.slice'
import { Asset } from '../../../../assets/domain/models/Asset'
import { Client } from '../../../../clients/domain/Client'
import { GetCoinBalanceUseCase } from '../../../domain/use-cases/GetCoinBalanceUseCase'
import { CoinBalance } from '../../../domain/models/CoinBalance'
import { ExchangeRateToEur } from '../../../domain/models/ExchangeRateToEur'
import { CancelablePromise, makeCancelable } from '../../../../common/utils/promise'
import { GetTokenPositionsUseCase } from '../../../domain/use-cases/GetTokenPositionsUseCase'
import RenderLink from '../../../../common/view/components/render-link/RenderLink'
import EmptyState from '../../../../common/view/components/empty-state/EmptyState'
import CryptoAddress from '../../../../common/view/components/crypto-address/CryptoAddress'
import {
  getUserClientCode,
  getUserClientWalletAddress, getUserSuperUserStatus
} from '../../../../user/view/store/user.selectors'
import {CalculateBalances, converToThousands} from '../../../../common/utils/CalculateBalances';
import QrCode from '../qr-code/QrCode';

import { ReactComponent as ETHLogo } from '../../../../common/assets/crypto/eth.svg'
import { ReactComponent as USDCLogo } from '../../../../common/assets/crypto/usdc.svg'
import {useParams} from "react-router-dom";
import {useIsMounted} from "../../../../common/view/hooks/IsMounted.hook";
import {useAppDispatch} from "../../../../common/view/store/store";
  import {ClientsRepository} from "../../../../clients/data/ClientsRepository";
import AddIcon from "@material-ui/icons/Add";
import {AddPositionDialog} from "../add-position-dialog/AddPositionDialog";
import {TransferDialog} from "../transfer-dialog/TransferDialog";
import {Liquidity} from "../../../../assets/domain/models/Liquidity";
import {GetLiquidityUseCase} from "../../../domain/use-cases/GetLiquidityUseCase";
import {TransferLiquidityDialog} from "../transfer-liquidity-dialog/TransferLiquidityDialog";

const PositionPage = () => {
  // Deps
  const classes = useStyles()
  const isMounted = useIsMounted()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const getClientDetailPromise = useRef<CancelablePromise<Client> | null>(null)
  const balancePromise = useRef<CancelablePromise<CoinBalance>>()
  const isSuperUser = useSelector(getUserSuperUserStatus)
  const positionPromise = useRef<CancelablePromise<Array<Asset>>>()
  const liquidityPromise = useRef<CancelablePromise<Array<Liquidity>>>()
  const currentuserWalletAddress = useSelector(getUserClientWalletAddress) || ''
  const currentuserClientCode = useSelector(getUserClientCode) || ''

  // State
  const [loadingPosition, setLoadingPosition] = useState(false)
  const [loadingLiquidity, setLoadingLiquidity] = useState(false)
  const [currentClient, setCurrentClient] = useState<Client | null>(null)
  const [userWalletAddress, setUserWalletAddress] = useState('')
  const [liquidity, setLiquidity] = useState<Array<Liquidity>>([])
  const [position, setPosition] = useState<Array<Asset>>([])
  const [coinBalance, setCoinBalance] = useState<CoinBalance>({ usdc: null, eth: null })
  const [exchangeRates, setExchangeRates] = useState<ExchangeRateToEur>({ usdc: 0, eth: 0 })

  // Methods
  const fetchBalance = useCallback(() => {
    balancePromise.current = makeCancelable<CoinBalance>(GetCoinBalanceUseCase.execute(userWalletAddress))
    balancePromise.current?.promise.then(async (balance) => {
      setCoinBalance(balance)
    })
  }, [userWalletAddress])

  const fetchPosition = useCallback(async () => {
    setLoadingPosition(true)
    positionPromise.current = makeCancelable<Array<Asset>>(new GetTokenPositionsUseCase().execute(userWalletAddress))
    positionPromise.current?.promise
      .then((position) => {
        setPosition(position)
      })
      .finally(() => {
        setLoadingPosition(false)
      })
      .catch((e) => console.log(' ****** error', e))
  }, [userWalletAddress])

  const fetchLiquidity = useCallback(async () => {
    setLoadingLiquidity(true)
    liquidityPromise.current = makeCancelable<Array<Liquidity>>(new GetLiquidityUseCase().execute(userWalletAddress))
    liquidityPromise.current?.promise
      .then((liquidity) => {
        setLiquidity(liquidity)
      })
      .finally(() => {
        setLoadingLiquidity(false)
      })
      .catch((e) => console.log(' ****** error', e))
  }, [userWalletAddress])

  const getInitialData = useCallback(async () => {
    if (!isMounted) return

    dispatch(setLoader(true))
    try {
      if(id) {
        getClientDetailPromise.current = makeCancelable<Client>(ClientsRepository.get(Number(id)))
        const [clientResponse] = await Promise.all([getClientDetailPromise.current?.promise])
        setCurrentClient(clientResponse)
        setUserWalletAddress(clientResponse.ethPublicAddress)
      }
      else {
        setUserWalletAddress(currentuserWalletAddress)
      }

    } catch (e) {
      dispatch(setLoader(false))
    } finally {
      dispatch(setLoader(false))
    }
  }, [dispatch, getClientDetailPromise, id, isMounted, currentuserWalletAddress])

  useEffect(() => {
    void getInitialData()
    return () => {
      //getAssetDetailPromise.current?.cancel()
    }
  }, [getInitialData])

  useEffect(() => {
    fetchBalance()
    fetchPosition()
    if (isSuperUser) fetchLiquidity()

    return () => {
      balancePromise?.current?.cancel()
      positionPromise?.current?.cancel()
    }
  }, [fetchBalance, fetchPosition, isSuperUser, fetchLiquidity])

  useEffect(() => {
    const fetchExchangeRates = async () => {
      let exchangeRates = {
        usdc: 0,
        eth: 0
      }

      const fetchExchangeRate = async (token: string) => {
        let res = await fetch(`https://api.coinbase.com/v2/exchange-rates?currency=${token.toUpperCase()}`)
        const resJson: { data: any } = await res.json()
        if (resJson?.data?.rates?.EUR) {
          const { EUR } = resJson.data.rates
          token === 'usdc' ? exchangeRates.usdc = Number(EUR) : exchangeRates.eth = Number(EUR);
        }
      }

      const promises = Object.keys(exchangeRates).map(async (token) => await fetchExchangeRate(token))

      await Promise.all(promises);

      setExchangeRates(exchangeRates)
    }
    fetchExchangeRates()
  }, [coinBalance])

  const openAddPositionDialog = () => {
    dispatch(
      openDialog({
        content: () => <AddPositionDialog bankAccount={"ESXX XXXX XXXX XX XXXXXXXXXX"} clientCode={currentuserClientCode? currentuserClientCode : ''} />,
        maxWidth: 'sm',
        fullWidth: true
      })
    )
  }

  const openAddTransferDialog = () => {
    dispatch(
      openDialog({
        content: () => <TransferDialog />,
        maxWidth: 'sm',
        fullWidth: true
      })
    )
  }

  const openTransferLiquidityDialog = (tokenId : number) => {
    dispatch(
      openDialog({
        content: () => <TransferLiquidityDialog tokenId={tokenId}/>,
        maxWidth: 'sm',
        fullWidth: true
      })
    )
  }

  const renderPosition = () => {
    if (!position.length) {
      return <EmptyState height="70vh" message={`${t('balance.empty')}`} icon={AccountBalanceWalletIcon} />
    }


    return <List className={classes.positionList}>
          {position.map((asset) => {
            const { totalBalance, totalSupply, USDCValue } = CalculateBalances(asset)
            const valueInEur = exchangeRates.usdc * (USDCValue ? Number(USDCValue) : 0);
            return (
              <React.Fragment key={asset.id}>
                <ListItem className={classes.positionItemRoot} component={RenderLink} to={`/u/assets/${asset.id}`}>
                  <ListItemAvatar>
                    <Avatar className={classes.assetAvatar} src={asset?.logo} />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.positionItemText }}
                    primary={`${asset.name} (${asset?.tokenSymbol?.toUpperCase() ?? '---'})`}
                    secondary={`${t('balance.totalSupply')} ${totalSupply}`}
                  />
                  <div className={classes.positionBalance}>
                    <Typography variant="caption">{t('balance.balance')}</Typography>
                    <Typography variant="body1">
                      <b>{totalBalance}</b>
                    </Typography>
                  </div>
                  <div className={classes.positionBalanceEur}>
                    <Typography variant="caption">{t('balance.eurValue')}</Typography>
                    <Typography variant="body1">
                      <b>{converToThousands(valueInEur?.toString())} €</b>
                    </Typography>
                  </div>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            )
          })}
        </List>
  }

  const renderLiquidity = () => {
    if (!liquidity.length) {
      return <EmptyState height="70vh" message={`${t('balance.empty')}`} icon={AccountBalanceWalletIcon} />
    }

    return <List className={classes.positionList}>
          {liquidity.map((asset) => {
            return (
              <React.Fragment key={asset.id}>
                <ListItem className={classes.positionItemRoot}>
                  <ListItemAvatar>
                    <img className={classes.liquidityAvatar} alt={'pool'} src={asset?.image} />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.positionItemText }}
                    primary={asset.name}
                  />
                  <div className={classes.liquidityActions}>
                    <Button target={"_blank"} href={`https://app.uniswap.org/#/pool/${asset.id}`}>View</Button>
                    <Button onClick={() => {openTransferLiquidityDialog(asset.id)}}>Withdraw</Button>
                  </div>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            )
          })}
        </List>
  }

  const renderLoading = () => {
    return (
      <Grid className={classes.loadingPositionRoot} container direction="column" justify="center" alignItems="center">
        <CircularProgress size={40} color="inherit" />
      </Grid>
    )
  }

  const renderWalletInfo = () => {
    return (
      <div className={classes.walletSection}>
        {currentClient &&
          <div>
            <Typography variant="h5" noWrap>
              <b>{currentClient?.user?.firstName + " " + currentClient?.user?.lastName}</b>
            </Typography>
          </div>
        }
        <div className={classes.wallet}>
          <div>
            <Typography variant="body2" display="block" noWrap className={classes.walletHeading}>
              {t('balance.walletAddress')}
            </Typography>

            <CryptoAddress address={userWalletAddress ?? ''} />
          </div>
          <div className={classes.qrContainer}>
            <QrCode size={125} walletAddress={userWalletAddress} />
          </div>
        </div>
      </div>
    )
  }

  const renderTokenBalance = () => {
    return <List className={classes.positionList}>
          {Object.entries(coinBalance).map(([token, balance]) => {
            const valueInEur = (token === 'usdc' ? exchangeRates.usdc :  exchangeRates.eth) * balance;
            return (
              <React.Fragment key={token}>
                <ListItem className={classes.positionItemRoot}>
                  <ListItemAvatar>
                    {token === 'eth' ? <ETHLogo /> : <USDCLogo />}
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: classes.positionItemText }}
                    primary={<b>{token === 'eth' ? 'Ethereum' : 'USD Coin'}</b>}
                  />
                  <div className={classes.positionBalance}>
                    <Typography variant="caption">{t('balance.balance')}</Typography>
                    <Typography variant="body1">
                      <b>
                        {!balance ? 0 : token === 'eth' ? balance.toString() : converToThousands(balance.toString()) || 0} {token.toUpperCase()}{' '}
                      </b>
                    </Typography>
                  </div>
                  <div className={classes.positionBalanceEur}>
                    <Typography variant="caption">{t('balance.eurValue')}</Typography>
                    <Typography variant="body1">
                      <b>{converToThousands(valueInEur.toFixed(4).toString())} €</b>
                    </Typography>
                  </div>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            )
          })}
        </List>
  }

  return (
    <div className={classes.container}>
      {renderWalletInfo()}
      <div className={classes.content}>
        <div className={classes.coinsContainer}>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <div className={classes.titleWrapper}>
              <Typography className={classes.coinTitle} variant="h5">
                <b>{t('balance.coins')}</b>
              </Typography>
              {currentuserClientCode &&
                <Button className={classes.assetButton} startIcon={<AddIcon />} variant="contained" color="primary" onClick={openAddPositionDialog}>
                  {t('balance.add')}
                </Button>
              }
              {!currentuserClientCode &&
                <Button className={classes.assetButton} variant="contained" color="primary" onClick={openAddTransferDialog}>
                  {t('balance.transferText')}
                </Button>
              }
            </div>
            {renderTokenBalance()}
          </Grid>
        </div>
        <div className={classes.positionContainer}>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <Typography className={classes.coinTitle} variant="h5">
              <b>{t('balance.tokens')}</b>
            </Typography>
            <div className={classes.positionData}>
              {loadingPosition ? renderLoading() : renderPosition()}
            </div>
          </Grid>
        </div>
        {isSuperUser &&
          <div className={classes.liquidityContainer}>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start">
              <Typography className={classes.coinTitle} variant="h5">
                <b>{t('balance.liquidity')}</b>
              </Typography>
              <div className={classes.positionData}>
                {loadingLiquidity ? renderLoading() : renderLiquidity()}
              </div>
            </Grid>
          </div>
        }
      </div>
    </div>
  )
}

export default PositionPage
