import {DialogContentText, DialogTitle} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {AglaiaDialogContent} from "../../../../common/view/components/app-dialog/AppDialog.styled";

interface Props {
  clientCode: string,
  bankAccount: string
}

export const AddPositionDialog = ({clientCode, bankAccount} : Props) => {
  const { t } = useTranslation()
  return (
    <>
      <DialogTitle>{t('balance.addTitle')}</DialogTitle>
      <AglaiaDialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('balance.addAccount') + bankAccount + t('balance.addAccount2') + clientCode}
        </DialogContentText>
      </AglaiaDialogContent>
    </>
  )
}
