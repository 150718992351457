import { Asset } from '../../../domain/models/Asset'
import React, { useState } from 'react'
import { Button, Grid, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { OperationType } from '../../../../eth/domain/models/OperationType'
import {openDialog, setLoader} from '../../../../common/view/store/ui/ui.slice'
import { SwapDialog } from '../swap-dialog/SwapDialog'
import { useAppDispatch } from '../../../../common/view/store/store'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    getUserClientAddressChanged,
    getUserClientCode,
    getUserSuperUserStatus,
    getUserValidatedStatus
} from '../../../../user/view/store/user.selectors'
import useStyles from './AssetDetailPageActions.style'
import { MintAssetDialog } from '../mint-assset-dialog/MintAssetDialog'
import { PauseAssetDialog } from '../pause-assset-dialog/PauseAssetDialog'
import {UnpauseAssetDialog } from '../unpause-assset-dialog/UnpauseAssetDialog'
import { EditAssetDialog } from '../edit-asset-dialog/EditAssetDialog';
import {FixedNumber} from "ethers";
import {AddPositionDialog} from "../../../../balance/view/components/add-position-dialog/AddPositionDialog";
import {ValidateDialog} from "../../../../user/view/components/validate-dialog/ValidateDialog";
import {AssetsRepository} from "../../../data/AssetsRepository";

interface Props {
  asset: Asset
  isOwner: boolean
  refreshAssetData: () => void
  usdcBalance: FixedNumber | null
}

const AssetDetailPageActions = ({ asset, refreshAssetData, isOwner, usdcBalance }: Props) => {
  // Deps
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isSuperUser = useSelector(getUserSuperUserStatus)
  const isNotGuardian = useSelector(getUserClientAddressChanged)
  const isValidated = useSelector(getUserValidatedStatus)
  const currentuserClientCode = useSelector(getUserClientCode) || ''

  // State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // Methods
  const handleLiquidityMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseLiquidityMenu = () => {
    setAnchorEl(null)
  }

  const disableBuyLiteral = (): string => {
    if (!isValidated) return `${t('appDashboard.validationUser')}`;
    return !isNotGuardian ? `${t('assets.detail.noFundsBuyGuardian')}` : `${t('assets.detail.noFundsBuyNoGuardian')}`
  }

  const handleAssetSwap = (operationType: OperationType) => {
      dispatch(
        openDialog({
          content: () => {
              return !usdcBalance?.isZero() ?
                  <SwapDialog asset={asset} onSuccess={refreshAssetData} usdcBalance={usdcBalance} operationType={operationType} /> :
                  <AddPositionDialog bankAccount={asset.bank_account} clientCode={currentuserClientCode? currentuserClientCode : ''} />
          },
          maxWidth: 'sm',
          fullWidth: true
        })
      )
  }

  const handleSign = async () => {
      const onFinish = async () => {
        refreshAssetData()
      }
      dispatch(setLoader(true))
      const url = await AssetsRepository.sign(asset.id || '')
      dispatch(setLoader(false))

      dispatch(
        openDialog({
          content: () => <ValidateDialog closable={false} url={url} onFinish={onFinish} />,
          maxWidth: 'xl',
          fullWidth: true,
          blocking: true
        })
      )
  }

  const openMintModal = () => {
    dispatch(
      openDialog({
        content: () => <MintAssetDialog ownedAsset={asset!} onSuccess={refreshAssetData} />,
        maxWidth: 'sm',
        fullWidth: true
      })
    )
  }

  const openPauseModal = () => {
    dispatch(
      openDialog({
        content: () => <PauseAssetDialog ownedAsset={asset!} onSuccess={refreshAssetData} />,
        maxWidth: 'sm',
        fullWidth: true
      })
    )
  }

  const openUnpauseModal = () => {
    dispatch(
      openDialog({
        content: () => <UnpauseAssetDialog ownedAsset={asset!} onSuccess={refreshAssetData} />,
        maxWidth: 'sm',
        fullWidth: true
      })
    )
  }

  const openEditDialog = () => {
    if (asset) {
      dispatch(
        openDialog({
          content: () => <EditAssetDialog asset={asset} onSuccess={refreshAssetData} />,
          maxWidth: 'sm',
          fullWidth: true
        })
      )
    }
  }

  return (
    <Grid item>
      <Grid direction={'column'} container spacing={2} justify="flex-start" alignItems="center">
        {!isSuperUser && asset.signed && (
          <>
            <Grid item>
              <Tooltip
                title={disableBuyLiteral()}
                placement="top"
                classes={{ tooltip: classes.buyBtnTooltip }}
                disableTouchListener={isValidated}
                disableHoverListener={isValidated}
                disableFocusListener={isValidated}>
                <span>
                  <Button
                    className={classes.whiteBtn}
                    variant="contained"
                    color="primary"
                    onClick={() => handleAssetSwap('buy')}
                    disabled={!asset || asset.paused || !isValidated}>
                    {t('assets.detail.buyBtn')}
                  </Button>
                </span>
              </Tooltip>
              <Button
                className={classes.whiteBtn}
                variant="contained" color="primary"
                onClick={() => handleAssetSwap('sell')}
                disabled={!asset || asset.paused || Number(asset?.amountData?.clientBalance?.toSignificant() ?? '0') === 0}>
                {t('assets.detail.sellBtn')}
              </Button>
            </Grid>
          </>
        )}
        {!isSuperUser && !asset.signed && (
          <>
            <Grid item>
              <Button
                className={classes.whiteBtn}
                variant="contained" color="primary"
                onClick={() => handleSign()}>
                {t('assets.detail.buyBtn')}
              </Button>
            </Grid>
          </>
        )}
        {isSuperUser && asset && !asset.paused &&(
          <Grid item>
            <Button fullWidth className={classes.liquidityBtn} variant="contained" color="primary" onClick={handleLiquidityMenu}>
              {t('assets.detail.liquidityBtn')}
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseLiquidityMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              {asset?.swappableTokens.map((token) => (
                <MenuItem
                  key={token.address}
                  component="a"
                  className={classes.liquidityBtnMenuItem}
                  target="_blank"
                  href={`https://app.uniswap.org/#/add/${token.address}/${asset.tokenAddress}${asset.uniswap_fee ? '/' + asset.uniswap_fee : ''}`}>
                    {token.name}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        )}
        {isOwner && asset && !asset.paused &&(
          <Grid item>
            <Button className={classes.adminBtn} variant="outlined" color="secondary" onClick={openMintModal}>
              {t('assets.detail.mintBtn')}
            </Button>
          </Grid>
        )}
        {isSuperUser && asset &&(
          <Grid item>
            <Button className={classes.adminBtn} variant="outlined" color="secondary" onClick={openEditDialog}>
              {t('assets.detail.editBtn')}
            </Button>
            <Button className={classes.adminBtn} variant="outlined" color="secondary" onClick={!asset.paused ? openPauseModal : openUnpauseModal}>
              {t(!asset.paused ? 'assets.detail.pauseBtn' : 'assets.detail.unpauseBtn')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default AssetDetailPageActions
