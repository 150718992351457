import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      }
    },
    address: {
      color: grey[500],
    },
    copy: { 
      fontSize: 13 
    },
  }
});

export default useStyles;