import { AppRoute } from '../../../common/view/router/config'
import PositionPage from '../components/position-page/PositionPage'
import { IsSuperAdminGuard } from '../../../user/view/router/guards/IsSuperAdminGuard'

export const positionRoutes: Array<AppRoute> = [
  {
    path: '/u/balance/:id',
    component: PositionPage,
    guard: new IsSuperAdminGuard()
  },
  {
    path: '/u/balance/',
    component: PositionPage
  },
]
