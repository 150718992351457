import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {useDispatch, useSelector} from 'react-redux'
import {closeDialog, openSnackbar, setLoader} from '../../../../common/view/store/ui/ui.slice'
import { Form, Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../common/i18n/config'
import yup from '../../../../common/utils/yup.extended'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Grid, MenuItem} from '@material-ui/core'
import { AglaiaDialogContent } from '../../../../common/view/components/app-dialog/AppDialog.styled'
import {getUserClientWalletAddress} from "../../../../user/view/store/user.selectors";
import ViewEtherscanButton from "../../../../eth/view/components/view-etherscan-button/ViewEtherscanButton";
import {TransferTokenModel} from "../../../../assets/data/models/TransferTokenModel";
import {useEthErrorsWrapper} from "../../../../eth/view/hooks/EthErrorsWrapper.hook";
import {TransferTokenInteractor} from "../../../../eth/domain/use-cases/TransferTokenInteractor/TransferTokenInteractor";
import SwappableTokens from "../../../../eth/domain/models/SwappableTokens";
import {GetTokenPositionsUseCase} from "../../../domain/use-cases/GetTokenPositionsUseCase";
import {EnvVars} from "../../../../common/config/EnvVars";

interface FormValues {
  amount: string
  tokenSelected: string
  otherTokenAddress: string
  from: string
  to: string
}

interface tokenAddressModel {
  name: string
  address: string
}

const formInitialValues: FormValues = {
  amount: '',
  tokenSelected: 'eth',
  otherTokenAddress: '',
  from: '',
  to: EnvVars.bridgeAddress
}

const validationSchema = yup.object().shape({
  amount: yup
    .string()
    .required(i18n.t('formErrors.required'))
    .moreThan(0, i18n.t('formErrors.moreThan', { min: 0 })),
  tokenSelected: yup.string().required(i18n.t('formErrors.required')),
  otherTokenAddress: yup.string()
    .when('tokenSelected', {
      is: (tokenSelected: string) => tokenSelected === 'other',
      then: yup.string().required(i18n.t('formErrors.required'))
    }),
  from: yup.string().required(i18n.t('formErrors.required')),
  to: yup.string().required(i18n.t('formErrors.required'))
})

export const TransferDialog = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef = useRef<FormikProps<FormValues>>(null)
  const currentuserWalletAddress = useSelector(getUserClientWalletAddress) || ''
  const TransferUseCase = useEthErrorsWrapper(TransferTokenInteractor.execute)
  const [tokenList, setTokenList] = useState<Array<tokenAddressModel>>([])
  const getTokensUseCase = useMemo(() => new GetTokenPositionsUseCase(), [])

  const fetchTokens = useCallback(async () => {
    const swappableTokens = SwappableTokens.tokens;
    const tokenAssets = await getTokensUseCase.execute(currentuserWalletAddress)
    const list = [];

    list.push({name: 'ETH', address: 'eth'})
    swappableTokens.forEach(token => list.push({name: token.name, address: token.address}))
    tokenAssets.forEach(token => list.push({name: token.name, address: token.tokenAddress}))
    list.push({name: 'Other', address: 'other'})


    setTokenList(list)
  }, [currentuserWalletAddress, getTokensUseCase])

  useEffect(() => {
    formRef.current?.setFieldValue('from', currentuserWalletAddress)
  }, [currentuserWalletAddress, formRef])

  useEffect(() => {
    fetchTokens()
  }, [fetchTokens])

  const handleCloseDialog = () => {
    dispatch(closeDialog())
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      dispatch(setLoader(true))

      const input: TransferTokenModel = {
         source_address: values.from,
         destination_address: values.to,
         amount_to_transfer: values.amount.toString(),
         token_address: values.tokenSelected === 'other' ? values.otherTokenAddress : values.tokenSelected
      }

      const result = await TransferUseCase(input)
      if (result === 'OK') {
        dispatch(closeDialog())
        dispatch(openSnackbar({ message: () => <div>
            {t('assets.swap.swapOk')}
            <ViewEtherscanButton tokenAddress={currentuserWalletAddress} />
          </div>
        }))
      }
      else {
        dispatch(openSnackbar({ message: t('assets.swap.swapKo') }))
      }
      dispatch(setLoader(false))
    } catch (e) {
      console.error(e)
      dispatch(openSnackbar({ message: t('assets.swap.swapKo') }))
      dispatch(setLoader(false))
    }
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      enableReinitialize>
      {({values, isValid, errors, handleChange, touched}: FormikProps<FormValues>) => {
        return (
          <>
            <DialogTitle id="form-dialog-title">{t('balance.transfer.title')}</DialogTitle>
            <AglaiaDialogContent>
              <Form noValidate>
                <Grid container spacing={2} direction="column" justify="flex-start" alignItems="flex-start">
                  <Grid container item xs>
                      <TextField
                        autoFocus={true}
                        name="from"
                        variant="outlined"
                        id="from"
                        label={t('balance.transfer.from')}
                        fullWidth
                        onChange={handleChange}
                        value={values.from}
                        error={Boolean(touched.from) && Boolean(errors.from)}
                        helperText={Boolean(touched.from) && Boolean(errors.from) && errors.from}
                      />
                  </Grid>
                  <Grid container item xs>
                      <TextField
                        name="to"
                        variant="outlined"
                        id="to"
                        label={t('balance.transfer.to')}
                        fullWidth
                        onChange={handleChange}
                        value={values.to}
                        error={Boolean(touched.to) && Boolean(errors.to)}
                        helperText={Boolean(touched.to) && Boolean(errors.to) && errors.to}
                      />
                  </Grid>
                  <Grid container item xs>
                      <TextField
                        select
                        name="tokenSelected"
                        variant="outlined"
                        id="tokenSelected"
                        fullWidth
                        label={t('balance.transfer.token')}
                        onChange={handleChange}
                        value={values.tokenSelected}
                        error={Boolean(touched.tokenSelected) && Boolean(errors.tokenSelected)}
                        helperText={Boolean(touched.tokenSelected) && Boolean(errors.tokenSelected) && errors.tokenSelected}>
                          {tokenList.map((option) => (
                            <MenuItem key={option.address} value={option.address}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </TextField>
                  </Grid>
                  {values.tokenSelected === 'other' &&
                    <Grid container item xs>
                      <TextField
                          name="otherTokenAddress"
                          variant="outlined"
                          id="otherTokenAddress"
                          label={t('balance.transfer.other')}
                          fullWidth
                          onChange={handleChange}
                          value={values.otherTokenAddress}
                          error={Boolean(touched.otherTokenAddress) && Boolean(errors.otherTokenAddress)}
                          helperText={Boolean(touched.otherTokenAddress) && Boolean(errors.to) && errors.otherTokenAddress}
                      />
                    </Grid>
                  }
                  <Grid container item xs>
                      <TextField
                        type={"number"}
                        variant="outlined"
                        id="amount"
                        name="amount"
                        label={t('balance.transfer.amount')}
                        fullWidth
                        onChange={handleChange}
                        value={values.amount}
                        error={Boolean(touched.amount) && Boolean(errors.amount)}
                        helperText={Boolean(touched.amount) && Boolean(errors.amount) && errors.amount}
                      />
                  </Grid>
                </Grid>
              </Form>
            </AglaiaDialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleCloseDialog}>
                {t('assets.swap.cancelBtn')}
              </Button>
              <Button color="primary" onClick={() => formRef.current?.submitForm()}>
                {t('balance.transfer.submitBtn')}
              </Button>
            </DialogActions>
          </>
        )
      }}
    </Formik>
  )
}
