import React from 'react'
import { useDispatch } from 'react-redux'
import QRCode from 'react-qr-code'
import { Button } from '@material-ui/core'

import useStyles from './QrCode.styles'

import { openDialog, closeDialog } from '../../../../common/view/store/ui/ui.slice'
import {EnvVars} from "../../../../common/config/EnvVars";

export interface Props {
  size: number
  walletAddress: string
  disabled?: boolean
}

const onClickQr = (dispatch: any, walletAddress: string) => {
  dispatch(
    openDialog({
      content: () => <QrCode size={200} disabled={true} walletAddress={walletAddress} />,
      maxWidth: 'sm',
    })
    )
  }

const QrCode = ({ size, walletAddress, disabled = false }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles({ size, walletAddress, disabled })
  const onClickOpen = () => onClickQr(dispatch, walletAddress)
  const onClickClose = () => {
    dispatch(closeDialog())
  }

  return (
    <Button onClick={!disabled ? onClickOpen : onClickClose} className={classes.btn}>
      <QRCode value={`${EnvVars.etherscanUrl}address/${walletAddress}`} size={size} level={'Q'} />
    </Button>
  )
}

export default QrCode;
