import { Expose, Type } from 'class-transformer'

export class ClientUpdate {
  @Expose()
  id!: string
  @Expose()
  @Type(() => UserClientUpdate)
  user!: UserClientUpdate
  @Expose({ name: 'eth_public_address' })
  ethPublicAddress!: string
}

export class BusinessUpdate {
  @Expose()
  id!: string
  @Expose()
  @Type(() => UserClientUpdate)
  user!: UserClientUpdate
  @Expose({ name: 'eth_public_address' })
  ethPublicAddress!: string
  @Expose()
  business_jurisdiction_code!: number
  @Expose()
  business_registration_number!: string
  @Expose()
  business_name!: string
}
class UserClientUpdate {
  @Expose()
  id!: string
  @Expose({ name: 'first_name' })
  firstName!: string
  @Expose({ name: 'last_name' })
  lastName!: string
}
