import React, { useCallback, useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import debounce from 'lodash/debounce'
import { CancelablePromise, makeCancelable } from '../../../utils/promise'
import { Grid, Typography } from '@material-ui/core'

interface Props<T> {
  label?: string
  promise: (searchValue: string) => Promise<Array<T>>
  onChange: (value: T | null) => void
  value?: T | null
  helperText?: string
  noOptionsText?: string
}

export interface AsyncAutocompleteOption {
  key: string
  label: string
  subLabel?: string
}

const AsyncAutocomplete = <T extends AsyncAutocompleteOption>({ promise, label, onChange, value, helperText, noOptionsText }: Props<T>) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<Array<T>>([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  let searchPromise = useRef<CancelablePromise<Array<T>> | null>(null)

  const wrappedPromise = async (inputValue: string) => {
    setLoading(true)
    searchPromise.current = makeCancelable<Array<T>>(promise(inputValue))
    searchPromise.current?.promise
      .then((results) => {
        let newOptions = [] as Array<T>
        if (value) {
          newOptions = [value]
        }
        if (results) {
          newOptions = [...newOptions, ...results]
        }
        setOptions(newOptions)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPromise = useCallback(debounce(wrappedPromise, 300), [])

  const handleOpen = () => {
    if (inputValue.length) {
      setOpen(!open)
    } else if (!inputValue.length && open) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (!inputValue.length && !options.length) {
      setOpen(false)
    }
  }, [inputValue.length, options.length])

  useEffect(() => {
    if (inputValue === value?.label) {
      return undefined
    }
    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    debouncedPromise(inputValue)

    return () => {
      searchPromise.current?.cancel()
    }
  }, [value, inputValue, debouncedPromise])

  return (
    <Autocomplete
      open={open}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
      getOptionSelected={(option, value) => option.key === value.key}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value ?? null}
      loading={loading}
      multiple={false}
      noOptionsText={noOptionsText}
      onOpen={handleOpen}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onChange={(event: any, newValue: T | null) => {
        onChange(newValue)
        setOptions(newValue ? [newValue, ...options] : options)
        setOpen(false)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      renderOption={(option) => {
        return (
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <Typography variant="body1">{option.label}</Typography>
            {option.subLabel && (
              <Typography variant="body2" color="textSecondary">
                {option.subLabel}
              </Typography>
            )}
          </Grid>
        )
      }}
    />
  )
}

export default AsyncAutocomplete
