import { createAsyncThunk, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit'
import { EthStoreState } from './eth.types'
import { MetamaskInitUseCase } from '../../domain/use-cases/MetamaskInitUseCase'
import { MetamaskConnectionStatus } from '../../domain/models/MetamaskConnectionStatus'
import { MetamaskService } from '../../data/MetamaskService'
import { closeSnackbar, openSnackbar } from '../../../common/view/store/ui/ui.slice'
import i18n from '../../../common/i18n/config'
import { ClientUpdateOutputModelData } from '../../../clients/data/models/ClientUpdateOutputModel'
import { setAddressChanged, updateUserClientThunk } from '../../../user/view/store/user.slice'
import { AppDispatch, RootState } from '../../../common/view/store/store'
import EthereumClient from '../../data/EthereumClient'
import { TransactionStatus, TransactionType } from '../../domain/models/TransactionState'

const INITIAL_STATE: EthStoreState = {
  metamask: {
    connectionStatus: null
  },
  transactions: []
}

export const metamaskConnectionCheckThunk = createAsyncThunk<void, undefined, { dispatch: AppDispatch }>(
  'ethStore/metamaskConnectionCheck',
  async (arg, { dispatch }) => {
    const connectionStatus = await MetamaskInitUseCase.execute()
    dispatch(setMetamaskStatus(connectionStatus))
  }
)

export const metamaskDisconnectThunk = createAsyncThunk('ethStore/metamaskDisconnect', async (arg, { dispatch }) => {
  await MetamaskService.disconnect()
  dispatch(setMetamaskStatus(MetamaskConnectionStatus.DISCONNECTED))
})

export const metamaskConnectThunk = createAsyncThunk<void, undefined, { dispatch: AppDispatch }>(
  'ethStore/metamaskConnect',
  async (arg, { dispatch }) => {
    dispatch(closeSnackbar())
    try {
      await MetamaskService.connect()
      await dispatch(metamaskSaveAddressThunk())
      dispatch(openSnackbar({ message: i18n.t('metamask.snackbar.success.message'), severity: 'success' }))
      dispatch(setMetamaskStatus(MetamaskConnectionStatus.CONNECTED))
    } catch (e) {
      dispatch(openSnackbar({ message: i18n.t('metamask.snackbar.error.connectionMessage'), severity: 'error' }))
      dispatch(setMetamaskStatus(MetamaskConnectionStatus.NOT_CONNECTED))
    }
  }
)

export const metamaskSaveAddressThunk = createAsyncThunk<Promise<void>, undefined, { dispatch: AppDispatch; state: RootState }>(
  'ethStore/metamaskSaveAddress',
  async (arg, { dispatch, getState }) => {
    const userProfile = getState().user.profile
    if (!userProfile?.addressChanged) {
      if (MetamaskService.metamaskConnected) {
        const address = await EthereumClient.providerAddress('web3')
        if (address) {
          const outputModel: Partial<ClientUpdateOutputModelData> = { ethPublicAddress: address }
          const res = await dispatch(updateUserClientThunk(outputModel))
          const clientUpdate = unwrapResult(res)
          if (clientUpdate?.ethPublicAddress === outputModel.ethPublicAddress) {
            dispatch(setAddressChanged(true))
            MetamaskService.setAddressInClient(clientUpdate?.ethPublicAddress, true)
          }
        }
      }
    }
  }
)

export const ethStore = createSlice({
  name: 'ethStore',
  initialState: INITIAL_STATE,
  reducers: {
    setMetamaskStatus: (state, action: PayloadAction<MetamaskConnectionStatus>) => {
      state.metamask.connectionStatus = action.payload
    },
    addTransaction: (state, action: PayloadAction<{ hash: string; type?: TransactionType }>) => {
      const { hash, type = 'swap' } = action.payload
      state.transactions.push({ hash, status: 'pending', type })
    },
    changeTransactionStatus: (state, action: PayloadAction<{ hash: string; status: TransactionStatus }>) => {
      const { hash, status } = action.payload
      const clonedState = [...state.transactions]
      const foundTx = clonedState.find((tx) => tx.hash === hash)
      if (foundTx) {
        foundTx.status = status
        state.transactions = clonedState
      }
    }
  }
})

export const { setMetamaskStatus, addTransaction, changeTransactionStatus } = ethStore.actions

export default ethStore.reducer
