import { makeStyles, styled } from '@material-ui/core/styles'
import backgroundConnect from '../../../assets/background/network_background.jpg'

export const MainContainer = styled('div')({
  overflow: 'hidden',
  paddingBottom: '5vh',
})

export const BackgroundImage = styled('div')({
  backgroundImage: `url(${backgroundConnect})`,
  backgroundPosition: 'bottom',
  backgroundSize: 'cover',
  height: '100vh',
  width: '100vw',
  position: 'absolute',
  zIndex: -1000,
  overflow: 'hidden'
})

export const Content = styled('div')({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '10vw',
  paddingRight: '10vw',
  paddingBottom: '10vh',
});


const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.primary.dark,
  }
}))

export default useStyles