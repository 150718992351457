import { Asset } from '../../../../assets/domain/models/Asset'
import { ContractRepository } from '../../../data/ContractRepository'
import { providers } from 'ethers'
import { TokenType } from '../../../../assets/domain/models/TokenType'
import {EnvVars} from "../../../../common/config/EnvVars";

export class MintTokensUseCase {
  static async execute(asset: Asset, amount: string): Promise<providers.TransactionResponse | undefined> {
    const to = EnvVars.adminEthAddress
    if (asset.tokenType === TokenType.ERC_20_TOKEN) {
      const erc20Contract = new ContractRepository(asset.tokenAddress)
      return erc20Contract.mint(amount, to)
    } else if (asset.tokenType === TokenType.S_TOKEN) {
      const sTokenContract = new ContractRepository(asset.tokenAddress)
      return sTokenContract.mint(amount, to)
    }
  }
}
