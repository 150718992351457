import 'reflect-metadata'
import './common/i18n/config.ts'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import * as Sentry from '@sentry/react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './common/view/store/store'

const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === 'true' || false
Sentry.init({
  enabled: sentryEnabled,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'unknown'
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
