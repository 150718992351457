import { fade, makeStyles, Theme } from '@material-ui/core/styles'

interface StyleProps {
  isDragActive: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return {
    fileUploaderRoot: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    fileUploaderDropzone: {
      cursor: 'pointer',
      display: 'flex',
      width: '100%',
      height: 80,
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2),
      borderWidth: 2,
      borderRadius: 4,
      borderColor: ({ isDragActive }) => (isDragActive ? theme.palette.primary.main : theme.palette.grey.A100),
      borderStyle: 'dashed',
      backgroundColor: fade(theme.palette.background.default, 0.5),
      color: theme.palette.text.primary,
      outline: 'none',
      transition: 'border .24s ease-in-out'
    },
    fileUploaderPreview: {}
  }
})

export default useStyles
