import {
  AutoSizer,
  Index,
  IndexRange,
  InfiniteLoader,
  InfiniteLoaderChildProps,
  Size,
  WindowScroller,
  WindowScrollerChildProps
} from 'react-virtualized'
import { Grid as VirtualizedGrid, GridCellRenderer, SectionRenderedParams } from 'react-virtualized/dist/es/Grid'

interface Props {
  cellRenderer: GridCellRenderer
  isRowLoaded: (params: Index) => boolean
  loadMoreRows: (params: IndexRange) => Promise<any>
  columnCount: number
  rowCountNormalList: number
  rowCountTupleList: number
  columnWidthCallback: (width: number) => number
  scrollElement?: typeof window | Element
  threshold?: number,
}

const GridInfiniteLoader = ({
  cellRenderer,
  isRowLoaded,
  loadMoreRows,
  rowCountNormalList,
  rowCountTupleList,
  threshold = 30,
  scrollElement,
  columnCount,
  columnWidthCallback,
}: Props) => {
  let _onRowsRendered: (params: IndexRange) => void

  const onSectionRendered = ({ columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex }: SectionRenderedParams) => {
    const startIndex = rowStartIndex * columnCount + columnStartIndex
    const stopIndex = rowStopIndex * columnCount + columnStopIndex

    _onRowsRendered({ startIndex, stopIndex })
  }

  return (
    <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={rowCountNormalList} threshold={threshold}>
      {({ onRowsRendered, registerChild }: InfiniteLoaderChildProps) => {
        _onRowsRendered = onRowsRendered
        return (
          <WindowScroller scrollElement={scrollElement}>
            {({ height, scrollTop, isScrolling, onChildScroll }: WindowScrollerChildProps) => {
              return (
                <AutoSizer disableHeight>
                  {({ width }: Size) => {
                    return (
                      height && (
                        <VirtualizedGrid
                          onSectionRendered={onSectionRendered}
                          cellRenderer={cellRenderer}
                          columnCount={columnCount}
                          rowCount={rowCountTupleList}
                          autoHeight
                          height={height}
                          ref={registerChild}
                          width={width}
                          isScrolling={isScrolling}
                          scrollTop={scrollTop}
                          onScroll={onChildScroll}
                          columnWidth={columnWidthCallback(width)}
                          rowHeight={400}
                        />
                      )
                    )
                  }}
                </AutoSizer>
              )
            }}
          </WindowScroller>
        )
      }}
    </InfiniteLoader>
  )
}

export default GridInfiniteLoader
