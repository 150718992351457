import React, { useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Formik, FormikProps } from 'formik'
import { unwrapResult } from '@reduxjs/toolkit'
import { Divider, Grid, Link, Typography } from '@material-ui/core'

import useStyles, { CompanyName, MainContainer, BackgroundImage, SubmitButton, EmailInput } from './LoginPage.styles'

import yup from '../../../../common/utils/yup.extended'
import i18n from '../../../../common/i18n/config'

import { useAppDispatch } from '../../../../common/view/store/store'
import { openDialog } from '../../../../common/view/store/ui/ui.slice'
import { PasswordTextField } from '../../../../common/view/components/PasswordTextField'
import { ErrorType } from '../../../../common/domain/models/ErrorType'
import { AglaiaPaper } from '../../../../common/view/components/aglaia-paper/AglaiaPaper'
import { loginThunk } from '../../../../user/view/store/user.slice'
import { RecoverPasswordDialog } from '../../../../user/view/components/recover-password-dialog/RecoverPasswordDialog'
import { Web3CheckErrors } from '../../../../eth/data/EthereumClient'

import AglaiaLogo from '../../../../common/assets/logos/LOGO_ACTIONMONKEY.png'

interface FormValues {
  email: string
  password: string
}

const formInitialValues: FormValues = {
  email: '',
  password: ''
}

const validationSchema = yup.object().shape({
  email: yup.string().required(i18n.t('formErrors.required')).email(i18n.t('formErrors.email')),
  password: yup.string().required(i18n.t('formErrors.required'))
})

export default function LoginPage() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const router = useHistory()
  const [loginError, setLoginError] = useState<string | null>(null)
  const classes = useStyles()

  const handleSubmit = async (values: FormValues) => {
    const result = await dispatch(loginThunk({ email: values.email, password: values.password }))
    try {
      unwrapResult(result)
      router.push('/u')
    } catch (e) {
      if (e.message.includes('errorCode')) {
        const parsedError: ErrorType<Web3CheckErrors> = JSON.parse(e.message)
        if (parsedError.errorCode === Web3CheckErrors.WRONG_CHAIN_ID) {
          setLoginError(`${t('login.wrongChainId', { chainId: parsedError.data.chainId })}`)
        } else if (parsedError.errorCode === Web3CheckErrors.DIFFERENT_PUBLIC_ADDRESS) {
          setLoginError(`${t('login.wrongMetamaskAddress', { address: parsedError.data.address })}`)
        } else {
          setLoginError(`${t('login.defaultError')}`)
        }
      } else {
        setLoginError(`${t('login.defaultError')}`)
      }
      console.error(e)
    }
  }

  const handleEmailRecovery = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    dispatch(openDialog({ content: RecoverPasswordDialog }))
  }

  return (
    <MainContainer>
      {/*<ThemeSelector className={classes.themeSelector} />*/}
      <BackgroundImage>
        <div className={classes.shape} />
        <div className={classes.wrapper}>
          <div className={classes.section}>
            <img className={classes.logoLogin} src={AglaiaLogo} alt="Aglaia Capital DeFi Platform" title="Aglaia Capital DeFi Platform"/>
            <CompanyName variant="h4" display="block">
              <b>{t('login.title')}</b>
            </CompanyName>
          </div>
          <div className={classes.sectionRight}>
            <AglaiaPaper className={classes.loginPaper}>
              <Typography variant="h6" display="block" color="primary" className={classes.subtitle}>
                <b>{t('login.subtitle')}</b>
              </Typography>
              <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={handleSubmit}>
                {({ errors, touched, handleChange, values }: FormikProps<FormValues>) => {
                  return (
                    <Form className={classes.form} noValidate>
                      <EmailInput
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label={t('formLabels.login.email')}
                        name="email"
                        autoComplete="email"
                        onChange={handleChange}
                        value={values.email}
                        error={Boolean(touched.email) && Boolean(errors.email)}
                        helperText={Boolean(touched.email) && Boolean(errors.email) && errors.email}
                        className={classes.textField}
                      />
                      <PasswordTextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t('formLabels.login.password')}
                        id="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                        value={values.password}
                        error={Boolean(touched.password) && Boolean(errors.password)}
                        helperText={Boolean(touched.password) && Boolean(errors.password) && errors.password}
                      />
                      {loginError && (
                        <div className={classes.generalError}>
                          <Typography component="small" variant="body2" color="error">
                            {loginError}
                          </Typography>
                        </div>
                      )}
                      <SubmitButton type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        {t('login.submitBtn')}
                      </SubmitButton>
                      <Divider className={classes.formDivider} />
                      <Grid container direction="column" justify="center" spacing={2} alignItems="flex-start">
                        <Grid item>
                          <Link component={RouterLink} to="/sign-up" variant="body2">
                            {t('login.loginClaimOne')}
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link onClick={handleEmailRecovery} href="#" variant="body2">
                            {t('login.loginClaimTwo')}
                          </Link>
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </AglaiaPaper>
          </div>
        </div>
      </BackgroundImage>
    </MainContainer>
  )
}
