import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  themeSelector: {
    position: 'fixed',
    top: 20,
    right: 20
  },
  paper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  generalError: {
    marginTop: theme.spacing(1)
  }
}))

export default useStyles
