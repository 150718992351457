
import { IconButton } from '@material-ui/core'

import { ReactComponent as EtherscanLogo } from '../../../../common/assets/logos/etherscan-logo-circle.svg'
import {EnvVars} from "../../../../common/config/EnvVars";

interface Prop {
  tokenAddress: string | undefined
}

const ViewEtherscanButton = ({ tokenAddress } : Prop) => {
  return (
    <IconButton target="_blank" href={`${EnvVars.etherscanUrl}address/${tokenAddress}`}>
      <EtherscanLogo height={20} width={20} />
    </IconButton>
  )
}

export default ViewEtherscanButton;
