import { useAppDispatch } from '../../store/store'
import { useSelector } from 'react-redux'
import { getSnackbarsState } from '../../store/ui/ui.selectors'
import { removeSnackbar } from '../../store/ui/ui.slice'
import React, { useEffect } from 'react'
import { SnackbarKey, useSnackbar } from 'notistack'
import { AppSnackbar } from './AppSnackbar'

let displayed: Array<SnackbarKey> = []

export const AppSnackbarNotifier = () => {
  const dispatch = useAppDispatch()
  const snackbars = useSelector(getSnackbarsState || [])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)]
  }

  useEffect(() => {
    snackbars.forEach(({ snackbarKey, message, dismissed = false, ...restSnackbarState }) => {
      if (dismissed) {
        closeSnackbar(snackbarKey)
        return
      }

      if (displayed.includes(snackbarKey)) return

      enqueueSnackbar(message, {
        content: () => <AppSnackbar {...{ snackbarKey, message, dismissed, ...restSnackbarState }} />,
        key: snackbarKey,
        persist: !restSnackbarState.autoHide,
        autoHideDuration: restSnackbarState.autoHide,
        onExited: (event, myKey) => {
          dispatch(removeSnackbar(myKey))
          removeDisplayed(myKey)
        }
      })

      storeDisplayed(snackbarKey)
    })
  }, [snackbars, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}
