import { useMemo, useState } from 'react'

export enum RowsPerPage {
  A50 = 50,
  A100 = 100
}

export const useTable = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.A50)

  return {
    apiParams: useMemo(() => ({ offset: rowsPerPage * page, limit: rowsPerPage }), [page, rowsPerPage]),
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage
  }
}
