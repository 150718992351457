import { darken, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  const minAmountBgColor = darken(theme.palette.primary.dark, 0.5)
  return {
    minimumAmountOut: {
      backgroundColor: minAmountBgColor,
      color: theme.palette.getContrastText(minAmountBgColor),
      borderRadius: 8,
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between'
    },
    priceError: {
      marginTop: theme.spacing(1)
    }
  }
})

export default useStyles
