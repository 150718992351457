import { Button, DialogActions, DialogContentText, DialogTitle } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Client } from '../../../domain/Client'
import { closeDialog, openSnackbar } from '../../../../common/view/store/ui/ui.slice'
import { ClientsRepository } from '../../../data/ClientsRepository'
import { AglaiaDialogContent } from '../../../../common/view/components/app-dialog/AppDialog.styled'

interface Props {
  client: Client
  onSuccess: () => void
}

export default function ActivateClientAlertDialog({ client, onSuccess }: Props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { isActive } = client?.user || {};

  const handleClose = () => {
    dispatch(closeDialog())
  }

  const handleConfirm = async () => {
    try {
      let message = t('clientsList.activateDialog.confirmationMsg')
      if (!isActive) {
        await ClientsRepository.activate(client.id)
      } else {
        await ClientsRepository.desactivate(client.id)
        message = t('clientsList.activateDialog.confirmationDeactivateMsg')
      }

      dispatch(openSnackbar({ message, severity: 'info' }))
      onSuccess()
    } catch (e) {
      dispatch(openSnackbar({ message: t('defaultErrorMsg'), severity: 'error' }))
      throw e
    } finally {
      handleClose()
    }
  }

  let messageDisplay = t('clientsList.activateDialog.description', { client: client?.fullName });

  if (isActive) {
    messageDisplay = t('clientsList.activateDialog.descriptionDeactivate', { client: client?.fullName })
  }

  return (
    <>
      <DialogTitle id="alert-dialog-title">{t('clientsList.activateDialog.title')}</DialogTitle>
      <AglaiaDialogContent>
        <DialogContentText id="alert-dialog-description">
          {messageDisplay}
        </DialogContentText>
      </AglaiaDialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('clientsList.activateDialog.deniesBtn')}
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="outlined" autoFocus>
          {t('clientsList.activateDialog.confirmBtn')}
        </Button>
      </DialogActions>
    </>
  )
}
