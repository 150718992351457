import { Paper, withStyles } from '@material-ui/core'
import { createStyles, lighten, Theme } from '@material-ui/core/styles'
import { PaperProps } from '@material-ui/core/Paper/Paper'
import { WithStylesOptions } from '@material-ui/styles/withStyles'

export const AglaiaPaper = withStyles<string, WithStylesOptions<Theme>, Omit<PaperProps, 'elevation'>>((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: lighten(theme.palette.background.default, 1),
      padding: theme.spacing(4),
      borderRadius: 10
    }
  })
)(({ elevation, ...restProps }: PaperProps) => <Paper elevation={0} {...restProps} />)
