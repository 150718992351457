import { HttpClient } from '../../common/data/HttpClient'
import { User } from '../domain/models/User'
import { plainToClass } from 'class-transformer'
import { ResetPasswordOutputModel } from './models/ResetPasswordOutputModel'

export class UserRepository {
  static async get(): Promise<User> {
    const { data } = await HttpClient.instance.get('/users/me/')
    return plainToClass(User, data, { excludeExtraneousValues: true })
  }

  static async passwordRecovery(email: string): Promise<void> {
    await HttpClient.instance.post('/users/password-recovery/', { email })
  }

  static async resetPassword(outputModel: ResetPasswordOutputModel): Promise<void> {
    await HttpClient.instance.post('/users/reset-password/', outputModel)
  }
}
