import React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

function areEqual(prevProps: RouterLinkProps, nextProps: RouterLinkProps) {
  return prevProps.to === nextProps.to
}

const RenderLink = React.memo(
  React.forwardRef<any, RouterLinkProps>(({ to, ...restProps }, ref) => <RouterLink to={to} ref={ref} {...restProps} />),
  areEqual
)

export default RenderLink
