import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

function selectUiBase(state: RootState) {
  return state.ui
}

export const getSnackbarsState = createSelector([selectUiBase], (ui) => ui.snackbars)

export const getLoaderState = createSelector([selectUiBase], (ui) => ui.showLoader)

export const getDialogState = createSelector([selectUiBase], (ui) => ui.dialog)

export const getThemeState = createSelector([selectUiBase], (ui) => ui.theme)
