import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../../../common/view/store/store'

function selectUserBase(state: RootState) {
  return state.user
}

function selectUserProfileBase(state: RootState) {
  return state.user.profile
}

function selectUserProfileClientBase(state: RootState) {
  return state.user.profile?.client
}

export const getUserProfile = createSelector([selectUserBase], (user) => user.profile)

export const getUserFirstName = createSelector([selectUserProfileBase], (profile) => profile?.firstName ?? '')

export const getUserSuperUserStatus = createSelector([selectUserProfileBase], (profile) => profile?.isSuperUser)

export const getUserClientAddressChanged = createSelector([selectUserProfileBase], (profile) => profile?.addressChanged)

export const getUserClientWalletAddress = createSelector([selectUserProfileBase], (profile) => profile?.ethPublicAddress)

export const getUserClientIsOwner = createSelector([selectUserProfileClientBase], (client) => client?.isOwner)

export const getUserClientId = createSelector([selectUserProfileClientBase], (client) => client?.id)

export const getUserClientCode = createSelector([selectUserProfileClientBase], (client) => client?.aglaia_id)

export const getUserValidatedStatus = createSelector([selectUserBase, selectUserProfileClientBase], (user, client) => {
  const {is_business, business_know_is_validated, business_aml_is_validated, business_docs_is_validated} = client || {};
  return user?.profile?.is_validated && (!is_business || (is_business && business_know_is_validated && business_aml_is_validated  && business_docs_is_validated))
})

export const isValidated = createSelector([selectUserBase], (user) => {
  return {
    is_validated: user?.profile?.is_validated,
    verification_url: user?.profile?.verification_url || null
  }
})

export const hasSignedDocs = createSelector([selectUserProfileClientBase], (client) => {
  if (!client) {
    return true
  }

  return client.registry_docs_signed
})

export const isBusinessInfoComplete = createSelector([selectUserProfileClientBase], (client) => {
  if (!client) {
    return true
  }
  const {business_jurisdiction_code, business_registration_number, business_name, is_business} = client || {};
  if (!is_business) {
    return true;
  }
  const isEmpty = (value: string) => !value || value === '';
  const juristrictionEmpty = isEmpty(business_jurisdiction_code || '');
  const registrationEmpty = isEmpty(business_registration_number || '');
  const nameEmpty = isEmpty(business_name || '');
  return !juristrictionEmpty && !registrationEmpty && !nameEmpty;
})

export const isBusinessInfoValidated = createSelector([selectUserProfileClientBase], (client) => {
  if (!client) {
    return true
  }
  const { business_aml_is_validated, business_know_is_validated, business_docs_is_validated, is_business} = client || {};
  if (!is_business) {
    return true;
  }
  return business_aml_is_validated && business_know_is_validated && business_docs_is_validated;
})

export const isBusinessDocsComplete = createSelector([selectUserBase], (user) => {
  if (!user.profile?.client || !user.profile?.client.is_business) {
    return {
      is_business_docs_validated: true,
      docs_verification_url: null
    }
  }
  return {
    business_docs_is_validated: user.profile?.client.business_docs_is_validated,
    docs_verification_url: user?.profile?.docs_verification_url || null
  }
})

