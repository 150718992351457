import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog, openSnackbar } from '../../../../common/view/store/ui/ui.slice'
import { Form, Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../common/i18n/config'
import yup from '../../../../common/utils/yup.extended'
import React, { createRef } from 'react'
import { UserRepository } from '../../../data/UserRepository'
import { AglaiaDialogContent } from '../../../../common/view/components/app-dialog/AppDialog.styled'
import { Grid } from '@material-ui/core'

interface FormValues {
  email: string
}

const formInitialValues: FormValues = {
  email: ''
}

const validationSchema = yup.object().shape({
  email: yup.string().required(i18n.t('formErrors.required')).email(i18n.t('formErrors.email'))
})

export const RecoverPasswordDialog = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef = createRef<FormikProps<FormValues>>()

  const handleCancel = () => {
    dispatch(closeDialog())
  }

  const handleSubmit = async (values: FormValues) => {
    await UserRepository.passwordRecovery(values.email)
    dispatch(openSnackbar({ message: t('recoverPassword.successfulRecovery') }))
    handleCancel()
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">{t('recoverPassword.title')}</DialogTitle>
      <AglaiaDialogContent>
        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
          <DialogContentText>{t('recoverPassword.description')}</DialogContentText>
          <Formik
            innerRef={formRef}
            initialValues={formInitialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}>
            {({ handleChange, errors, touched, values }: FormikProps<FormValues>) => {
              return (
                <Form noValidate>
                  <TextField
                    autoFocus
                    margin="normal"
                    id="email"
                    required
                    label={t('formLabels.recoverPassword.email')}
                    type="email"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.email}
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={Boolean(touched.email) && Boolean(errors.email) && errors.email}
                  />
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </AglaiaDialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          {t('recoverPassword.cancelBtn')}
        </Button>
        <Button color="primary" onClick={() => formRef.current?.submitForm()}>
          {t('recoverPassword.submitBtn')}
        </Button>
      </DialogActions>
    </>
  )
}
