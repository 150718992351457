import { Asset } from '../../../../assets/domain/models/Asset'
import { ContractRepository } from '../../../data/ContractRepository'
import { providers } from 'ethers'
import { TokenType } from '../../../../assets/domain/models/TokenType'

export class PauseTokenUseCase {
  static async execute(asset: Asset): Promise<providers.TransactionResponse | undefined> {
    if (asset.tokenType === TokenType.ERC_20_TOKEN) {
      const erc20Contract = new ContractRepository(asset.tokenAddress)
      return erc20Contract.pause()
    } else if (asset.tokenType === TokenType.S_TOKEN) {
      const sTokenContract = new ContractRepository(asset.tokenAddress)
      return sTokenContract.pause()
    }
  }
}
