import { makeStyles, withStyles, styled } from '@material-ui/core/styles'
import { Typography, Button, TextField } from '@material-ui/core'

export const MainContainer = styled('div')({
  overflow: 'hidden',
  paddingBottom: '5vh'
})

export const BackgroundImage = styled('div')({
  background: 'black',
  height: '100vh',
  width: '100vw',
  position: 'absolute',
  zIndex: -1000,
  overflow: 'hidden'
})

export const CompanyName = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    textAlign: 'center',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  }
}))(Typography)

export const EmailInput = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white
  }
}))(TextField)

export const SubmitButton = withStyles((theme) => ({
  root: {
    marginTop: '10px'
  },
  label: {
    color: theme.palette.common.white,
    height: '40px'
  }
}))(Button)

const useStyles = makeStyles((theme) => ({
  shape: {
    transform: 'skew(-20deg, 0deg)',
    position: 'absolute',
    background: 'black',
    zIndex: -500,
    top: 0,
    right: -200,
    height: '100vh',
    width: '600px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '0'
    }
  },
  themeSelector: {
    position: 'fixed',
    top: 20,
    right: 20
  },
  wrapper: {
    minHeight: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > h3': {
      marginBottom: theme.spacing(1)
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  section: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',
  },
  sectionRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '30px',
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      flex: 3,
      justifyContent: 'flex-start',
      padding: '0 30px',
    },
  },
  logoLogin: {
    width: 180,
    [theme.breakpoints.down('sm')]: {
      width: 120,
    }
  },
  generalError: {
    marginTop: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(8),
    height: theme.spacing(8)
  },
  logo: {
    fontSize: 42
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  textField: {
    backgroundColor: theme.palette.common.white
  },
  formDivider: {
    margin: `${theme.spacing(2, 0)} !important`
  },
  loginPaper: {
    width: '446px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  subtitle: {
    marginBottom: '10px'
  },
  submit: {
    label: {
      color: theme.palette.common.white
    }
  }
}))

export default useStyles
