import EthereumClient from './EthereumClient'

import { AddTokenButtonProps } from '../../eth/data/models/AddTokenButtonModel';
import {EnvVars} from "../../common/config/EnvVars";

export class MetamaskService {
  static checkConnection(): boolean | undefined {
    if (EnvVars.metamaskAllowed && MetamaskService.hasMetamaskInBrowser) {
      return MetamaskService.metamaskConnected
    }
    return undefined
  }

  static async connect(): Promise<void> {
    if (EnvVars.metamaskAllowed && MetamaskService.hasMetamaskInBrowser) {
      if (!MetamaskService.hasMetamaskAsClient) {
        await EthereumClient.setMetamask()
      }
      try {
        await EthereumClient.metamaskProvider?.request!({ method: 'eth_requestAccounts' })
      } catch (e) {
        EthereumClient.resetToDefaultProvider()
        throw e
      }
    }
  }

  static async addToken({ tokenAddress, tokenSymbol, tokenDecimals, tokenImage, callbackAddToken }: AddTokenButtonProps): Promise<void> {
    if (!MetamaskService.hasMetamaskInBrowser) {
      if (callbackAddToken) {
        callbackAddToken('metamask.snackbar.error.noMetamask', 'error')
      }
      return;
    }
    if (!MetamaskService.hasMetamaskAsClient) {
      await EthereumClient.setMetamask()
    }
    try {
      const wasAdded = await EthereumClient.metamaskProvider?.request!({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18 , // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      })
      if (callbackAddToken) {
        if (wasAdded) {
          callbackAddToken('metamask.snackbar.tokenAdded')
          return;
        }
        callbackAddToken('metamask.snackbar.error.tokenNotAdded', 'error')
        return;
      }
    } catch (e) {
      if (callbackAddToken) {
        callbackAddToken('metamask.snackbar.error.connectionMessage', 'error')
      }
    }
  }

  static disconnect(): void {
    EthereumClient.resetToDefaultProvider()
  }

  static get hasMetamaskInBrowser(): boolean {
    return typeof window.ethereum !== 'undefined' && window.ethereum.hasOwnProperty('isMetaMask') && window.ethereum.isMetaMask
  }

  static get hasMetamaskAsClient(): boolean {
    return EthereumClient.metamaskProvider?.isMetaMask ?? false
  }

  static get metamaskConnected(): boolean {
    return Boolean(EthereumClient.metamaskProvider?.selectedAddress ?? null)
  }

  static on<T>(event: string, callback: (value: T) => void): void {
    if (EthereumClient.metamaskProvider?.isMetaMask) {
      EthereumClient.metamaskProvider?.on<T>(event, callback)
    }
  }

  static setAddressInClient(address?: string, changed = false) {
    EthereumClient.setStoredAddressChanged(changed)
    EthereumClient.setStoredAddress(address)
  }
}
