import { closeDialog, openSnackbar } from '../../../common/view/store/ui/ui.slice'
import { useAppDispatch } from '../../../common/view/store/store'
import { useTranslation } from 'react-i18next'
import { Web3CheckErrors } from '../../data/EthereumClient'
import { ErrorType } from '../../../common/domain/models/ErrorType'
import { useCallback } from 'react'
import { useSentryException } from '../../../common/view/hooks/SentryException.hook'

export const useEthErrorsWrapper = <T extends (...args: any[]) => any>(promise: T): ((...args: Parameters<T>) => Promise<ReturnType<T>>) => {
  const dispatch = useAppDispatch()
  const { captureException } = useSentryException()
  const { t } = useTranslation()

  const showErrorMsg = useCallback(
    (parsedError?: ErrorType<Web3CheckErrors>) => {
      console.log('showErrorMsg called')
      dispatch(closeDialog)
      switch (parsedError?.errorCode) {
        case Web3CheckErrors.DIFFERENT_PUBLIC_ADDRESS:
          dispatch(openSnackbar({ message: t('metamask.snackbar.error.wrongAddress', { data: parsedError.data?.address }), severity: 'error' }))
          break
        case Web3CheckErrors.WRONG_CHAIN_ID:
          dispatch(openSnackbar({ message: t('metamask.snackbar.error.wrongChain'), severity: 'error' }))
          break
        default:
          dispatch(openSnackbar({ message: t('defaultErrorMsg'), severity: 'error' }))
          break
      }
    },
    [dispatch, t]
  )

  return useCallback(
    (...args: Parameters<T>) =>
      new Promise<ReturnType<T>>((resolve, reject) => {
        return promise(...args)
          .then((result: ReturnType<T>) => {
            resolve(result)
          })
          .catch((err: any) => {
            if (err.message.includes('errorCode')) {
              const parsedError: ErrorType<Web3CheckErrors> = JSON.parse(err.message)
              showErrorMsg(parsedError)
            } else {
              console.log(err)
              showErrorMsg()
            }
            captureException(err)
            reject(err)
          })
      }),
    [captureException, promise, showErrorMsg]
  )
}
