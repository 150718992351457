import { AppRoute } from '../../../common/view/router/config'
import LoginPage from '../components/login-page/LoginPage'
import { LoginGuard } from './guards/LoginGuard'
import { PresaleGuard } from './guards/PresaleGuard'

import SignUp from '../components/sign-up-page/SignUpPage'

export const authRoutes: Array<AppRoute> = [
  {
    path: '/login',
    exact: true,
    component: LoginPage,
    guard: new LoginGuard()
  },
  {
    path: '/sign-up',
    component: SignUp
  },
  {
    path: '/presaleprivatesale',
    component: SignUp,
    guard: new PresaleGuard()
  }
]
