import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return {
    containerPadding: {
      marginTop: 48
    },
    topBar: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      backgroundColor: theme.palette.background.default
    },
    closeBtn: {
      margin: 0
    },
    iframe: {
      border: 0,
      margin: '0 auto',
      display: 'block',
    }
  }
})

export default useStyles
