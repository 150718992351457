export const createTupleFromList = <T>(items: Array<T>, elementsPerRow = 3, baseTuple: Array<Array<T>> = [[]]): Array<Array<T>> => {
  return items.reduce(
    (acc, val) => {
      if (acc.tuple[acc.tupleIdx].length < elementsPerRow) {
        acc.tuple[acc.tupleIdx].push(val)
      } else if (acc.tuple[acc.tupleIdx].length === elementsPerRow) {
        acc.tupleIdx = acc.tupleIdx + 1
        acc.tuple.push([val])
      }
      return acc
    },
    { tuple: baseTuple, tupleIdx: 0 }
  ).tuple
}
